const initialState = {
    designationCreateRes: [],
    designationCreateResSuccess: false,

    designationGetAllRes: [],
    designationGetAllResSuccess: false,

    designationGetRes: [],
    designationGetResSuccess: false,

    designationUpdateRes: [],
    designationUpdateResSuccess: false,
}

// designation store reducer
const designationStore = (state = initialState, action) => {
    switch (action.type) {

        //Designation Create
        case "SET_CREATE_DESIGNATION_SUCCESS_RES":
            return {
                designationCreateRes: action.response,
                designationCreateResSuccess: true,
            }
        case "SET_CREATE_DESIGNATION_EMPTY_RES":
            return {
                designationCreateRes: {},
                designationCreateResSuccess: false,
            }
        case "SET_CREATE_DESIGNATION_FAILS_RES":
            return {
                designationCreateRes: action.response,
                designationCreateResSuccess: false,
            }
        //Designation Get All
        case "SET_GET_ALL_DESIGNATION_SUCCESS_RES":
            return {
                designationGetAllRes: action.response,
                designationGetAllResSuccess: true,
            }
        case "SET_GET_ALL_DESIGNATION_EMPTY_RES":
            return {
                designationGetAllRes: {},
                designationGetAllResSuccess: false,
            }
        case "SET_GET_ALL_DESIGNATION_FAILS_RES":
            return {
                designationGetAllRes: action.response,
                designationGetAllResSuccess: false,
            }

        //Designation Get
        case "SET_GET_DESIGNATION_SUCCESS_RES":
            return {
                designationGetRes: action.response,
                designationGetResSuccess: true,
            }
        case "SET_GET_DESIGNATION_EMPTY_RES":
            return {
                designationGetRes: {},
                designationGetResSuccess: false,
            }
        case "SET_GET_DESIGNATION_FAILS_RES":
            return {
                designationGetRes: action.response,
                designationGetResSuccess: false,
            }

        //Designation Udate
        case "SET_UPDATE_DESIGNATION_SUCCESS_RES":
            return {
                designationUpdateRes: action.response,
                designationUpdateResSuccess: true,
            }
        case "SET_UPDATE_DESIGNATION_EMPTY_RES":
            return {
                designationUpdateRes: {},
                designationUpdateResSuccess: false,
            }
        case "SET_UPDATE_DESIGNATION_FAILS_RES":
            return {
                designationUpdateRes: action.response,
                designationUpdateResSuccess: false,
            }
        default:
            return state;
    }
};

export default designationStore;
