import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';


function UserIcon(props) {
    return (
        <SvgIcon {...props} >
          {
              props.color==="primary"?(
                <g transform="matrix(1,0,0,1,0,0)">
                    <path stroke="#00b894" fill="none" d="M10.519.75A3.734 3.734 0 1 1 6.75 4.483 3.752 3.752 0 0 1 10.519.75z" class="prefix__cls-2" data-name="Path 965" transform="translate(-1.269)"/>
                    <path stroke="#00b894" fill="none" d="M2.25 20.433a7 7 0 0 1 14 0" class="prefix__cls-2" data-name="Path 966" transform="translate(0 -3.683)"/>
                </g>
              ):(
                <g transform="matrix(1,0,0,1,0,0)">
                    <path stroke="#657786" fill="none" d="M10.519.75A3.734 3.734 0 1 1 6.75 4.483 3.752 3.752 0 0 1 10.519.75z" class="prefix__cls-2" data-name="Path 965" transform="translate(-1.269)"/>
                    <path stroke="#657786" fill="none" d="M2.25 20.433a7 7 0 0 1 14 0" class="prefix__cls-2" data-name="Path 966" transform="translate(0 -3.683)"/>
                </g>
              )
          }
      </SvgIcon>
    );
}

export default UserIcon;