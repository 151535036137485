import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function FarwardIcon(props) {
    return (
        <SvgIcon {...props} viewBox='0 0 20 10'>
            <path d="M72.2,32.4v23.5c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5V43.2L35.5,70.9c-0.9,0.9-2,1.3-3.2,1.3s-2.3-0.4-3.2-1.3  c-1.8-1.8-1.8-4.6,0-6.4l27.7-27.7H44.1c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h23.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0h0  c1.1,0,2.1,0.4,2.9,1.1c0.2,0.2,0.4,0.4,0.6,0.6c0.5,0.7,0.9,1.5,0.9,2.4C72.2,32.1,72.2,32.2,72.2,32.4z" />
        </SvgIcon>
    );
}

export default FarwardIcon;