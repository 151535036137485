import ApiToken from './token';
const siteURL = "http://maanyait.elvis.do.viewyoursite.net/api/";
// const token = ApiToken.accessToken;
// 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJvcmlnX2lhdCI6MTU4MjExMDc0NSwiZW1haWwiOiJpbmZvQG1hYW55YWl0LmNvbSIsInVzZXJuYW1lIjoiaW5mb0BtYWFueWFpdC5jb20iLCJleHAiOjE1ODMzMjAzNDV9.AdgTbbUh5BqzHEwxRgUFyD8ytFnWKB_v_xcDCQ31KQw';
var token = localStorage.getItem("access") !== null ?
  localStorage.getItem("access") :
  sessionStorage.getItem("access");
const appConfig = {
  siteURL: siteURL,
  accessToken: () => {
    return token;
    //return 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJvcmlnX2lhdCI6MTU4MjExMDc0NSwiZW1haWwiOiJpbmZvQG1hYW55YWl0LmNvbSIsInVzZXJuYW1lIjoiaW5mb0BtYWFueWFpdC5jb20iLCJleHAiOjE1ODMzMjAzNDV9.AdgTbbUh5BqzHEwxRgUFyD8ytFnWKB_v_xcDCQ31KQw'
  }
}

export default appConfig
