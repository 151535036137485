const initialState = {
  country_list: [],
  companySearchList: [],
  contactSearchList: [],
  currency_list: [],
  pipeline_list: [],
  show_Loader: false,
  CurrentUser: [],
  list_all_owner_users: [],
  organization: [],
  company_products_list:[],
  userProfile: {},
  userProfileError: false,
}
// leads store reducer
const commonStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_OWNER_USER_LIST_RESPONSE':
      return {
        ...state,
        list_all_owner_users: action.response,
      }

    case 'SET_OWNER_USER_LIST_EMPTY':
      return {
        ...state,
        list_all_owner_users: [],
      }

    case 'SET_All_ORGANIZATIONS':
      return {
        ...state,
        organization: action.response,
      }

    case 'SET_EMPTY_ORGANIZATIONS':
      return {
        ...state,
        organization: [],
      }

    case 'SET_LOADER_SHOW':
      return {
        ...state,
        show_Loader: true
      }
    case 'SET_LOADER_HIDE':
      return {
        ...state,
        show_Loader: false
      }
    case 'SET_COUNTRIES_LIST_RESPONSE':
      return {
        ...state,
        country_list: action.response,
      }

    case 'SET_COMPANY_SEARCH_LIST_RES':
      return {
        ...state,
        companySearchList: action.response,
      }

    case 'SET_CONTACT_SEARCH_LIST_RES':
      return {
        ...state,
        contactSearchList: action.response,
      }

    case 'SET_CURRENCY_LIST_RES':
      return {
        ...state,
        currency_list: action.response,
      }

    case 'SET_PIPELINE_LIST_RES':
      return {
        ...state,
        pipeline_list: action.response,
      }

    case 'SET_PIPELINE_LIST_EMPTY_RES':
      return {
        ...state,
        pipeline_list: [],
      }


    case 'SET_COMPANY_PRODUCTS_RES':
      return {
        ...state,
        company_products_list: action.response,
      }

      case 'SET_COMPANY_PRODUCTS_EMPTY_RES':
        return {
          ...state,
          company_products_list: [],
        }

    case 'SET_CURRENT_USER_SUCCESS_RES':
      return {
        ...state,
        CurrentUser: action.response
      }

    case 'SET_CURRENT_USER_FAILS_RES':
      return {
        ...state,
        CurrentUser: []
      }

    case 'SET_CURRENT_USER_EMPTY_RES':
      return {
        ...state,
        CurrentUser: []
      }

    case 'SET_USER_PROFILE_RES':
      return {
        ...state,
        userProfile: action.response,
        userProfileError: false,
      }

    case 'SET_USER_PROFILE_FAILS_RES':
      return {
        ...state,
        userProfile: action.response,
        userProfileError: true,
      }

    case 'SET_USER_PROFILE_EMPTY':
      return {
        ...state,
        userProfile: {},
        userProfileError: false,
      }
    
      case 'SET_PROFILE_PIC_RES':
        return {
          ...state,
          userProfilePic: action.response,
          userProfilePicError: false,
        }
  
      case 'SET_PROFILE_PIC_FAILS_RES':
        return {
          ...state,
          userProfilePic: action.response,
          userProfilePicError: true,
        }
  
      case 'SET_PROFILE_PIC_EMPTY':
        return {
          ...state,
          userProfilePic: null,
          userProfilePicError: false,
        }

    default:
      return state;
  }
};

export default commonStore;
