import "../styles/css/test.css";

import {
  Avatar,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  ListItemSecondaryAction,
  Paper,
  Popper,
} from "@material-ui/core";
import { CloseRounded, NoEncryption } from "@material-ui/icons";
import { Link, withRouter } from "react-router-dom";
import { getMessaging, onMessage } from "firebase/messaging";

import AddIcon from "@material-ui/icons/Add";
import AppBar from "@material-ui/core/AppBar";
import Badge from "@material-ui/core/Badge";
import BottomEditIcon from "../components/bottomedit/bottomedit";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import CssBaseline from "@material-ui/core/CssBaseline";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import Drawer from "@material-ui/core/Drawer";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Fab from "@material-ui/core/Fab";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
// import SearchIcon from '@material-ui/icons/Search';
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import { Helmet } from "react-helmet";
import IconButton from "@material-ui/core/IconButton";
import Icons from "../components/icons";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Logoutconfirmation from "../components/dialogbox/LogoutConfirmation";
import MenuIcon from "@material-ui/icons/Menu";
import NavList from "../components/navigation/NavList";
import NavProfileContainer from "../containers/profile/nav_profile";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import Popover from "@material-ui/core/Popover";
import ProfilePhoto from "../assets/images/user-icon-jpg-11.jpg";
import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import apiToken from "../api/token";
import axios from "axios";
import clsx from "clsx";
import { connect } from "react-redux";
import endpoints from "../api/endpoints";
import { getNotifications } from "../actions/homeActions";
import moment from "moment";
import parse from "date-fns/parse";
import { withStyles } from "@material-ui/core/styles";

// let api_token = apiToken();
// const token = api_token.accessToken;

const drawerWidth = 233;
const TITLE = "Save on CRM : ";

const styles = (theme) => ({
  root: {
    display: "flex",
    fontFamily: "sans-serif",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight: "0px",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  nav: {
    backgroundColor: "#1a1a1a",
    cursur: "pointer",
  },

  toolBarTop: {
    position: "fixed",
    zIndex: "700",
    boxShadow:
      "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    backgroundColor: "#202020",
    width: drawerWidth,
  },
  expandIcon: {
    color: "white",
  },
  listText: {
    color: "#797979 ",
    padding: "0 16px",
    fontWeight: "400",
    fontSize: "16px",
  },
  card: {
    backgroundColor: "primary",
  },
  subMenu: {
    backgroundColor: "#323232",
  },

  menuListItem: {
    marginTop: "8px !importatnt",
    "&:hover": {
      backgroundColor: "#313131",
    },
  },
  subMenuListItem: {
    "&:hover": {
      backgroundColor: "#3A3A3A",
    },
  },
  link: {
    underline: "none",
  },
  grow: {
    flexGrow: 1,
  },
  notic_container: {
    minWidth: 350,
    paddingLeft: "5%",
    backgroundColor: "#EEEEEE",
  },
  help_container: {
    minWidth: 350,
    padding: "2% 2% 2% 2%",
  },
  fab: {
    position: "fixed",
    right: 10,
    bottom: 10,
    transition: "tarnsform 2s",
  },
});

function notificationCount(notifications) {
  var notifications_count = 0;
  var notifications =
    notifications && notifications.filter((item) => item.is_read === false);
  notifications_count = notifications.length;
  return notifications_count;
}

class SideNav extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      userMenuOpen: false,
      menuList: [],
      profileName: "",
      profileDesignation: "",
      profileImage: "",
      search: false,
      searchValue: "",
      fullScreen: false,
      notification: false,
      helpOpen: false,
      agin_get: true,
      anchorEl: "",
      company_name: "",
      current_year: new Date().getFullYear(),
      quarter: 1,
      menuButtons: false,
      openConfirmation: false,
      user_currency: "",
      user_role: "",
      notifications_count: 0,
      global_search_data: {},
    };
  }

  componentWillMount() {
    var notifications = this.props.notifications;
    var notifications_count = 0;
    this.getUserDetail();
    this.props.dispatch(getNotifications());
    notifications_count = notificationCount(notifications);
    this.setState({
      notifications_count: notifications_count,
    });
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      this.props.dispatch(getNotifications());
      notifications_count = notificationCount(notifications);
      this.setState({
        notifications_count: notifications_count,
      });
      // ...
    });
  }

  // componentDidUpdate() {
  // await sleep(5000)
  // await setTimeout(()=>{
  //   this.props.dispatch(getNotifications());
  // },5000)
  // console.log(this.props.notifications);
  // }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  daysdifference = (date1, date2) => {
    // The number of milliseconds in one day
    var ONEDAY = 1000 * 60 * 60 * 24;
    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    // Calculate the difference in milliseconds
    var difference_ms = Math.abs(date1_ms - date2_ms);

    // Convert back to days and return
    return Math.round(difference_ms / ONEDAY);
  };

  getUserDetail = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    let url = endpoints.GET_PROFILE;
    axios
      .get(url, {
        crossDomain: true,
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          user_currency: res.data.company.currency,
        });
        sessionStorage.setItem("current_user_id", res.data.profile.user);
        sessionStorage.setItem(
          "user_currency",
          res.data.company.currency.currency_id
        );
        sessionStorage.setItem(
          "user_designation_id",
          res.data.profile.designation.designation_id
        );
        sessionStorage.setItem(
          "user_designation_name",
          res.data.profile.designation.designation_name
        );
        sessionStorage.setItem("user_designation", res.data.profile?.roles[0]);
        let financial_year =
          res.data.company.fa_start_date != ""
            ? parseInt(res.data.company.fa_start_date.split("-")[1])
            : 0;

        let quarter = 0,
          count_weeks = 0;
        if (financial_year > 0) {
          let currentMonth = new Date().getMonth() + 1;
          let count_month = 0;
          if (financial_year > currentMonth) {
            count_month = 12 - financial_year + currentMonth + 1;
          } else {
            count_month = currentMonth - financial_year + 1;
          }
          let reminder = count_month % 3;
          quarter =
            reminder > 0
              ? (count_month - reminder) / 3 + 1
              : (count_month - reminder) / 3;
          let dt = new Date();
          let totaldays = 0;
          if (count_month === 1) {
            let st_date = new Date(dt.getFullYear() - 1, 11, 1);
            totaldays = (new Date() - st_date) / (1000 * 3600 * 24);
          } else {
            let st_date = new Date(
              dt.getFullYear(),
              parseInt(financial_year) - 1,
              1
            );
            totaldays = (new Date() - st_date) / (1000 * 3600 * 24);
          }
          let weeks = totaldays / 7;
          count_weeks = Math.ceil(weeks);
        }
        this.setState({
          menuList: res.data.menus.filter((num) => num.visible == true && num.permission == true),
          profileName:
            res.data.profile.first_name + " " + res.data.profile.last_name,
          profileDesignation: res.data.profile.designation.designation_name,
          profileImage: endpoints.BASE_URL + res.data.profile.avatar_full,
          company_name: res.data.company.company_name,
          quarter: quarter,
          count_weeks: count_weeks,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getUserDetail2 = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    let url = endpoints.GET_PROFILE;
    axios
      .get(url, {
        crossDomain: true,
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        this.setState({
          // menuList: res.data.menus.filter((num) => num.visible == true),
          agin_get: true,
          profileName:
            res.data.profile.first_name + " " + res.data.profile.last_name,
          profileDesignation: res.data.profile.designation.designation_name,
          profileImage: endpoints.BASE_URL + res.data.profile.avatar_full,
          company_name: res.data.company.company_name,
          // quarter: quarter,
          // count_weeks: count_weeks
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleMenuClick = (menu, is_submenu) => {
    if (menu.link == false) {
      let menuList = this.state.menuList;
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].name == menu.name) {
          menuList[i].open = !menuList[i].open;
        }
      }
      this.setState({ menuList: menuList });
    } else {
      // route page to supplied link
    }
  };

  // toggleSearchBox = (e) => {
  //   this.setState({ search: !this.state.search });
  // }
  toggleFullScreen = (e) => {
    this.setState({ fullScreen: !this.state.fullScreen });
  };
  handleNoticificationIcon = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      notification: true,
    });
  };
  handleNoticificationClose = (e) => {
    this.setState({
      anchorEl: "",
      notification: false,
    });
  };
  handleHelpOpen = (e) => {
    this.setState({
      anchorEl: e.currentTarget,
      helpOpen: true,
    });
  };
  handleHelpClose = (e) => {
    this.setState({
      anchorEl: "",
      helpOpen: false,
    });
  };

  logout = () => {
    localStorage.removeItem("access");
    sessionStorage.removeItem("access");
    this.props.history.push({
      pathname: "/",
    });
  };

  handleClick = () => {
    this.props.history.push("/user/profile");
  };

  handleImageClick = () => {
    this.props.history.push("/");
  };

  handleConfirmation = () => {
    this.setState({ openConfirmation: true });
  };

  handleClose = () => {
    this.setState({ openConfirmation: false });
  };

  notificationUpdate = async (id) => {
    let token = apiToken();
    // let data = this.getData();
    let url = endpoints.BASE_URL + "/api/notifications/read/" + id;
    await axios
      .put(
        url,
        {},
        {
          headers: {
            Authorization: token.accessToken,
          },
        }
      )
      .then((res) => {
        this.props.dispatch(getNotifications());
      });
  };

  handleNotificationClick = (code, p_id, n_id) => {
    if (code === "EXPCMT") {
      this.notificationUpdate(n_id);
    }
    if (code === "LEADSSIGN") {
      let url = "/lead/" + p_id + "/details";
      // this.props.history.push(url)
      window.location = url;
    } else if (code === "OPPASSIGN") {
      let url = "/opportunity/" + p_id + "/activities";
      // this.props.history.push(url)
      window.location = url;
    } else {
      let url = "/expense/all";
      // this.props.history.push(url)
      window.location = url;
    }
    this.notificationUpdate(n_id);
    this.props.dispatch(getNotifications());
  };

  toggleSearchBox = () => {
    this.setState(
      {
        search: !this.state.search,
      },
      () => {
        this.setState({
          global_search_show: !this.state.search,
        });
      }
    );
  };

  globalFilterApi = (params) => {
    let token = apiToken();
    let url = endpoints.BASE_URL + "/api/core/global-search/" + params;
    var api_token = token.accessToken;
    let headers = {
      Authorization: api_token,
    };
    this.setState({
      global_search_loading: true,
    });
    axios
      .get(url, {
        headers: headers,
      })
      .then((res) => {
        this.setState({
          global_search_data: res.data,
          global_search_loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          global_search_loading: false,
        });
        console.log(error);
      });
  };

  handleSearch = (event) => {
    if (event.target.value) {
      this.globalFilterApi(event.target.value);
    }

    this.setState({ searchValue: event.target.value });
  };

  render() {
    // const [mobileOpen, setMobileOpen] = React.useState(false);
    const { classes, children } = this.props;
    var {
      opportunities,
      contacts,
      leads,
      organizations,
      opportunity_activities,
    } = this.state.global_search_data;
    return (
      <div className={classes.root} className="mainappbar">
        <Helmet>
          <title>{TITLE + this.state.company_name}</title>
        </Helmet>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
          color="default"
          elevation={1}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className="hamburgermenu"
            >
              <MenuIcon />
            </IconButton>
            <Typography
              style={{ fontSize: "16px", marginTop: "-1px", fontWeight: "400" }}
              variant="h6"
              noWrap
              className="topheading"
            >
              <span>{this.state.company_name}</span>
              <span>{this.state.current_year}</span>
              <span>Quarter {this.state.quarter}</span>
              <span>Week {this.state.count_weeks}</span>
              {this.state.user_currency !== "" &&
              this.state.user_currency !== undefined ? (
                <span>
                  {" "}
                  {this.state.user_currency?.currency_name +
                    "(" +
                    this.state.user_currency?.currency_symbol +
                    ")"}
                </span>
              ) : (
                ""
              )}
              {/* Maanya It Services Pvt Ltd | 2020 | Quarter | Week 8 | */}
            </Typography>
            <div className={classes.grow} />
            <div className="notificationbtn">
              {this.state.search ? (
                <div>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <TextField
                        placeholder="Global Search"
                        label="Global Search"
                        value={this.state.searchValue}
                        onChange={this.handleSearch}
                        // variant="outlined"
                        onFocus={(e) => {
                          this.setState({
                            global_search_show: true,
                            global_search_anchorEl: e.currentTarget,
                          });
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <div className={classes.root}>
                                {this.state.global_search_loading ? (
                                  <CircularProgress color="primary" />
                                ) : (
                                  <IconButton onClick={this.toggleSearchBox}>
                                    <CloseRounded />
                                  </IconButton>
                                )}
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    {!this.state.search ? (
                      <Grid item>
                        <IconButton onClick={this.toggleSearchBox}>
                          <SearchIcon />
                        </IconButton>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Popper
                    // id={'transitions-popper'}
                    open={this.state.global_search_show}
                    anchorEl={this.state.global_search_anchorEl}
                    placement="top-end"
                    disablePortal={true}
                    modifiers={{
                      flip: {
                        enabled: true,
                      },
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                      },
                      arrow: {
                        enabled: true,
                        element: this.state.global_search_anchorEl,
                      },
                    }}
                    transition
                    style={{ zIndex: 5000, top: "4px !important" }}
                  >
                    {organizations || leads || contacts || opportunities ? (
                      <Paper
                        style={{
                          width: 700,
                          padding: 10,
                          overflowY: "auto",
                          maxHeight: 500,
                          background: "#87909b",
                        }}
                      >
                        <Grid container spacing={2}>
                          {/* <legend>opportunities</legend> */}
                          <Grid item md={12}>
                            <strong>
                              ORAGANIZATIONS(
                              {organizations ? organizations.length : 0})
                            </strong>
                          </Grid>
                          {organizations &&
                            organizations.map((item, i) => (
                              <Grid item>
                                <Chip
                                  variant="default"
                                  size="small"
                                  label={item.organization_name}
                                  component="a"
                                  href={
                                    "/organisation/" +
                                    item.organization_id +
                                    "/details"
                                  }
                                  color="default"
                                  clickable
                                />
                              </Grid>
                            ))}
                          <Grid item md={12}>
                            <strong>LEADS({leads ? leads.length : 0})</strong>
                          </Grid>
                          {leads &&
                            leads.map((item, i) => (
                              <Grid item>
                                <Chip
                                  variant="default"
                                  size="small"
                                  label={item.first_name + " " + item.last_name}
                                  component="a"
                                  href={"/lead/" + item.lead_id + "/details"}
                                  color="default"
                                  clickable
                                />
                              </Grid>
                            ))}
                          <Grid item md={12}>
                            <strong>
                              CONTACTS({contacts ? contacts.length : 0})
                            </strong>
                          </Grid>
                          {contacts &&
                            contacts.map((item, i) => (
                              <Grid item>
                                <Chip
                                  variant="default"
                                  size="small"
                                  label={item.first_name + " " + item.last_name}
                                  component="a"
                                  href={
                                    "/contact/" + item.contact_id + "/details"
                                  }
                                  color="default"
                                  clickable
                                />
                              </Grid>
                            ))}
                          <Grid item md={12}>
                            <strong>
                              OPPORTUNITIES(
                              {opportunities ? opportunities.length : 0})
                            </strong>
                          </Grid>
                          {opportunities &&
                            opportunities.map((item, i) => (
                              <Grid item>
                                <Chip
                                  variant="default"
                                  size="small"
                                  label={item.opportunity_name}
                                  component="a"
                                  href={
                                    "/opportunity/" +
                                    item.opportunity_id +
                                    "/activities"
                                  }
                                  color="default"
                                  clickable
                                />
                              </Grid>
                            ))}
                        </Grid>
                      </Paper>
                    ) : undefined}
                  </Popper>
                </div>
              ) : (
                <div>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      <IconButton onClick={this.toggleSearchBox}>
                        <SearchIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              )}
              {/* <IconButton onClick={this.toggleSearchBox}>
                <SearchIcon />
              </IconButton> */}
            </div>

            <div className="notificationbtn">
              <IconButton onClick={this.handleNoticificationIcon}>
                <Badge
                  color="secondary"
                  badgeContent={notificationCount(this.props.notifications)}
                >
                  <NotificationsOutlinedIcon />
                </Badge>
              </IconButton>
              <Popover
                // id={id}
                open={this.state.notification}
                anchorEl={this.state.anchorEl}
                onClose={this.handleNoticificationClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: { overflow: "hidden" },
                }}
              >
                <div className={classes.notic_container}>
                  <Typography variant="h6">Notifications</Typography>
                  <Typography variant="body2">
                    You have {notificationCount(this.props.notifications)} new
                    notifications
                  </Typography>
                  <Divider />
                </div>
                <List className="overflow-add">
                  {this.props.notifications &&
                    this.props.notifications.map((data, ind) => (
                      <ListItem
                        button
                        onClick={this.handleNotificationClick.bind(
                          this,
                          data.page_code,
                          data.parent_id,
                          data.notification_id
                        )}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="body2">
                              {data.notification_title}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{ whiteSpace: "pre" }}
                            >
                              {data.notification_content}{" "}
                              <strong>
                                By{" "}
                                {data.from_user?.first_name +
                                  " " +
                                  data.from_user?.last_name}
                              </strong>
                              <br />
                              <span>
                                {moment(data.created_on).format("DD/MM/YYYY")}
                              </span>
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          {!data.is_read ? (
                            <FiberManualRecordIcon
                              color="primary"
                              fontSize="small"
                            />
                          ) : (
                            ""
                          )}
                        </ListItemSecondaryAction>

                        {/* </ListItemText> */}
                      </ListItem>
                    ))}
                </List>

                {!this.props.notifications &&
                !this.props.notifications.length <= 0 ? (
                  <div>
                    <Typography variant="h6" style={{ textAlign: "center" }}>
                      No data found
                    </Typography>
                  </div>
                ) : (
                  ""
                )}
              </Popover>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          containerstyle={{ transform: "none" }}
          elevation={4}
          open={this.state.open}
          data-name="Drawer"
          variant="persistent"
          className={classes.drawer}
          className="maindrawer"
          // onClick={this.handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <AppBar position="relative" elevation={1}>
            <Toolbar
              className={classes.toolBarTop}
              onClick={this.handleImageClick}
            >
              <div className="logofield">
                <img
                  src={require("../assets/images/lucrum-crm-white@3x.png")}
                  style={{ width: 151 }}
                />
              </div>
            </Toolbar>
          </AppBar>
          {/* <Divider classname={classes.dividerColor} /> */}

          <div className="scrollnavbar">
            <NavProfileContainer />
            <NavList menuList={this.state.menuList} handleMenuClick={this.handleMenuClick} />
            {/* <List
              className={classes.nav}
              component="nav"
              style={{ width: "233px" }}
            >
              {this.state.menuList.map((item, pindex) => {
                let styleIcon = {};
                if (item.icon === "dashboard") {
                  styleIcon = {
                    width: "24.89px",
                    height: "35px",
                    marginTop: "2px",
                  };
                } else if (item.icon === "leads") {
                  styleIcon = {
                    width: "24px",
                    height: "36px",
                    marginRight: "5px",
                  };
                } else if (item.icon === "accounts") {
                  styleIcon = {
                    width: "33px",
                    height: "35px",
                    marginRight: "-8px",
                    marginTop: "6px",
                  };
                } else if (item.icon === "opportunity") {
                  styleIcon = {
                    width: "28px",
                    height: "35px",
                    marginRight: "1px",
                    marginTop: "3px",
                  };
                } else if (item.icon === "expenses") {
                  styleIcon = { width: "25px", height: "35px" };
                } else if (item.icon === "configuration") {
                  styleIcon = { width: "25px", height: "35px" };
                } else if (item.icon === "reports") {
                  styleIcon = {
                    width: "17.5px",
                    height: "35px",
                    marginRight: "11px",
                    marginTop: "-1px",
                  };
                }
                return item.sub && item.sub.length > 0 ? (
                  <div className="menuli" key={pindex}>
                    <ListItem
                      className={classes.menuListItem}
                      button
                      onClick={this.handleMenuClick.bind(this, item, false)}
                      style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    >
                      <Icons
                        name={item.icon}
                        htmlColor="#fff"
                        style={styleIcon}
                      />
                      <ListItemText
                        primary={
                          <Typography className={classes.listText}>
                            {" "}
                            {item.name}{" "}
                          </Typography>
                        }
                      />
                      {item.open ? (
                        <ExpandLess className={classes.expandIcon} />
                      ) : (
                        <ExpandMore
                          className={classes.expandIcon}
                          style={{
                            width: "26px",
                            height: "26px",
                            paddingLeft: "1px",
                            marginRight: "-1px",
                            marginTop: "2px",
                            color: "#fff",
                          }}
                        />
                      )}
                    </ListItem>
                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                      <List
                        className={classes.subMenu}
                        component="div"
                        disablePadding
                      >
                        {item.name.toLowerCase() == "accounts"
                          ? item.sub.map((subMenu, index) => (
                              <ListItem
                                key={index}
                                className={classes.menuListItem}
                                {...{
                                  to: subMenu.link.startsWith("/")
                                    ? subMenu.link
                                    : "/" + subMenu.link,
                                }}
                                component={Link}
                                button={true}
                              >
                                <ListItemText
                                  primary={
                                    <Typography className={classes.listText}>
                                      {" "}
                                      <span
                                        style={{ marginRight: "25px" }}
                                      ></span>
                                      {subMenu.name}{" "}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))
                          : item.name.toLowerCase() == "stage"
                          ? item.sub
                              .filter((subName) => {
                                if (subName.name.toLowerCase() == "stages") {
                                  return subName;
                                }
                              })
                              .map((subMenu, index) => (
                                <ListItem
                                  key={index}
                                  className={classes.menuListItem}
                                  {...{
                                    to: subMenu.link.startsWith("/")
                                      ? subMenu.link
                                      : "/" + subMenu.link,
                                  }}
                                  component={Link}
                                  button={true}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography className={classes.listText}>
                                        {" "}
                                        <span
                                          style={{ marginRight: "25px" }}
                                        ></span>
                                        {subMenu.name}{" "}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))
                          : item.name.toLowerCase() == "expenses"
                          ? item.sub
                              .filter((subName) => {
                                if (subName.visible == true) {
                                  return subName;
                                  // if (sessionStorage.getItem("user_designation") == 'sales_director' && subName.name.toLowerCase() == 'sales approval') {
                                  //   return subName
                                  // }
                                  // if (sessionStorage.getItem("user_designation") == 'sales_team_lead' && subName.name.toLowerCase() == 'sales approval') {
                                  //   return subName
                                  // }
                                  // if (sessionStorage.getItem("user_designation") == 'sales_manager' && subName.name.toLowerCase() == 'sales approval') {
                                  //   return subName
                                  // }
                                  // else if (sessionStorage.getItem("user_designation") == 'finance_director' && subName.name.toLowerCase() == 'finance approval') {
                                  //   return subName
                                  // }
                                  // else if (sessionStorage.getItem("user_designation") == 'company_admin') {
                                  //   return subName
                                  // } else if (subName.name.toLowerCase() === 'my expenses') {
                                  //   return subName
                                  // }
                                }
                              })
                              .map((subMenu, index) => (
                                <ListItem
                                  key={index}
                                  className={classes.menuListItem}
                                  {...{
                                    to: subMenu.link.startsWith("/")
                                      ? subMenu.link
                                      : "/" + subMenu.link,
                                  }}
                                  component={Link}
                                  button={true}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography className={classes.listText}>
                                        {" "}
                                        <span
                                          style={{ marginRight: "25px" }}
                                        ></span>
                                        {subMenu.name}{" "}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))
                          : item.name.toLowerCase() == "configuration"
                          ? item.sub
                              .filter((subName) => {
                                if (
                                  subName.name.toLowerCase() !==
                                  "company profile"
                                ) {
                                  return subName;
                                }
                              })
                              .map((subMenu, index) => (
                                <ListItem
                                  key={index}
                                  className={classes.menuListItem}
                                  {...{
                                    to: subMenu.link.startsWith("/")
                                      ? subMenu.link
                                      : "/" + subMenu.link,
                                  }}
                                  component={Link}
                                  button={true}
                                >
                                  <ListItemText
                                    primary={
                                      <Typography className={classes.listText}>
                                        {" "}
                                        <span
                                          style={{ marginRight: "25px" }}
                                        ></span>
                                        {subMenu.name}{" "}
                                      </Typography>
                                    }
                                  />
                                </ListItem>
                              ))
                          : item.sub.map((subMenu, index) => (
                              <ListItem
                                key={index}
                                className={classes.menuListItem}
                                {...{
                                  to: subMenu.link.startsWith("/")
                                    ? subMenu.link
                                    : "/" + subMenu.link,
                                }}
                                component={Link}
                                button={true}
                              >
                                <ListItemText
                                  primary={
                                    <Typography className={classes.listText}>
                                      {" "}
                                      <span
                                        style={{ marginRight: "25px" }}
                                      ></span>
                                      {subMenu.name}{" "}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            ))}
                      </List>
                    </Collapse>
                  </div>
                ) : (
                  <ListItem
                    key={pindex}
                    className={classes.menuListItem}
                    {...{
                      to: item?.link?.startsWith("/")
                        ? item.link
                        : "/" + item.link,
                    }}
                    component={Link}
                    button={true}
                  >
                    <Icons
                      name={item.icon}
                      htmlColor="#fff"
                      style={styleIcon}
                    />
                    <ListItemText
                      primary={
                        <Typography className={classes.listText}>
                          {" "}
                          {item.name}{" "}
                        </Typography>
                      }
                      className="menufont"
                    />
                  </ListItem>
                );
              })}
            </List> */}
          </div>

          <div
            style={{
              position: "fixed",
              bottom: "0px",
              width: "233px",
              background: "#282828",
              zIndex: "100000",
            }}
          >
            <div style={{ borderTop: "2px solid #979797" }}>
              <div style={{ display: "flex" }}>
                <button
                  onClick={this.handleHelpOpen}
                  style={{
                    backgroundColor: "#282828",
                    border: "none",
                    cursor: "pointer",
                    color: "#fff",
                    fontWeight: "600",
                    width: "52%",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px 0",
                    fontFamily: "'Poppins', sans-serif",
                    paddingRight: "6px",
                  }}
                >
                  Help
                </button>
                <Popover
                  style={{ zIndex: "99999" }}
                  // id={id}
                  open={this.state.helpOpen}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleHelpClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.help_container}>
                    <Typography variant="h6">Help</Typography>
                    <Divider />
                    <Typography variant="body2">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s, when
                      an unknown printer took a galley of type and scrambled it
                      to make a type specimen book. It has survived not only
                      five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the 1960s with the release of Letraset
                      sheets containing Lorem Ipsum passages, and more recently
                      with desktop publishing software like Aldus PageMaker
                      including versions of Lorem Ipsum. Lorem Ipsum is simply
                      dummy text of the printing and typesetting industry. Lorem
                      Ipsum has been the industry's standard dummy text ever
                      since the 1500s, when an unknown printer took a galley of
                      type and scrambled it to make a type specimen book. It has
                      survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged.
                      It was popularised in the 1960s with the release of
                      Letraset sheets containing Lorem Ipsum passages, and more
                      recently with desktop publishing software like Aldus
                      PageMaker including versions of Lorem Ipsum. Lorem Ipsum
                      is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                      dummy text ever since the 1500s, when an unknown printer
                      took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries,
                      but also the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s
                      with the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum. Lorem Ipsum is simply dummy text of the printing
                      and typesetting industry. Lorem Ipsum has been the
                      industry's standard dummy text ever since the 1500s, when
                      an unknown printer took a galley of type and scrambled it
                      to make a type specimen book. It has survived not only
                      five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the 1960s with the release of Letraset
                      sheets containing Lorem Ipsum passages, and more recently
                      with desktop publishing software like Aldus PageMaker
                      including versions of Lorem Ipsum. Lorem Ipsum is simply
                      dummy text of the printing and typesetting industry. Lorem
                      Ipsum has been the industry's standard dummy text ever
                      since the 1500s, when an unknown printer took a galley of
                      type and scrambled it to make a type specimen book. It has
                      survived not only five centuries, but also the leap into
                      electronic typesetting, remaining essentially unchanged.
                      It was popularised in the 1960s with the release of
                      Letraset sheets containing Lorem Ipsum passages, and more
                      recently with desktop publishing software like Aldus
                      PageMaker including versions of Lorem Ipsum. Lorem Ipsum
                      is simply dummy text of the printing and typesetting
                      industry. Lorem Ipsum has been the industry's standard
                      dummy text ever since the 1500s, when an unknown printer
                      took a galley of type and scrambled it to make a type
                      specimen book. It has survived not only five centuries,
                      but also the leap into electronic typesetting, remaining
                      essentially unchanged. It was popularised in the 1960s
                      with the release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </Typography>
                    <center>
                      <button
                        onClick={this.handleHelpClose}
                        style={{ alignSelf: "baseline" }}
                      >
                        Close
                      </button>
                    </center>
                  </div>
                </Popover>
                <div
                  style={{ height: "38px", borderLeft: "2px solid #979797" }}
                ></div>
                <button
                  onClick={this.handleConfirmation}
                  style={{
                    backgroundColor: "#282828",
                    borderLeft: "2px solid #979797",
                    border: "none",
                    float: "right",
                    cursor: "pointer",
                    color: "#fff",
                    fontWeight: "600",
                    width: "49%",
                    textAlign: "center",
                    display: "inline-block",
                    textDecoration: "none",
                    fontSize: "12px",
                    padding: "10px 0",
                    fontFamily: "'Poppins', sans-serif",
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </Drawer>

        <main
          className={clsx(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
        >
          <div className={classes.drawerHeader} />
          <section className={classes.mainWrap} className="mainsection">
            <div className="expensesallfield">
              <div className="expensetablebox">{children}</div>
            </div>
          </section>
        </main>

        <Popover
          open={this.state.menuButtons}
          onClose={this.menuButton}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <div>
            absolute
            <ListItem button>
              <Icons name={"csv"} style={{ margin: 10 }}></Icons>
              <ListItemText>
                <Typography variant="body2">Export to CSV</Typography>
              </ListItemText>
            </ListItem>
          </div>
          <div>
            <ListItem button>
              <Icons name={"pdf"} style={{ margin: 10 }}></Icons>
              <ListItemText>
                <Typography variant="body2">Export to PDF</Typography>
              </ListItemText>
            </ListItem>
          </div>
        </Popover>
        {this.state.openConfirmation ? (
          <Logoutconfirmation
            open={this.state.openConfirmation}
            handleLogout={this.logout}
            handleClose={this.handleClose}
          />
        ) : undefined}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notifications: state.dashboardStore.notifications,
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(SideNav))
);
