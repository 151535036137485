
import React from 'react';
import './App.css';
import { Route, BrowserRouter as Router } from 'react-router-dom'
import { Switch } from 'react-router-dom';
import SideNav from '../containers/nav';
import Application from './Application';
import Loading from '../components/loding-component/loadingComponent';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import { getFCMToken } from './firebaseInit'

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#202020',
    },
    secondary: {
      light: '#ede1e5',
      main: '#ff4081',
      dark: '#102131',
      contrastText: '#fff',
      // main: '#ff4081',
      // light: 'rgba(255, 64, 129, 0.15)'
    },
  },
  typography: {
    color: 'red',
    fontFamily: [
      // 'Lexend Deca',
      // 'Roboto',
      'Poppins',
      'sans-serif'
    ].join(','),
    body1: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.45)'
    },
    subtitle1: {
      fontWeight: 'bold',
      color: 'rgba(0, 0, 0, 0.45)'
    },
    subtitle2: {
      fontSize: '14px',
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    buttonText: {
      fontSize: '12px',
      fontWeight: '600',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '1px',
      color: '#282828',
      textTransform: 'uppercase'
    }
  }
});

function App() {
  getFCMToken();

  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Route component={Application} />
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
