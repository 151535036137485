const initialState = {
    userDesignation: [],
    userDesignationSuccess: false,

    userAll: [],
    userAllSuccess: false,

    department: [],
    departmentSuccess: false,

    userRole: [],
    userRoleSuccess: false,

    postUserRes: [],
    postUserResSuccess: false,

    putUserRes: [],
    putUserResSuccess: false,

    resetPassRes: {},
    resetPassResSuccess: false,

    deactivateUserRes: {},
    deactivateUserResSuccess: false,

    userProfileRes: {},
    userProfileResSuccess: false,
}

// user store reducer
const userStore = (state = initialState, action) => {
    switch (action.type) {

        case "SET_USER_DESIGNATION_SUCCESS_RES":
            return {
                userDesignation: action.response,
                userDesignationSuccess: true
            }
        case "SET_USER_DESIGNATION_Empty_RES":
            return {
                userDesignation: [],
                userDesignationSuccess: false
            }
        case "SET_USER_DESIGNATION_Fails_RES":
            return {
                userDesignation: action.response,
                userDesignationSuccess: false
            }


        // User All
        case "SET_USER_ALL_SUCCESS_RES":
            return {
                userAll: action.response,
                userAllSuccess: true
            }
        case "SET_USER_ALL_Empty_RES":
            return {
                userAll: [],
                userAllSuccess: true
            }
        case "SET_USER_ALL_Fails_RES":
            return {
                userAll: action.response,
                userAllSuccess: true
            }

        // User Role
        case "SET_USER_ROLE_SUCCESS_RES":
            return {
                userRole: action.response,
                userRoleSuccess: true
            }
        case "SET_USER_ROLE_Empty_RES":
            return {
                userRole: [],
                userRoleSuccess: true
            }
        case "SET_USER_ROLE_Fails_RES":
            return {
                userRole: action.response,
                userRoleSuccess: true
            }


        // Department
        case "SET_DEPARTMENT_SUCCESS_RES":
            return {
                department: action.response,
                departmentSuccess: true
            }
        case "SET_DEPARTMENT_Empty_RES":
            return {
                department: [],
                departmentSuccess: false
            }
        case "SET_DEPARTMENT_Fails_RES":
            return {
                department: action.response,
                departmentSuccess: false
            }

        //Post User
        case "SET_POST_USER_SUCCESS_RES":
            return {
                postUserRes: action.response,
                postUserResSuccess: true
            }
        case "SET_POST_USER_Empty_RES":
            return {
                postUserRes: [],
                postUserResSuccess: false
            }
        case "SET_POST_USER_Fails_RES":
            return {
                postUserRes: action.response,
                postUserResSuccess: false
            }

        //Put user
        case "SET_PUT_USER_SUCCESS_RES":
            return {
                putUserRes: action.response,
                putUserResSuccess: true
            }
        case "SET_PUT_USER_Empty_RES":
            return {
                putUserRes: [],
                putUserResSuccess: false
            }
        case "SET_PUT_USER_Fails_RES":
            return {
                putUserRes: action.response,
                putUserResSuccess: false
            }


        //Reset pass user
        case "SET_RESET_PASSWORD_SUCCESS_RES":
            return {
                resetPassRes: action.response,
                resetPassResSuccess: true,
            }
        case "SET_RESET_PASSWORD_Empty_RES":
            return {
                resetPassRes: {},
                resetPassResSuccess: false,
            }
        case "SET_RESET_PASSWORD_Fails_RES":
            return {
                resetPassRes: action.response,
                resetPassResSuccess: false,
            }

        //Deactivate user
        case "SET_DEACTIVE_USER_SUCCESS_RES":
            return {
                deactivateUserRes: action.response,
                deactivateUserResSuccess: true,
            }
        case "SET_DEACTIVE_USER_Empty_RES":
            return {
                deactivateUserRes: {},
                deactivateUserResSuccess: false,
            }
        case "SET_DEACTIVE_USER_Fails_RES":
            return {
                deactivateUserRes: action.response,
                deactivateUserResSuccess: false,
            }
        //user Profile
        case "SET_USER_PROFILE_SUCCESS_RES":
            return {
                userProfileRes: action.response,
                userProfileResSuccess: true,
            }
        case "SET_USER_PROFILE_Empty_RES":
            return {
                userProfileRes: {},
                userProfileResSuccess: false,
            }
        case "SET_USER_PROFILE_Fails_RES":
            return {
                userProfileRes: action.response,
                userProfileResSuccess: false,
            }

        default:
            return state;
    }
};

export default userStore;
