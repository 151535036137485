const initialState = {
    createProductRes: {},
    createProductResSuccess: true,

    getProductRes: {},
    getProductResSuccess: true,

    putProductRes: {},
    putProductResSuccess: true
}

// Product store reducer
const productStore = (state = initialState, action) => {
    switch (action.type) {
        case "SET_POST_PRODUCT_SUCCESS_RES":
            return {
                createProductRes: action.response,
                createProductResSuccess: true
            }
        case "SET_POST_PRODUCT_Empty_RES":
            return {
                createProductRes: {},
                createProductResSuccess: false
            }
        case "SET_POST_PRODUCT_Fails_RES":
            return {
                createProductRes: action.response,
                createProductResSuccess: false
            }

        //put Product
        case "SET_PUT_PRODUCT_SUCCESS_RES":
            return {
                putProductRes: action.response,
                putProductResSuccess: true
            }
        case "SET_PUT_PRODUCT_Empty_RES":
            return {
                putProductRes: {},
                putProductResSuccess: false
            }
        case "SET_PUT_PRODUCT_Fails_RES":
            return {
                putProductRes: action.response,
                putProductResSuccess: false
            }

        //get Product
        case "SET_GET_ALL_PRODUCT_SUCCESS_RES":
            return {
                getAllProductRes: action.response,
                createProductResSuccess: true
            }
        case "SET_GET_ALL_PRODUCT_Empty_RES":
            return {
                createProductRes: {},
                createProductResSuccess: false
            }
        case "SET_GET_ALL_PRODUCT_Fails_RES":
            return {
                createProductRes: action.response,
                createProductResSuccess: false
            }

        //get Single
        case "SET_GET_PRODUCT_SUCCESS_RES":
            return {
                getProductRes: action.response,
                getProductResSuccess: true
            }
        case "SET_GET_PRODUCT_Empty_RES":
            return {
                getProductRes: {},
                getProductResSuccess: false
            }
        case "SET_GET_PRODUCT_Fails_RES":
            return {
                getProductRes: action.response,
                getProductResSuccess: false
            }

        //delete Product
        case "SET_DELETE_PRODUCT_SUCCESS_RES":
            return {
                createProductRes: action.response,
                createProductResSuccess: true
            }
        case "SET_DELETE_PRODUCT_Empty_RES":
            return {
                createProductRes: {},
                createProductResSuccess: false
            }
        case "SET_DELETE_PRODUCT_Fails_RES":
            return {
                createProductRes: action.response,
                createProductResSuccess: false
            }

        default:
            return state;
    }
};

export default productStore;
