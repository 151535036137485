import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function ExportIcon(props) {
    return (
        <SvgIcon  {...props} fontSize="large" viewBox="0 -10 1 40">
            <path d="M8.483 19V5.944l5.841 6.086.676-.707L8 4l-7 7.323.676.707 5.841-6.086V19zM1 1h14v1H1z" />
        </SvgIcon>
    );
}

export default ExportIcon;
