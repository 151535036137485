import appConfig from './apiConfig';
import apiToken from '../api/token';
import endpoints from './endpoints';
import OpportunityForm from '../containers/opportunity/OpportunityForm';

// let api_token = apiToken();
// const token = api_token.accessToken;

export const getAllOwnerUsersApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.BASE_URL + '/user/all', {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(pipelineListResponse => {
            return pipelineListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getPipelineStageListApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.pipline_stage_get + id, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(pipelineListResponse => {
            return pipelineListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const createOpportunityApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.create_opportunity, {
        method: "POST",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(data)
    });

    return fetch(request).then(response => {
        // return response.json().then(OpportunityRes => {
            return response;
        // });
    }).catch(error => {
        return error;
    });
}

export const getSingleOpportunityApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_OPPORTUNITY + id, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        // body:JSON.stringify(data)
    });

    return fetch(request).then(response => {
        return response.json().then(OpportunityRes => {
            return OpportunityRes;
        });
    }).catch(error => {
        return error;
    });
}

export const updateOpportunityApi = (data, id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.UPDATE_OPPORTUNITY + "?allUpdate=true", {
        method: "PUT",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        },
        body: JSON.stringify(data)
    });

    return fetch(request).then(response => {
        // return response.json().then(OpportunityRes => {
            return response;
        // });
    }).catch(error => {
        return error;
    });
}