import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function CalendarIcon(props) {
    return (
      <SvgIcon {...props} viewBox ='0 0 20 10'>
       <path d="M14.358 1.642h-2.764V.536a.276.276 0 1 0-.553 0v2.488a.276.276 0 1 0 .553 0v-.83h2.764a.83.83 0 0 1 .83.83v2.488H.812V3.024a.83.83 0 0 1 .83-.83h2.211v-.552H1.642C.88 1.642.26 2.262.26 3.024v11.334c0 .762.62 1.382 1.382 1.382h12.717c.762 0 1.382-.62 1.382-1.382V3.024c0-.763-.62-1.382-1.383-1.382zm.83 12.716a.83.83 0 0 1-.83.83H1.642a.83.83 0 0 1-.83-.83V6.065h14.376v8.293z"/>
        <path d="M4.683 3.3a.276.276 0 0 0 .276-.276v-.83h5.529v-.552H4.959V.536a.276.276 0 1 0-.553 0v2.488c0 .153.124.276.277.276z"/>
      </SvgIcon>
    );
}

export default CalendarIcon;