export default function currencyFormate(value) {
    var amount = 0;
    value = parseFloat(value)
    if (value) {
        if (value >= 10000) {
            value = value / 1000;
            amount = value.toFixed(1).replace(/\.0+$/,'') + 'K';
            if (value >= 1000) {
                value = value / 1000;
                amount = value.toFixed(1).replace(/\.0+$/,'') + 'M';
                if (value >= 1000) {
                    value = value / 1000;
                    amount = value.toFixed(1).replace(/\.0+$/,'') + 'B';
                    if (value >= 1000) {
                        value = value / 1000;
                        amount = value.toFixed(1).replace(/\.0+$/,'') + 'T';
                    }
                }
            } 
        } else {
            amount = value.toFixed(1).replace(/\.0+$/,'')
        }
    }
    return amount;
}