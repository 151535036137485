import {
    getUserDesignationsApi, getUserAllApi,
    getDepartmentApi, getUserRoleApi, postUserApi,
    updateUserApi, getUserApi, deleteUserApi,
    ActivateDeactivateUserApi,
    getUserResetPasswordApi,
    getUserProfileApi
} from '../api/userApi';
import {
    setAtomBallSpinnerShowSalesDashboard,
    setAtomBallSpinnerHideSalesDashboard
} from './reportAction'

export const setUserDesignationSuccessRes = (response) => {
    return {
        type: "SET_USER_DESIGNATION_SUCCESS_RES",
        response
    }
}

export const setUserDesignationEmptyRes = (response) => {
    return {
        type: "SET_USER_DESIGNATION_Empty_RES",
    }
}

export const setUserDesignationFailsRes = (response) => {
    return {
        type: "SET_USER_DESIGNATION_Fails_RES",
        response
    }
}


export const getDesignations = () => {
    return dispatch => {
        dispatch(setUserDesignationEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        getUserDesignationsApi().then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setUserDesignationSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setUserDesignationFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}


export const setUserAllSuccessRes = (response) => {
    return {
        type: "SET_USER_ALL_SUCCESS_RES",
        response
    }
}

export const setUserAllEmptyRes = (response) => {
    return {
        type: "SET_USER_ALL_Empty_RES",
    }
}

export const setUserAllFailsRes = (response) => {
    return {
        type: "SET_USER_ALL_Fails_RES",
        response
    }
}


export const getUserAll = (roles) => {
    return dispatch => {
        dispatch(setUserAllEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        getUserAllApi(roles).then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setUserAllSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setUserAllFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}


export const setDepartmentSuccessRes = (response) => {
    return {
        type: "SET_DEPARTMENT_SUCCESS_RES",
        response
    }
}

export const setDepartmentEmptyRes = (response) => {
    return {
        type: "SET_DEPARTMENT_Empty_RES",
    }
}

export const setDepartmentFailsRes = (response) => {
    return {
        type: "SET_DEPARTMENT_Fails_RES",
        response
    }
}


export const getDepartment = () => {
    return dispatch => {
        dispatch(setDepartmentEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        getDepartmentApi().then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setDepartmentSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setDepartmentFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}


export const setUserRoleSuccessRes = (response) => {
    return {
        type: "SET_USER_ROLE_SUCCESS_RES",
        response
    }
}

export const setUserRoleEmptyRes = (response) => {
    return {
        type: "SET_USER_ROLE_Empty_RES",
    }
}

export const setUserRoleFailsRes = (response) => {
    return {
        type: "SET_USER_ROLE_Fails_RES",
        response
    }
}


export const getUserRole = () => {
    return dispatch => {
        dispatch(setUserRoleEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        getUserRoleApi().then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setUserRoleSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setUserRoleFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}


export const setPostUserSuccessRes = (response) => {
    return {
        type: "SET_POST_USER_SUCCESS_RES",
        response
    }
}

export const setPostUserEmptyRes = (response) => {
    return {
        type: "SET_POST_USER_Empty_RES",
    }
}

export const setPostUserFailsRes = (response) => {
    return {
        type: "SET_POST_USER_Fails_RES",
        response
    }
}


export const postUser = (data) => {
    return dispatch => {
        dispatch(setPostUserEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        postUserApi(data).then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setPostUserSuccessRes(jsonRes));
                    dispatch(resetPasswordUser(jsonRes.email))
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setPostUserFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}

export const setPutUserSuccessRes = (response) => {
    return {
        type: "SET_PUT_USER_SUCCESS_RES",
        response
    }
}

export const setPutUserEmptyRes = (response) => {
    return {
        type: "SET_PUT_USER_Empty_RES",
    }
}

export const setPutUserFailsRes = (response) => {
    return {
        type: "SET_PUT_USER_Fails_RES",
        response
    }
}


export const updateUser = (data) => {
    return dispatch => {
        dispatch(setPutUserEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        updateUserApi(data).then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setPutUserSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setPutUserFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}

export const setUserProfileSuccessRes = (response) => {
    return {
        type: "SET_USER_PROFILE_SUCCESS_RES",
        response
    }
}

export const setUserProfileEmptyRes = () => {
    return {
        type: "SET_USER_PROFILE_Empty_RES",
    }
}

export const setUserProfileFailsRes = (response) => {
    return {
        type: "SET_USER_PROFILE_Fails_RES",
        response
    }
}


export const getUserProfile = () => {
    return dispatch => {
        dispatch(setUserProfileEmptyRes());
        getUserProfileApi().then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setUserProfileSuccessRes(jsonRes));
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setUserProfileFailsRes(jsonRes));
                })
            }

        })
    }
}

export const setResetPasswordSuccessRes = (response) => {
    return {
        type: "SET_RESET_PASSWORD_SUCCESS_RES",
        response
    }
}

export const setResetPasswordEmptyRes = (response) => {
    return {
        type: "SET_RESET_PASSWORD_Empty_RES",
    }
}

export const setResetPasswordFailsRes = (response) => {
    return {
        type: "SET_RESET_PASSWORD_Fails_RES",
        response
    }
}


export const resetPasswordUser = (data) => {
    return dispatch => {
        dispatch(setResetPasswordEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        getUserResetPasswordApi(data).then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setResetPasswordSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setResetPasswordFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}

export const setDeactiveUserSuccessRes = (response) => {
    return {
        type: "SET_DEACTIVE_USER_SUCCESS_RES",
        response
    }
}

export const setDeactiveUserEmptyRes = (response) => {
    return {
        type: "SET_DEACTIVE_USER_Empty_RES",
    }
}

export const setDeactiveUserFailsRes = (response) => {
    return {
        type: "SET_DEACTIVE_USER_Fails_RES",
        response
    }
}


export const ActivateDeactivateUser = (data) => {
    return dispatch => {
        dispatch(setDeactiveUserEmptyRes());
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        ActivateDeactivateUserApi(data).then(response => {
            if (response.status == 200) {
                response.json().then(jsonRes => {
                    dispatch(setDeactiveUserSuccessRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            } else {
                response.json().then(jsonRes => {
                    dispatch(setDeactiveUserFailsRes(jsonRes));
                    setTimeout(() => {
                        dispatch(setAtomBallSpinnerHideSalesDashboard());
                    }, 1000);
                })
            }

        })
    }
}