import appConfig from './apiConfig';
import apiToken from '../api/token';
import endpoints from './endpoints';
import Axios from 'axios';

// let api_token = apiToken();
// const token = api_token.accessToken;

export const createcontactApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token });
    let contactData = JSON.stringify(data);
    const request = new Request(endpoints.create_contact, {
        method: "POST",
        crossDomain: true,
        headers: headers,
        body: contactData
    });
    return fetch(request).then(response => {

        return response.json().then(contactResponse => {
            return contactResponse;
        });
    }).catch(error => {
        return error;
    });
}


export const updateContactApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    let contactData = JSON.stringify(data);
    const request = new Request(endpoints.UPDATE_CONTACT + data.contact_id, {
        method: "PUT",
        crossDomain: true,
        headers: headers,
        body: contactData
    });

    return fetch(request).then(response => {
        return response.json().then(contactResponse => {
            return contactResponse;
        });
    }).catch(error => {
        return error;
    });
}



export const getContactApi = (contact_id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token });
    const request = new Request(endpoints.GET_CONTACT + contact_id, {
        method: 'GET',
        crossDomain: true,
        headers: headers
    });
    return fetch(request).then(response => {
        return response.json().then(contactResponse => {
            return contactResponse
        })
    }).catch(error => {
        return error;
    });

}

export const deleteContactApi = (contact_id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token });
    const request = new Request(endpoints.CONTACT_DELETE + contact_id, {
        method: 'DELETE',
        crossDomain: true,
        headers: headers
    });
    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}