// var api_token = localStorage.getItem("access") !== null ?
//     localStorage.getItem("access") :
//     sessionStorage.getItem("access");
// const token = {
//     accessToken: "Bearer " +JSON.parse(api_token),
//     // "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWFpbCI6ImluZm9AbWFhbnlhaXQuY29tIiwiZXhwIjoxNTg3ODA1NzY3LCJ1c2VybmFtZSI6ImluZm9AbWFhbnlhaXQuY29tIiwib3JpZ19pYXQiOjE1ODY1OTYxNjd9.jeruI_-LqpJYbnQMmaVrd6ZSb_16mJ20Br4FZWnF7Jo"
// };



function Token () {
    var api_token = localStorage.getItem("access") !== null ?
        localStorage.getItem("access") :
        sessionStorage.getItem("access");
    const token = {
        accessToken: "Bearer " + JSON.parse(api_token),
        // "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJlbWFpbCI6ImluZm9AbWFhbnlhaXQuY29tIiwiZXhwIjoxNTg3ODA1NzY3LCJ1c2VybmFtZSI6ImluZm9AbWFhbnlhaXQuY29tIiwib3JpZ19pYXQiOjE1ODY1OTYxNjd9.jeruI_-LqpJYbnQMmaVrd6ZSb_16mJ20Br4FZWnF7Jo"
    };
    return token;
};

export default Token;
