import { combineReducers } from 'redux';
import dashboardStore from './home';
import leadsStore from './leads';
import commonStore from './common';
import contactStore from './contact';
import oppertunityStore from './opportunity';
import userStore from './user';
import stageStore from './stage';
import productStore from './product'
import expenseStore from './expense';
import designationStore from './designation';
import departmentStore from './department';
import loaderStore from './loader';
import reportsStore from './reports';

const rootReducer = combineReducers({
  dashboardStore,
  leadsStore,
  commonStore,
  contactStore,
  oppertunityStore,
  userStore,
  stageStore,
  productStore,
  expenseStore,
  designationStore,
  departmentStore,
  loaderStore,
  reportsStore
});
export default rootReducer;