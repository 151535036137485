const initialState = {
  create_contact_res: {},
  contact: [],
  delete_contact_res: [],
  delete_contact_error:false,
  delete_contact_success: true
}
// leads store reducer
const contactStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_CREATE_CONTACT_RESPONSE':
      return {
        ...state,
        create_contact_success: true,
        create_contact_error: false,
        create_contact_res: action.response,
      }

    case 'SET_CREATE_CONTACT_FAIL_RES':
      return {
        ...state,
        create_contact_success: false,
        create_contact_error: true,
        create_contact_res: action.response,
      }

    case 'SET_CREATE_CONTACT_EMPTY_RES':
      return {
        ...state,
        create_contact_success: false,
        create_contact_error: false,
        create_contact_res: '',
      }

    case 'SET_GET_CONTACT_SUCCESS_RES':
      return {
        ...state,
        get_contact_response: action.response,
        // get_contact_response: null,
        get_contact_success: true,
        get_contact_error: false,
      }

    case 'SET_GET_CONTACT_FAIL_RES':
      return {
        ...state,
        get_contact_response: action.response,
        get_contact_success: false,
        get_contact_error: true,
      }

    case 'SET_GET_CONTACT_EMPTY_RES':
      return {
        ...state,
        get_contact_response: null,
        get_contact_success: false,
        get_contact_error: false,
      }

    case 'SET_UPDATE_CONTACT_SUCCESS_RES':
      return {
        ...state,
        update_contact_response: action.response,
        update_contact_success: true,
        update_contact_error: false,
      }

    case 'SET_UPDATE_COONTACT_FAIL_RES':
      return {
        ...state,
        update_contact_response: action.response,
        update_contact_success: false,
        update_contact_error: true,
      }

    case 'SET_UPDATE_CONTACT_EMPTY_RES':
      return {
        ...state,
        update_contact_response: null,
        update_contact_success: false,
        update_contact_error: false,
      }

    case 'SET_DELETE_CONTACT_SUCCESS_RESPONSE':
      return {
        ...state,
        delete_contact_res: action.response,
        delete_contact_success: true,
        delete_contact_error: false,
      }
    case 'SET_DELETE_CONTACT_ERROR_RESPONSE':
      return {
        ...state,
        delete_contact_res: action.response,
        delete_contact_success: false,
        delete_contact_error: true,
      }
    case 'SET_DELETE_CONTACT_EMPTY_RESPONSE':
      return {
        ...state,
        delete_contact_res: [],
        delete_contact_success: false,
        delete_contact_error: false,
      }
    default:
      return state;
  }
};

export default contactStore;
