import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function BellIcon(props) {
    return (
      <SvgIcon {...props} viewBox ='0 0 20 10'>
       <path d="M58.9863458,71.5 C58.726905,76.2379945 54.8027842,80 50,80 C45.1972158,80 41.273095,76.2379945 41.0136542,71.5 L30.0098932,71.5 C26.6909197,71.5 24,68.8151468 24,65.5 C24,62.189348 26.688664,59.5 30.0020869,59.5 C29.9981947,59.5 30,47.4992494 30,47.4992494 C30,39.0465921 35.2858464,31.6357065 43,28.7587819 L43,28.4952534 C43,24.6330455 46.138457,21.5 50,21.5 C53.8656855,21.5 57,24.6311601 57,28.4952534 L57,28.7589486 C64.7139633,31.6362562 70,39.0483724 70,47.4992494 L70,59.4982567 C73.3063923,59.5 76,62.1883045 76,65.5 C76,68.8115251 73.3069662,71.5 69.9901068,71.5 L58.9863458,71.5 Z M54.9753124,71.5 L45.0246876,71.5 C45.2755498,74.0266613 47.4073259,76 50,76 C52.5926741,76 54.7244502,74.0266613 54.9753124,71.5 Z M45.5542654,32.1248469 C38.7637896,34.083424 34,40.3222636 34,47.4992494 L34,59.4982567 C34,61.7067825 32.2078843,63.5 30.0020869,63.5 C28.8980308,63.5 28,64.3982593 28,65.5 C28,66.6039843 28.8980403,67.5 30.0098932,67.5 L69.9901068,67.5 C71.0993429,67.5 72,66.6008676 72,65.5 C72,64.3992101 71.099016,63.5 69.9979131,63.5 C67.7877428,63.5 66,61.7131252 66,59.4982567 L66,47.4992494 C66,40.3240131 61.2358497,34.083713 54.4456568,32.1249199 L53,31.7078857 L53,28.4952534 C53,26.8412012 51.6574476,25.5 50,25.5 C48.3460522,25.5 47,26.8437313 47,28.4952534 L47,31.7078537 L45.5542654,32.1248469 Z"/>
      </SvgIcon>
    );
}

export default BellIcon;