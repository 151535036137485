import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function FilterIcon(props) {
    return (
        <SvgIcon  {...props} fontSize="large"  viewBox="0 25 160 25" >
            <path d="M91.446,17.504C90.452,15.343,88.348,14,85.958,14H14.042c-2.391,0-4.494,1.343-5.489,3.504  c-0.987,2.143-0.646,4.589,0.891,6.385c0.001,0.001,0.003,0.003,0.004,0.004L38,57.112V83c0,1.106,0.609,2.123,1.585,2.646  C40.027,85.883,40.515,86,41,86c0.582,0,1.162-0.17,1.664-0.504l18-12C61.499,72.939,62,72.003,62,71V57.112l28.556-33.223  C92.092,22.093,92.434,19.647,91.446,17.504z M56.725,54.044C56.258,54.589,56,55.282,56,56v13.395l-12,8V56  c0-0.718-0.258-1.411-0.725-1.956L14.026,20h71.975L56.725,54.044z"/>
        </SvgIcon>
    );
}

export default FilterIcon;
