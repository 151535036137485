const initialState = {
  list_search_opportunity_expense: [],
  expense_type_list: []
}

const expenseStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_OPPORTUNITY_EXPENSE_LIST_RES':
      return {
        ...state,
        list_search_opportunity_expense: action.response,
      }

    case 'SET_CREATE_EXPENSE_RES':
      return {
        ...state,
        post_expense_res: action.response,
      }

    case 'SET_CREATE_EXPENSE_FAILS_RES':
      return {
        ...state,
        post_expense_res: action.response,
      }

    case 'SET_CREATE_EXPENSE_EMPTY_RES':
      return {
        ...state,
        post_expense_res: null,
      }

    case 'SET_EXPENSE_COMMENT_LIST_RES':
      return {
        ...state,
        comments: action.response,
      }

    case 'SET_EXPENSE_COMMENT_LIST_EMPTY_RES':
      return {
        ...state,
        comments: [],
      }

    case 'SET_EXPENSE_STATUS_LIST_RES':
      return {
        ...state,
        expenseStatus: action.response,
      }

    case 'SET_EXPENSE_TYPE_LIST_RES':
      return {
        ...state,
        expense_type_list: action.response,
      }

    case 'SET_EXPENSE_TYPE_LIST_EMPTY_RES':
      return {
        ...state,
        expense_type_list: [],
      }

    default:
      return state;
  }
}

export default expenseStore;