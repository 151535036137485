import React, { Component } from 'react'
import { Dialog, DialogTitle, DialogContent, Typography, DialogActions, Button, IconButton, withStyles } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';

const style = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    headerText: {
        fontSize: 16
    }
});

class Logoutconfirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: props.open,
        }
    }

    handleClose = () => {
        this.setState({ open: false })
        this.props.handleClose();
    }

    logout = () => {
        this.props.handleLogout()
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog maxWidth onClose={this.handleClose} open={this.state.open}>
                    <DialogTitle onClose={this.handleClose}>
                        <span className={classes.headerText}>Logout Confirmation</span>
                        <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent style={{ width: 400, height: 100, paddingTop: 35 }} dividers>
                        <span>Are you sure you want to logout?</span>
                    </DialogContent>
                    <DialogActions style={{display:'flex',justifyContent:'center'}}>
                        <Button variant="contained" onClick={this.logout} color="primary">
                            OK
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            CANCEL
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default withStyles(style)(Logoutconfirmation)
