import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function ContactIcon(props) {
    return (
        <SvgIcon {...props} viewBox="1 0 20 22">
            <path fill="#464646" fill-rule="nonzero" stroke="#464646" stroke-width=".25" d="M1 11c0 5.517 4.483 10 10 10s10-4.483 10-10S16.517 1 11 1 1 5.483 1 11zm3.954 6.943a.915.915 0 0 1 .322-.621 19.319 19.319 0 0 1 3.701-2.368.483.483 0 0 0 .276-.46V11.92a.367.367 0 0 0-.184-.322c-.23-.161-.368-.391-.368-.667V8.287c0-1.103.897-2 2-2h.575c1.103 0 2 .897 2 2v2.644a.771.771 0 0 1-.368.667.396.396 0 0 0-.184.322v2.551c0 .184.115.368.276.46.345.161 2.092 1.035 3.701 2.368.184.16.299.39.322.62-1.586 1.426-3.701 2.276-6.023 2.276a9.168 9.168 0 0 1-6.046-2.252zM11 1.805c5.08 0 9.195 4.138 9.195 9.195a9.283 9.283 0 0 1-2.505 6.322 1.983 1.983 0 0 0-.46-.62c-1.494-1.242-3.127-2.093-3.701-2.392v-2.184c.345-.298.528-.735.528-1.195V8.287a2.777 2.777 0 0 0-2.781-2.781H10.7A2.777 2.777 0 0 0 7.92 8.287v2.644c0 .46.206.897.528 1.195v2.184c-.574.3-2.184 1.15-3.7 2.391a1.38 1.38 0 0 0-.46.62A9.263 9.263 0 0 1 1.804 11 9.193 9.193 0 0 1 11 1.805z" />
        </SvgIcon>
    );
}

export default ContactIcon;