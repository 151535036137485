const initialState = {
    createStageRes: {},
    createStageResSuccess: false,

    getStageRes: {},
    getStageResSuccess: false,

    updateStageRes: {},
    updateStageResSuccess: false,

    deleteStageRes: {},
    deleteStageResSuccess: false,
}

// stage store reducer
const stageStore = (state = initialState, action) => {
    switch (action.type) {

        case "SET_POST_STAGE_SUCCESS_RES":
            return {
                createStageRes: action.response,
                createStageResSuccess: true
            }
        case "SET_POST_STAGE_Empty_RES":
            return {
                createStageRes: {},
                createStageResSuccess: false
            }
        case "SET_POST_STAGE_Fails_RES":
            return {
                createStageRes: action.response,
                createStageResSuccess: false
            }

        //GET STAGE
        case "SET_GET_STAGE_SUCCESS_RES":
            return {
                getStageRes: action.response,
                getStageResSuccess: true
            }
        case "SET_GET_STAGE_EMPTY_RES":
            return {
                getStageRes: {},
                getStageResSuccess: false
            }
        case "SET_GET_STAGE_Fails_RES":
            return {
                getStageRes: action.response,
                getStageResSuccess: false
            }

        //UPDATE STAGE
        case "SET_UPDATE_STAGE_SUCCESS_RES":
            return {
                updateStageRes: action.response,
                updateStageResSuccess: true
            }
        case "SET_UPDATE_STAGE_EMPTY_RES":
            return {
                updateStageRes: {},
                updateStageResSuccess: false
            }
        case "SET_UPDATE_STAGE_Fails_RES":
            return {
                updateStageRes: action.response,
                updateStageResSuccess: false
            }

        //DELETE STAGE
        case "SET_DELETE_STAGE_SUCCESS_RES":
            return {
                deleteStageRes: action.response,
                deleteStageResSuccess: true
            }
        case "SET_DELETE_STAGE_EMPTY_RES":
            return {
                deleteStageRes: {},
                deleteStageResSuccess: false
            }
        case "SET_DELETE_STAGE_Fails_RES":
            return {
                deleteStageRes: action.response,
                deleteStageResSuccess: false
            }

        default:
            return state;
    }
};

export default stageStore;
