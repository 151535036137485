import React from 'react';
import { PropTypes } from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
const styles = {
  circularProgress: {
    position: 'fixed',
    top: 'calc(50% - 30px)',
    left: 'calc(50% - 30px)',
  }
};

function Loading(props) {
  return (
    <div className="loading">
        <div  className="loading-div">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
  // <CircularProgress className={props.classes.circularProgress} size={props.size?props.size:60} color="secondary" />
  );
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default (withStyles(styles)(Loading));
