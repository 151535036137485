const initialState = {
    departmentCreateRes: [],
    departmentCreateResSuccess: false,

    departmentGetAllRes: [],
    departmentGetAllResSuccess: false,

    departmentGetRes: [],
    departmentGetResSuccess: false,

    departmentUpdateRes: [],
    departmentUpdateResSuccess: false,
}

// department store reducer
const departmentStore = (state = initialState, action) => {
    switch (action.type) {

        //Designation Create
        case "SET_CREATE_DEPARTMENT_SUCCESS_RES":
            return {
                departmentCreateRes: action.response,
                departmentCreateResSuccess: true,
            }
        case "SET_CREATE_DEPARTMENT_EMPTY_RES":
            return {
                departmentCreateRes: {},
                departmentCreateResSuccess: false,
            }
        case "SET_CREATE_DEPARTMENT_FAILS_RES":
            return {
                departmentCreateRes: action.response,
                departmentCreateResSuccess: false,
            }
        //Designation Get All
        case "SET_GET_ALL_DESIGNATION_SUCCESS_RES":
            return {
                departmentGetAllRes: action.response,
                departmentGetAllResSuccess: true,
            }
        case "SET_GET_ALL_DESIGNATION_EMPTY_RES":
            return {
                departmentGetAllRes: {},
                departmentGetAllResSuccess: false,
            }
        case "SET_GET_ALL_DESIGNATION_FAILS_RES":
            return {
                departmentGetAllRes: action.response,
                departmentGetAllResSuccess: false,
            }

        //Designation Get
        case "SET_GET_DEPARTMENT_SUCCESS_RES":
            return {
                departmentGetRes: action.response,
                departmentGetResSuccess: true,
            }
        case "SET_GET_DEPARTMENT_EMPTY_RES":
            return {
                departmentGetRes: {},
                departmentGetResSuccess: false,
            }
        case "SET_GET_DEPARTMENT_FAILS_RES":
            return {
                departmentGetRes: action.response,
                departmentGetResSuccess: false,
            }
        
        //Department Get
        case "SET_GET_ALL_DEPARTMENT_SUCCESS_RES":
            return {
                departmentAllGetRes: action.response,
                departmentGetResSuccess: true,
            }
        case "SET_GET_ALL_DEPARTMENT_EMPTY_RES":
            return {
                departmentAllGetRes: [],
                departmentAllGetResSuccess: false,
            }
        case "SET_GET_ALL_DEPARTMENT_FAILS_RES":
            return {
                departmentAllGetRes: action.response,
                departmentAllGetResSuccess: false,
            }

        //Designation Udate
        case "SET_UPDATE_DEPARTMENT_SUCCESS_RES":
            return {
                departmentUpdateRes: action.response,
                departmentUpdateResSuccess: true,
            }
        case "SET_UPDATE_DEPARTMENT_EMPTY_RES":
            return {
                departmentUpdateRes: {},
                departmentUpdateResSuccess: false,
            }
        case "SET_UPDATE_DEPARTMENT_FAILS_RES":
            return {
                departmentUpdateRes: action.response,
                departmentUpdateResSuccess: false,
            }
        default:
            return state;
    }
};

export default departmentStore;
