import React from "react";
import DashboardIcon from './dashboard';
import LeadsIcon from './leads';
import AccountsIcon from './accounts';
import OpportunityIcon from './opportunity';
import ExpensesIcon from './expenses';
import ConfigurationIcon from './configuration';
import ReportsIcon from './reports';
import ArrowBackIcon from './arrowBack';
import FilterIcon from './filterIcon';
import ExportIcon from './export';
import BellIcon from './bell';
import CalendarIcon from './calendar'
import ContactIcon from './contact'
import CreateExpenseIcon from './createexpense'
import CreatePipelineIcon from "./createpipline";
import AddExpenseIcon from './addExpense';
import CsvIcon from "./csv";
import UserIcon from './user';
import FarwardIcon from "./farward";
import PdfIcon from "./pdf";
import ExportCsv from "./exportCSV";
import ExportPdf from './exportPDF';
import ExportPpt from './exportPPT';
import StageIcon from './stage';

const Icon = props => {

    switch (props.name) {
        case 'dashboard':
            return <DashboardIcon {...props} />
        case 'leads':
            return <LeadsIcon {...props} />
        case 'accounts':
            return <AccountsIcon {...props} />
        case 'opportunity':
            return <OpportunityIcon {...props} />
        case 'stage':
                return <StageIcon {...props} />    
        case 'expenses':
            return <ExpensesIcon {...props} />
        case 'add expense':
            return <AddExpenseIcon {...props} />
        case 'configuration':
            return <ConfigurationIcon {...props} />
        case 'reports':
            return <ReportsIcon {...props} />
        case 'arrow_back':
            return <ArrowBackIcon {...props} />
        case 'filter':
            return <FilterIcon {...props} />
        case 'export':
            return <ExportIcon {...props} />
        case 'bell':
            return <BellIcon {...props} />
        case 'calendar':
            return <CalendarIcon {...props}/>
        case 'contact':
            return <ContactIcon {...props}/>
        case 'create_expense':
            return <CreateExpenseIcon {...props} />
        case 'create_pipline':
            return <CreatePipelineIcon {...props}/>
        case 'csv':
            return <CsvIcon {...props}/>
        case 'farword':
            return <FarwardIcon {...props}/>
        case 'pdf':
            return <PdfIcon {...props} />
        case 'user':
            return <UserIcon {...props}/>
        case 'export_csv':
            return <ExportCsv {...props}/>
        case 'export_pdf':
            return <ExportPdf {...props}/>
        case 'export_ppt':
            return <ExportPpt {...props}/>
        case undefined:
            return <img src={require('../../assets/images/stage-icon.svg')} style={{height:35,width:25}}/>
            // return <span style={{ backgroundColor: '#fff', width: '25px', height: '35px' }}></span>

        default:
            return <span />
    }
}

export default Icon;
