import apiToken from "../api/token";
import convertToQueryString from "./covertToQueryString";
import endpoints from "./endpoints";

export const getOpportunityNoActivityApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }
  let url = endpoints.BASE_URL + "/api/opportunity/noactivity/" + data.pipeline;

  if (queryString) {
    url = url + "?" + queryString;
  }

  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getOpportunityNoActivityTotalApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }
  let url =
    endpoints.BASE_URL + "/api/opportunity/noactivitytotal/" + data.pipeline;

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLessActivityApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }
  let url = endpoints.BASE_URL + "/api/opportunity/lazyperson/" + data.pipeline;

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getMostActivityApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.GET_MOST_ACTIVITY +
      data.pipeline_id +
      "?status =" +
      data.status +
      "&fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTargetofpipelineApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.BASE_URL +
      "/UserProfile/targetofpipeline?status =" +
      data.status +
      "&fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getOpportunityExpensesApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }
  let url = endpoints.BASE_URL + "/api/expense/opportunityexpenses";

  if (queryString) {
    url = url + "?" + queryString;
  }

  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectedPaymentsApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.BASE_URL +
      "/api/expense/projected?status =" +
      data.status +
      "&fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getAwaitingPaymentsApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.BASE_URL +
      "/api/expense/awaiting?status =" +
      data.status +
      "&fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExpensebreakdownByTypeApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/expense/expense-by-type";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExpensebreakdownByTypeUserApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/expense/expense-by-type-user";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getperformerlistApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.BASE_URL +
      "/UserProfile/least-performerlist/" +
      data.pipeline_id +
      "?status =" +
      data.status +
      "&fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date +
      "&flag=" +
      data.flag,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getExportedDataApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.GET_EXPORT_DETAILS +
      "?fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLoginUserApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  const request = new Request(
    endpoints.GET_LOGIN_USER +
      "?fromdate=" +
      data.from_date +
      "&todate=" +
      data.to_date,
    {
      method: "GET",
      headers: {
        Authorization: token,
      },
    }
  );

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLostOpportunityApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var url = endpoints.GET_LOST_OPPORTUNITY;
  var queryString = convertToQueryString(data);
  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getWonOpportunityApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var url = endpoints.GET_WON_OPPORTUNITY;
  var queryString = convertToQueryString(data);
  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

// New Report (19/Nov)
export const getHighestSpenderByUserApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/expense/highest-spender-by-user";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getHighestSpenderByPipelineApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/expense/highest-spender-by-pipeline";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getOpportunityByStageApi = (p_id, data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/opportunity/stage-opportunity/" + p_id;

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLostOpportunityVsLessActivitiesApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url =
    endpoints.BASE_URL + "/api/opportunity/lost-opportunity-with-less-activity";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getComingToCloseOpprotunitiesApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url =
    endpoints.BASE_URL + "/api/opportunity/comming-to-close-opportunity";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLostOpportunityReasonApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/opportunity/lost-opportunity-reason";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTopPerformanceByValueApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/UserProfile/top-performerlist/";

  if (queryString) {
    url = url + data.pipeline_id + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTopPerformanceByVolumeApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/opportunity/top-performance-by-volume";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getTotalLeadsApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.GET_TOTAL_LEAD + (data.pipeline ? data.pipeline : 0);

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadByUsersApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/lead/leads-by-user";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadByDatesApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/lead/leads-by-date";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLostOpportunityExpenseApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/expense/lost-opportunity-expenses";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLostOpportunityTimeframeApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/opportunity/lost-opportunity";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getWonOpportunityTimeframeApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.BASE_URL + "/api/opportunity/won-opportunity";

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getLeadsWithNoActivitiesApi = (data) => {
  let api_token = apiToken();
  const token = api_token.accessToken;
  const headers = Object.assign({ "Content-Type": "application/json" });
  var queryString = "";
  if (data) {
    queryString = convertToQueryString(data);
  }

  var url = endpoints.GET_LEADS_WITH_NO_ACTIVITIES;

  if (queryString) {
    url = url + "?" + queryString;
  }
  const request = new Request(url, {
    method: "GET",
    headers: {
      Authorization: token,
    },
  });

  return fetch(request)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
