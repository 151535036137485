import {
  getOpportunityNoActivityApi,
  getOpportunityNoActivityTotalApi,
  getLessActivityApi,
  getProjectedPaymentsApi,
  getTargetofpipelineApi,
  getOpportunityExpensesApi,
  getAwaitingPaymentsApi,
  getMostActivityApi,
  getperformerlistApi,
  getExportedDataApi,
  getLoginUserApi,
  getLostOpportunityApi,
  getWonOpportunityApi,
  getExpensebreakdownByTypeApi,
  getExpensebreakdownByTypeUserApi,
  getHighestSpenderByUserApi,
  getHighestSpenderByPipelineApi,
  getOpportunityByStageApi,
  getLostOpportunityVsLessActivitiesApi,
  getComingToCloseOpprotunitiesApi,
  getLostOpportunityReasonApi,
  getTopPerformanceByValueApi,
  getTotalLeadsApi,
  getLeadByUsersApi,
  getLeadByDatesApi,
  getLostOpportunityExpenseApi,
  getLostOpportunityTimeframeApi,
  getWonOpportunityTimeframeApi,
  getLeadsWithNoActivitiesApi,
  getTopPerformanceByVolumeApi,
} from "../api/reportApi";

export const setAtomBallSpinnerShowSalesDashboard = () => {
  return {
    type: "SET_ATOM_BALL_SPINNER_SHOW_SALES_DASHBOARD",
  };
};

export const setAtomBallSpinnerHideSalesDashboard = () => {
  return {
    type: "SET_ATOM_BALL_SPINNER_HIDE_SALES_DASHBOARD",
  };
};

export const setOpportunityNoActivityRes = (response) => {
  return {
    type: "SET_OPPRS_NO_AVCTIVITY_RES",
    response,
  };
};

export const setLessActivityRes = (response) => {
  return {
    type: "SET_LESS_AVCTIVITY_RES",
    response,
  };
};

export const setOpportunityNoActivityTotalRes = (response) => {
  return {
    type: "SET_OPPRS_NO_TOTAL_AVCTIVITY_RES",
    response,
  };
};

export const setOpportunityExpensesRes = (response) => {
  return {
    type: "SET_OPPORTUNITY_EXPENSES_RES",
    response,
  };
};

export const setTargetofpipelineRes = (response) => {
  return {
    type: "SET_TARGET_OF_PIPELINE_RES",
    response,
  };
};

export const setProjectedPaymentsRes = (response) => {
  return {
    type: "SET_PROJECTED_PAYMENTS_RES",
    response,
  };
};

export const emptyTargetofpipeline = () => {
  return {
    type: "SET_EMPTY_TARGET_OF_PIPELINE_RES",
  };
};

export const setAwaitingPaymentsRes = (response) => {
  return {
    type: "SET_AWAITING_PAYMENT_RES",
    response,
  };
};

export const setPerformerlistRes = (response) => {
  return {
    type: "SET_PERFORMER_LIST_RES",
    response,
  };
};

export const getOpportunityNoActivity = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getOpportunityNoActivityApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setOpportunityNoActivityRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const getOpportunityNoActivityTotal = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getOpportunityNoActivityTotalApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setOpportunityNoActivityTotalRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

//getLessActivity
export const getLessActivity = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLessActivityApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLessActivityRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setGetMostActivityRes = (response) => {
  return {
    type: "SET_MOST_AVCTIVITY_RES",
    response,
  };
};
//getMostActivity
export const getMostActivity = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getMostActivityApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setGetMostActivityRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};
//
export const getTargetofpipeline = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getTargetofpipelineApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setTargetofpipelineRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

//getOpportunityExpenses
export const getOpportunityExpenses = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getOpportunityExpensesApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setOpportunityExpensesRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const getProjectedPayments = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getProjectedPaymentsApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setProjectedPaymentsRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const getAwaitingPayments = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getAwaitingPaymentsApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setAwaitingPaymentsRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const getperformerlist = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getperformerlistApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setPerformerlistRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setGetExportedDataRes = (response) => {
  return {
    type: "SET_EXPORTED_DATA_RES",
    response,
  };
};
//getExportedData
export const getExportedData = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getExportedDataApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setGetExportedDataRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setGetLoginUserRes = (response) => {
  return {
    type: "SET_LOGIN_USER_RES",
    response,
  };
};
//getExportedData
export const getLoginUser = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLoginUserApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setGetLoginUserRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setGetLostOpportunityRes = (response) => {
  return {
    type: "SET_LOST_OPPORTUNITY_RES",
    response,
  };
};
//getExportedData
export const getLostOpportunity = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLostOpportunityApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setGetLostOpportunityRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setGetWonOpportunityRes = (response) => {
  return {
    type: "SET_WON_OPPORTUNITY_RES",
    response,
  };
};
//getExportedData
export const getWonOpportunity = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getWonOpportunityApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((res) => {
          dispatch(setGetWonOpportunityRes(res));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setExpensebreakdownByType = (response) => {
  return {
    type: "SET_EXPENSE_BREAK_BY_TYPE_RES",
    response,
  };
};

export const getExpensebreakdownByType = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getExpensebreakdownByTypeApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setExpensebreakdownByType(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setExpensebreakdownByTypeUser = (response) => {
  return {
    type: "SET_EXPENSE_BREAK_BY_TYPE_USER_RES",
    response,
  };
};

export const getExpensebreakdownByTypeUser = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getExpensebreakdownByTypeUserApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setExpensebreakdownByTypeUser(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

// New Report (19/Nov)

export const setHighestSpenderByUser = (response) => {
  return {
    type: "SET_HIGHEST_SPENDER_USER_RES",
    response,
  };
};

export const getHighestSpenderByUser = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getHighestSpenderByUserApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setHighestSpenderByUser(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setHighestSpenderByPipeline = (response) => {
  return {
    type: "SET_HIGHEST_SPENDER_PIPELINE_RES",
    response,
  };
};

export const getHighestSpenderByPipeline = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getHighestSpenderByPipelineApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setHighestSpenderByPipeline(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setOpportunityByStage = (response) => {
  return {
    type: "SET_OPPORTUNITIES_BY_STAGE",
    response,
  };
};

export const getOpportunityByStage = (p_id, data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getOpportunityByStageApi(p_id, data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setOpportunityByStage(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLostOpportunityVsLessActivities = (response) => {
  return {
    type: "SET_OPPORTUNITIES_BY_VS_LESS_ACTIVITIES",
    response,
  };
};

export const getLostOpportunityVsLessActivities = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLostOpportunityVsLessActivitiesApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLostOpportunityVsLessActivities(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setComingToCloseOpprotunities = (response) => {
  return {
    type: "SET_OPPORTUNITIES_COMING_TO_CLOSE",
    response,
  };
};

export const getComingToCloseOpprotunities = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getComingToCloseOpprotunitiesApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setComingToCloseOpprotunities(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLostOpportunityReason = (response) => {
  return {
    type: "SET_OPPORTUNITIES_REASON",
    response,
  };
};

export const getLostOpportunityReason = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLostOpportunityReasonApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLostOpportunityReason(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setTopPerformanceByValue = (response) => {
  return {
    type: "SET_TOP_PERFORMANCE_BY_VALUE",
    response,
  };
};

export const getTopPerformanceByValue = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getTopPerformanceByValueApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setTopPerformanceByValue(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setTopPerformanceByVolume = (response) => {
  return {
    type: "SET_TOP_PERFORMANCE_BY_VOLUME",
    response,
  };
};

export const getTopPerformanceByVolume = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getTopPerformanceByVolumeApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setTopPerformanceByVolume(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setTotalLeads = (response) => {
  return {
    type: "SET_TOTAL_LEADS",
    response,
  };
};

export const getTotalLeads = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getTotalLeadsApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setTotalLeads(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLeadByUsers = (response) => {
  return {
    type: "SET_LEADS_BY_USERS",
    response,
  };
};

export const getLeadByUsers = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLeadByUsersApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLeadByUsers(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLeadByDates = (response) => {
  return {
    type: "SET_LEADS_BY_DATES",
    response,
  };
};

export const getLeadByDates = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLeadByDatesApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLeadByDates(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLostOpportunityExpense = (response) => {
  return {
    type: "SET_LOST_OPPORTUNITIES_EXPENSE",
    response,
  };
};

export const getLostOpportunityExpense = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLostOpportunityExpenseApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLostOpportunityExpense(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLostOpportunityTimeframe = (response) => {
  return {
    type: "SET_LOST_OPPORTUNITIES_TIMEFRAME",
    response,
  };
};

export const getLostOpportunityTimeframe = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLostOpportunityTimeframeApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLostOpportunityTimeframe(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setWonOpportunityTimeframe = (response) => {
  return {
    type: "SET_WON_OPPORTUNITIES_TIMEFRAME",
    response,
  };
};

export const getWonOpportunityTimeframe = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getWonOpportunityTimeframeApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setWonOpportunityTimeframe(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};

export const setLeadsWithNoActivities = (response) => {
  return {
    type: "SET_LEADS_WITH_NO_ACTIVITIES",
    response,
  };
};

export const getLeadsWithNoActivities = (data) => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getLeadsWithNoActivitiesApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setLeadsWithNoActivities(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
      }
    });
  };
};
