import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function LeadsIcon(props) {
    return (
      <SvgIcon {...props}>
        <g fillRule="nonzero">
            <path d="M14.368 8.842a3.32 3.32 0 0 1-3.315-3.316 3.32 3.32 0 0 1 3.315-3.315 3.32 3.32 0 0 1 3.316 3.315 3.32 3.32 0 0 1-3.316 3.316zm0-5.638a2.33 2.33 0 0 0-2.333 2.322 2.33 2.33 0 0 0 2.333 2.323 2.33 2.33 0 0 0 2.334-2.323 2.33 2.33 0 0 0-2.334-2.322zM2.411 17.684c-.789 0-1.366-.233-1.765-.713C.211 16.448 0 15.632 0 14.478c0-3.207 1.392-6.074 3.633-7.484.292-.184.613-.273.979-.273.336 0 .664.076.98.148.308.07.598.137.884.137.255 0 .532-.084.824-.173.323-.1.658-.201 1.01-.201.27 0 .52.062.746.186 1.184.652 2.181 1.73 2.885 3.12l.217.427-.386.26c-1.764 1.183-2.923 3.53-3.024 6.126l-.022.577-.536-.084a8.509 8.509 0 0 0-1.33-.11c-.864 0-1.705.154-2.519.304-.694.126-1.349.246-1.93.246zm2.2-9.923a.87.87 0 0 0-.484.13C2.212 9.094.975 11.68.975 14.478c0 .876.135 1.482.4 1.803.21.251.529.363 1.036.363.498 0 1.113-.113 1.765-.232.814-.15 1.737-.318 2.684-.318.31 0 .624.017.951.052.234-2.52 1.366-4.788 3.053-6.119-.589-1.009-1.362-1.795-2.255-2.286-.25-.137-.623-.037-1.041.09-.328.1-.7.214-1.092.214-.39 0-.76-.085-1.088-.16-.28-.065-.544-.125-.777-.125zM6.632 6.632a3.32 3.32 0 0 1-3.316-3.316A3.32 3.32 0 0 1 6.632 0a3.32 3.32 0 0 1 3.315 3.316 3.32 3.32 0 0 1-3.315 3.316zm0-5.638a2.33 2.33 0 0 0-2.334 2.322 2.33 2.33 0 0 0 2.334 2.323 2.33 2.33 0 0 0 2.333-2.323A2.33 2.33 0 0 0 6.632.994z"/>
            <path d="M10.191 19.895c-.62 0-1.162-.13-1.6-.516-.583-.512-.854-1.368-.854-2.69 0-.145.003-.288.008-.428l.995.045a9.057 9.057 0 0 0-.008.382c0 .967.169 1.605.5 1.897.505.445 1.555.256 2.768.037.832-.15 1.774-.318 2.742-.318 1.008 0 1.906.179 2.699.337.93.186 1.733.346 2.1.046.257-.209.409-.714.45-1.502.008-.154.014-.318.014-.497 0-2.787-1.26-5.369-3.21-6.577a4.25 4.25 0 0 0-.271-.16c-.256-.138-.569-.055-1.06.092-.334.1-.714.213-1.115.213-.397 0-.776-.085-1.11-.16-.582-.13-.955-.2-1.288.005l-.124.079-.527-.881.135-.087c.695-.428 1.395-.27 2.012-.132.314.07.61.137.902.137.261 0 .543-.084.842-.173.548-.165 1.17-.35 1.793-.015.116.064.223.125.33.195C19.584 10.63 21 13.493 21 16.688c0 .197-.006.38-.015.553-.06 1.133-.324 1.852-.831 2.265-.724.59-1.733.39-2.9.156-.75-.15-1.599-.32-2.512-.32-.883 0-1.742.155-2.574.305-.715.128-1.381.248-1.977.248z"/>
        </g>
      </SvgIcon>
    );
}

export default LeadsIcon;
