import Loadable from "react-loadable";
// import Loading from '../components/loding-component/loadingComponent';
import Loading from "../components/Loading";

//Gmail Auth
export const GmailAuth = Loadable({
  loader: () => import("../containers/login/GmailAuthentication"),
  loading: Loading,
});

// OnBoarding
export const OnboardProfile = Loadable({
  loader: () => import("./onBoarding/profile"),
  loading: Loading,
});

export const OnboardDesignationMaster = Loadable({
  loader: () => import("./onBoarding/designationMaster"),
  loading: Loading,
});

export const AddDesignatiion = Loadable({
  loader: () => import("./onBoarding/AddDesignation"),
  loading: Loading,
});

//
export const DepartmentMaster = Loadable({
  loader: () => import("./onBoarding/departmentMaster"),
  loading: Loading,
});

export const AddDepartment = Loadable({
  loader: () => import("./onBoarding/AddDepartment"),
  loading: Loading,
});

export const OnboardProduct = Loadable({
  loader: () => import("./onBoarding/Product"),
  loading: Loading,
});

export const AddProduct = Loadable({
  loader: () => import("./onBoarding/addProduct"),
  loading: Loading,
});

export const AddExpense = Loadable({
  loader: () => import("./onBoarding/AddExpenseType"),
  loading: Loading,
});

export const ExpenseType = Loadable({
  loader: () => import("./onBoarding/ExpenseType"),
  loading: Loading,
});

export const UserProfile = Loadable({
  loader: () => import("./onBoarding/UserProfile"),
  loading: Loading,
});

// Dashboard
export const PipelineDashboard = Loadable({
  loader: () => import("./Dashboard/pipelineDashboardPage"),
  loading: Loading,
});

export const ProfileContainer = Loadable({
  loader: () => import("./profile/profile"),
  loading: Loading,
});

export const SalesDashboard = Loadable({
  loader: () => import("./Dashboard/salesDashboard"),
  loading: Loading,
});

export const ActiviesDashboard = Loadable({
  loader: () => import("./Dashboard/activityDashboard"),
  loading: Loading,
});

export const FinanceDashboard = Loadable({
  loader: () => import("./Dashboard/financeDashboard"),
  loading: Loading,
});

export const ExpenseDashboard = Loadable({
  loader: () => import("./Dashboard/expenseDashboard"),
  loading: Loading,
});

export const MyActiviesDashboard = Loadable({
  loader: () => import("./Dashboard/myactivityDashboard"),
  loading: Loading,
});

//Accounts
export const CreateContact = Loadable({
  loader: () => import("./Accounts/createContactPage"),
  loading: Loading,
});

//Expenses

export const CreateExpense = Loadable({
  loader: () => import("./Expense/createExpensePage"),
  loading: Loading,
});

export const CreateExpenseForm = Loadable({
  // loader: () => import('./Expense/createExpenseHead'),
  loader: () => import("./Expense/createExpenseForm"),
  loading: Loading,
});

export const CreateExpenseHead = Loadable({
  loader: () => import("./Expense/createExpenseHead"),
  loading: Loading,
});

export const ExpenseList = Loadable({
  loader: () => import("./Expense/ExpenseList"),
  loading: Loading,
});

export const OpportunityExpenseList = Loadable({
  loader: () => import("./Expense/opportunityExpenseList"),
  loading: Loading,
});

export const ExpenseApproval = Loadable({
  loader: () => import("./Expense/ExpenseApproval"),
  loading: Loading,
});

export const FinanceApproval = Loadable({
  loader: () => import("./Expense/FinanceApproval"),
  loading: Loading,
});

//Leads
export const CreateLeads = Loadable({
  loader: () => import("./leads/createLeadsPage"),
  loading: Loading,
});

export const AllLeads = Loadable({
  loader: () => import("./leads/allLeadsPage"),
  loading: Loading,
});

//Opportunity
export const CreateOpportunity = Loadable({
  loader: () => import("./opportunities/createOpportunityPage"),
  loading: Loading,
});

export const ListOpportunity = Loadable({
  loader: () => import("./opportunities/listOpportunity"),
  loading: Loading,
});

export const OpportunityDetails = Loadable({
  loader: () => import("./opportunities/opportunities-details"),
  loading: Loading,
});

export const ActivityOverview = Loadable({
  loader: () => import("./opportunities/activity-overview/ActivityOverview"),
  loading: Loading,
});

//contacts
export const AllContact = Loadable({
  loader: () => import("./Accounts/allContactPage"),
  loading: Loading,
});

export const Login = Loadable({
  loader: () => import("../containers/login/login"),
  loading: Loading,
});

export const ForgetPassword = Loadable({
  loader: () => import("../containers/forgetPassword/forgetPassword"),
  loading: Loading,
});

export const SetNewPassword = Loadable({
  loader: () => import("../containers/ResetPassword/ResetPassword"),
  loading: Loading,
});

export const SignUp = Loadable({
  loader: () => import("../containers/signup/signup"),
  loading: Loading,
});
//Organization
export const OrganizationAll = Loadable({
  loader: () => import("./Accounts/organizationAll"),
  loading: Loading,
});
export const OrganizationEditContainer = Loadable({
  loader: () => import("../containers/organization/organizationCreate"),
  loading: Loading,
});

export const OrganizationDetails = Loadable({
  loader: () => import("../pages/Accounts/organization/organizationDetails"),
  loading: Loading,
});

// Users
export const UserAll = Loadable({
  loader: () => import("../pages/User/userAll"),
  loading: Loading,
});

//stage

export const StageAll = Loadable({
  loader: () => import("../pages/stage/stagesAll"),
  loading: Loading,
});

export const StageCreate = Loadable({
  loader: () => import("../pages/stage/StageCreate"),
  loading: Loading,
});

//Product
export const ProductAll = Loadable({
  loader: () => import("../pages/product/ProductAll"),
  loading: Loading,
});

export const ProductCreate = Loadable({
  loader: () => import("../pages/product/ProductCreate"),
  loading: Loading,
});
//Configuration
export const PipelineTarget = Loadable({
  loader: () => import("../pages/Configuration/pipelineTarget"),
  loading: Loading,
});

export const AllTeam = Loadable({
  loader: () => import("../pages/Configuration/allTeam"),
  loading: Loading,
});

export const ViewPipeline = Loadable({
  loader: () => import("../pages/Configuration/viewPipeline"),
  loading: Loading,
});
//Department
export const DepartmentCreate = Loadable({
  loader: () => import("../pages/department/DepartmentCreate"),
  loading: Loading,
});

export const DepartmentAll = Loadable({
  loader: () => import("../pages/department/DepartmentAll"),
  loading: Loading,
});
//Designation
export const DesignationCreate = Loadable({
  loader: () => import("../pages/designation/DesignationCreate"),
  loading: Loading,
});

export const DesignationAll = Loadable({
  loader: () => import("../pages/designation/DesignationAll"),
  loading: Loading,
});

export const PipelineManagementReports = Loadable({
  loader: () => import("../containers/reports/reports"),
  loading: Loading,
});

export const FinanceStaticReports = Loadable({
  loader: () => import("../containers/reports/finence-statistic"),
  loading: Loading,
});

export const SalesManagementReports = Loadable({
  loader: () => import("../containers/reports/sales-management"),
  loading: Loading,
});

export const SalesLeadManagementReports = Loadable({
  loader: () => import("../containers/reports/sales-lead-management"),
  loading: Loading,
});

export const TeamPerformanceReports = Loadable({
  loader: () => import("../containers/reports/team-performence"),
  loading: Loading,
});

// currency
export const CurrencyAll = Loadable({
  loader: () => import("../pages/designation/DesignationAll"),
  loading: Loading,
});

// reports
export const WonOpportunityList = Loadable({
  loader: () => import("../pages/report/WonOpportunityList"),
  loading: Loading,
});

export const LostOpportunityList = Loadable({
  loader: () => import("../pages/report/LostOpportunityList "),
  loading: Loading,
});

export const NoActivityReportPage = Loadable({
  loader: () => import("../pages/report/noactivity"),
  loading: Loading,
});

export const LessActivity = Loadable({
  loader: () => import("../pages/report/lessactivity"),
  loading: Loading,
});

export const PipelineVsCompany = Loadable({
  loader: () => import("../pages/report/pipelinevscompany"),
  loading: Loading,
});

export const OpportunityExpenses = Loadable({
  loader: () => import("../pages/report/opportunityexpenses"),
  loading: Loading,
});

export const ProjectedPayments = Loadable({
  loader: () => import("../pages/report/projected"),
  loading: Loading,
});

export const AwaitingPayments = Loadable({
  loader: () => import("../pages/report/awaiting"),
  loading: Loading,
});

export const BeforeMeeting = Loadable({
  loader: () => import("../pages/report/beforemeeting"),
  loading: Loading,
});

export const AllocatedPipelines = Loadable({
  loader: () => import("../pages/report/allocatedPipelines"),
  loading: Loading,
});

export const LeastPerformerPage = Loadable({
  loader: () => import("../pages/report/leastperformer"),
  loading: Loading,
});

export const TopPerformerPage = Loadable({
  loader: () => import("../pages/report/topperformer"),
  loading: Loading,
});

export const MostActivity = Loadable({
  loader: () => import("../pages/report/MostActivity"),
  loading: Loading,
});

export const ExportedData = Loadable({
  loader: () => import("../pages/report/ExportedData"),
  loading: Loading,
});

export const LoginUserContainer = Loadable({
  loader: () => import("../pages/report/LoginUserContainer"),
  loading: Loading,
});

export const ClosingOpportunities = Loadable({
  loader: () => import("../pages/report/closing_opp_page"),
  loading: Loading,
});

export const ExpenseBreakdownByTypePage = Loadable({
  loader: () => import("../pages/report/expense_breakdown_type_page"),
  loading: Loading,
});

export const ExpenseBreakdownByTypeUserPage = Loadable({
  loader: () => import("../pages/report/expense_breakdown_type_user_page"),
  loading: Loading,
});

//HighestSpenderByPipelinePage
export const HighestSpenderByPipelinePage = Loadable({
  loader: () => import("../pages/report/highest-spender-pipeline"),
  loading: Loading,
});

//HighestSpenderByPipelinePage
export const HighestSpenderByUserPage = Loadable({
  loader: () => import("../pages/report/highest-spender-user"),
  loading: Loading,
});

//LostOpportunitiesVsExpensePage
export const LostOpportunitiesVsExpensePage = Loadable({
  loader: () => import("../pages/report/lost-opportunities-vs-expense"),
  loading: Loading,
});

//LostOpportunitiesWithReasonWhyPage
export const LostOpportunitiesWithReasonWhyPage = Loadable({
  loader: () => import("../pages/report/lost-opp-with-reason-why"),
  loading: Loading,
});

//TotalLeadsPage
export const TotalLeadsPage = Loadable({
  loader: () => import("../pages/report/total-leads"),
  loading: Loading,
});

//LeadsByUserPage
export const LeadsByUserPage = Loadable({
  loader: () => import("../pages/report/leads-by-user"),
  loading: Loading,
});

//WonOpportunitiesTimeframePage
export const WonOpportunitiesTimeframePage = Loadable({
  loader: () => import("../pages/report/won-opp-timeframe"),
  loading: Loading,
});

//LostOpportunitiesTimeframePage
export const LostOpportunitiesTimeframePage = Loadable({
  loader: () => import("../pages/report/lost-opp-timeframe"),
  loading: Loading,
});

//LeadsByDatePage
export const LeadsByDatePage = Loadable({
  loader: () => import("../pages/report/leads-by-date"),
  loading: Loading,
});

//OpportunitiesByStagePage
export const OpportunitiesByStagePage = Loadable({
  loader: () => import("../pages/report/opp-by-stage"),
  loading: Loading,
});

//LeadsWithoutActivities
export const LeadsWithoutActivities = Loadable({
  loader: () => import("../pages/report/LeadsWithoutActivitiesPage"),
  loading: Loading,
});

// TopPerformerByVolumPage
export const TopPerformerByVolumPage = Loadable({
  loader: () => import("../pages/report/ topPerformerByVolum"),
  loading: Loading,
});

// new pages added

export const ViewContact = Loadable({
  loader: () => import("../pages/new-pages/ViewContact"),
  loading: Loading,
});

export const ViewOrganizations = Loadable({
  loader: () => import("../pages/new-pages/ViewOrganization"),
  loading: Loading,
});

export const ExpenseView = Loadable({
  loader: () => import("../pages/new-pages/ExpenseView"),
  loading: Loading,
});

export const LeadsView = Loadable({
  loader: () => import("../pages/new-pages/LeadsView"),
  loading: Loading,
});

export const ExpenseSalesApprovalView = Loadable({
  loader: () => import("../pages/new-pages/ExpenseSalesApprovalView"),
  loading: Loading,
});

export const ActivityList = Loadable({
  loader: () => import("../pages/new-pages/activityList"),
  loading: Loading,
});

export const ReportViewAll = Loadable({
  loader: () => import("../pages/new-pages/ReportViewAll"),
  loading: Loading,
});
