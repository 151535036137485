import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
// import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';

class SuccessSnackbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vertical: "top",
            horizontal: "right",
            open: false,
            message: props.message
        }
    }

    componentDidMount() {
        this.setState({ open: this.props.open });
    }

    handleClose = () => {
        this.setState({ open: false, message: '' });
    }

    render() {
        const vertical = this.state.vertical;
        const horizontal = this.state.horizontal;
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    key={`${vertical},${horizontal}`}
                    open={this.state.open}
                    onClose={this.handleClose}
                    message={this.state.message}
                    action={
                        <React.Fragment>
                            <Button color="secondary" size="small" onClick={this.handleClose}>
                                OK
                            </Button>
                        </React.Fragment>
                    }
                />
            </div>
        )
    }
}

export default SuccessSnackbar;