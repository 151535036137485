import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import '@firebase/messaging';
// const firebase = require('firebase/app');


// importScripts('https://www.gstatic.com/firebasejs/7.15.3/firebase-app.js');

// importScripts('https://www.gstatic.com/firebasejs/7.15.3/firebase-messaging.js');

var config = {
    apiKey: "AIzaSyB-JAlpa6ERiE3pYCPc8Mbgew6u3MfC304",
    authDomain: "lucrumcrm-6c2c8.firebaseapp.com",
    projectId: "lucrumcrm-6c2c8",
    storageBucket: "lucrumcrm-6c2c8.appspot.com",
    messagingSenderId: "389793636013",
    appId: "1:389793636013:web:1d8cd043ec4feb9c5fb024",
    measurementId: "G-XTX40RFMXH"
};

const firebase = initializeApp(config);
export const messaging = getMessaging();

// next block of code goes here
export function setTokenFound(token) {
    localStorage.setItem('FCM_token', token);
}

export const getFCMToken = async () => {
    let Token = '';
    // try {
    //     currenToken = await messaging.getToken().then((currenToken) => {
    //         if (currenToken) {
    //             // setTokenFound(currenToken);
    //             localStorage.setItem('FCM_token', currenToken);
    //         } else {
    //             // setTokenFound(currenToken);
    //         }
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // } catch (err) {
    //     console.log('An error occuring while retriving Token ' + err)
    // }
    getToken(messaging, { vapidKey: 'BLsl9AeBBuC-VuzCmY9W1LJyElcX5L0Y8gqdHZ-x9T9osC76hi8Y6Fg2Md5O0T-QxUFMoLTA_8kQMkDY9C-k9_A' }).then((currentToken) => {
        if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log('currentToken' + currentToken);
            Token = currentToken
            localStorage.setItem('FCM_token', currentToken);
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });
    return Token
}



// export const requestFirebaseNotificationPermission = () =>
//     new Promise((resolve, reject) => {
//         messaging
//             .requestPermission()
//             .then(() => messaging.getToken())
//             .then((firebaseToken) => {
//                 resolve(firebaseToken);
//             })
//             .catch((err) => {
//                 reject(err);
//             });
//     });

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            resolve(payload)
            // ...
        });
    });

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    
    // ...
});