import base_uri from "./baseUrls";

//const BASE_URI = 'http://maanyait.elvis.do.viewyoursite.net';
// const BASE_URI = 'https://elvis2-backend.do.viewyoursite.net';
const BASE_URI = base_uri.BASE_URL;
// alert("BASE_URI : ", BASE_URI);
const endpoints = {
  //Base url
  BASE_URL: BASE_URI,
  // logiN
  USER_API_KEY: BASE_URI + "/api/lead/user-uid",
  AUTH_TOKEN: BASE_URI + "/api/token/",
  AUTH_TOKEN_VERIFY: BASE_URI + "/api/token/verify/",
  GET_PROFILE: BASE_URI + "/api/me/",
  GET_ALL_USER: BASE_URI + "/api/user/all",
  GET_USER_PROFILE: BASE_URI + "/api/user/profile",
  USER_PROIFILE_PICTURE: BASE_URI + "/api/user/profile-picture",
  FORGET_PASSWORD: BASE_URI + "/account/api/password/reset/?email=",
  FORGET_PASSWORD_Unauth: BASE_URI + "/account/password-reset/",
  GMAIL_AUTHENTICATION: BASE_URI + "/api/gmail/auth",
  GMAIL_AUTHENTICATION_DETAILS: BASE_URI + "/api/gmail/auth-details",
  //signup
  account_signup: BASE_URI + "/account/sign-up/",
  // Notification
  GET_NOTIFICATION: BASE_URI + "/api/notifications/get-notification",
  UPDATE_NOTIFICATION: BASE_URI + "/api/notifications/read/",
  // user
  GET_USER_DESIGNATIONS: BASE_URI + "/api/user/designations",
  GET_DEPARTMENT: BASE_URI + "/api/user/departments",
  GET_USER_ROLE: BASE_URI + "/api/get_all_roles/",
  UPDATE_USER: BASE_URI + "/api/user/update/",
  USER_ACTIVATE: BASE_URI + "/api/user/user-active-deactive",
  // leads
  lead_delete: BASE_URI + "/api/lead/delete/",
  all_leads: BASE_URI + "/api/lead/",
  create_lead: BASE_URI + "/api/lead/",
  update_lead: BASE_URI + "/api/lead/update/",
  GET_LEAD: BASE_URI + "/api/lead/get/",
  LEAD_ACTIVITY_CREATE: BASE_URI + "/api/lead/activity/create",
  // pipeline
  GET_PIPELINE: BASE_URI + "/api/pipeline/",
  pipline_stage_get: BASE_URI + "/api/pipeline/stage/",
  // contact
  all_contact: BASE_URI + "/api/contact/all",
  create_contact: BASE_URI + "/api/contact/",
  contact_filter: BASE_URI + "/api/contact/filter/",
  GET_CONTACT: BASE_URI + "/api/contact/get/",
  CONTACT_DELETE: BASE_URI + "/api/contact/delete/",
  UPDATE_CONTACT: BASE_URI + "/api/contact/updates/",
  // opportunity
  create_opportunity: BASE_URI + "/api/opportunity/",
  list_opportunity: BASE_URI + "/api/opportunity/",
  GET_OPPORTUNITY: BASE_URI + "/api/opportunity/activity/get/o/",
  GET_LEAD_ACTIVITIES: BASE_URI + "/api/lead/activity/get/o/",
  CREATE_LEAD_NOTES: BASE_URI + "/api/lead/note/create",
  GET_LEAD_NOTES: BASE_URI + "/api/lead/note/get/o/",
  GET_LEAD_FILES: BASE_URI + "/api/lead/document/get/",
  LEAD_File_CREATE: BASE_URI + "/api/lead/document/create",
  GET_LEAD: BASE_URI + "/api/lead/get/",
  ALLOCATE_LEAD: BASE_URI + "/api/lead/allocation/",
  GET_TOTAL_LEAD: BASE_URI + "/api/lead/v2/",
  GET_LEADS_WITH_NO_ACTIVITIES: BASE_URI + "/api/lead/lead-with-no-activtiy",

  UPDATE_OPPORTUNITY: BASE_URI + "/api/opportunity/arrange",
  CREATE_NOTES: BASE_URI + "/api/opportunity/note/create",
  OPPORTUNITY_NOTES_GET: BASE_URI + "/api/opportunity/note/get/o/",
  OPPORTUNITY_ACTIVITY_GET: BASE_URI + "/api/opportunity/activity/get/o/",
  OPPORTUNITY_ACTIVITY_CREATE: BASE_URI + "/api/opportunity/activity/create",
  OPPORTUNITY_ACTIVITY_FILTER: BASE_URI + "/api/contact/filter/",
  OPPORTUNITY_V1_ALL: BASE_URI + "opportunity/v1/all/0/",
  OPPORTUNITY_MARK_AS_COMPLETED:
    BASE_URI + "/api/opportunity/activity/complete",
  // organization
  ORGANIZATION_ALL: BASE_URI + "/api/contact/organization/all/",
  GET_COUNTRY_LIST: BASE_URI + "/core/country",
  ORGANIZATION_CREATE: BASE_URI + "/api/contact/organization/create/",
  ORGANIZATION_SEARCH_API: BASE_URI + "/api/contact/organization/create/",
  GET_ORGANIZATION: BASE_URI + "/api/contact/organization/get/",
  GET_ORGANIZATION_DETAILS: BASE_URI + "/api/contact/get-organisation-details/",
  UPDATE_ORGANIZATION: BASE_URI + "/api/contact/organization/update/",
  GET_ORGANIZATION_CONTACTS: BASE_URI + "/api/contact/organisation-contacts/",
  GET_CONTACT_DETAILS: BASE_URI + "/api/contact/get-contact-details/",
  //core
  CURRENCY_GET: BASE_URI + "/core/currency",
  GET_ALL_STAGE: BASE_URI + "/api/pipeline/stage/arrange",
  DELETE_STAGE: BASE_URI + "/api/pipeline/stage/delete/",
  //product
  GET_ALL_PRODUCT: BASE_URI + "/api/company/product/all",
  CREATE_PRODUCT: BASE_URI + "/api/company/product/create",
  DELETE_PRODUCT: BASE_URI + "/api/company/product/delete/",
  UPDATE_PRODUCT: BASE_URI + "/api/company/product/update/",
  GET_PRODUCT: BASE_URI + "/api/company/product/get/",
  // Stage
  CREATE_STAGE: BASE_URI + "/api/pipeline/stage/create",
  GET_STAGE: BASE_URI + "/api/pipeline/get-stage/",
  UPDATE_STAGE: BASE_URI + "/api/pipeline/stage/update/",
  PIPELINE_UPDATE: BASE_URI + "/api/pipeline/update/",
  PIPELINE_DELETE: BASE_URI + "/api/pipeline/delete/",
  PIPELINE_CREATE: BASE_URI + "/api/pipeline/create",
  //Files
  File_CREATE: BASE_URI + "/api/opportunity/document/create",
  EXPENSE_HEAD_CREATE: BASE_URI + "/api/expense/head/create",
  EXPENSE_STATUS_GET: BASE_URI + "/api/expense/status",
  GET_EXPENSES: BASE_URI + "/api/expense/expenses",
  // Designation
  CREATE_USER_DESIGNAIION: BASE_URI + "/api/user/designations/create",
  GET_USER_DESIGNATIONS: BASE_URI + "/api/user/designations",
  UPDATE_USER_DESIGNATION_: BASE_URI + "/api/user/designations/update/",
  GET_DESINATION_: BASE_URI + "/api/user/designations/get/",
  DELETE_DESINATION_: BASE_URI + "/api/user/designations/delete/",
  // Department
  CREATE_USER_DEPARTMENT: BASE_URI + "/api/user/departments/create",
  GET_USER_DEPARTMENTS: BASE_URI + "/api/user/departments",
  UPDATE_USER_DEPARTMENT_: BASE_URI + "/api/user/departments/update/",
  GET_DEPARTMENT_: BASE_URI + "/api/user/departments/get/",
  DELETE_DEPARTMENT_: BASE_URI + "/api/user/departments/delete/",
  // deshboard
  GET_ACTIVITY_REPORT: BASE_URI + "/api/opportunity/dashboard/activity-report",

  //expense
  CREATE_EXPENSE_HEAD: BASE_URI + "/api/expense/head/create",
  CREATE_EXPENSE: BASE_URI + "/api/expense/create",
  UPDATE_EXPENSE: BASE_URI + "/api/expense/update/",
  SEARCH_OPPORTUNITY: BASE_URI + "/api/opportunity/expense/",
  EXPENSE_LIST_BY_HEADID: BASE_URI + "/api/expense/list/",
  GET_TYPES: BASE_URI + "/api/expense/types",
  DELETE_EXPENSE: BASE_URI + "/api/expense/delete/",
  EXPENSE_HEAD_LIST: BASE_URI + "/api/expense/heads/",
  OPPORTUNITY_EXPENSE_LIST: BASE_URI + "/api/opportunity/opportunity-expenses/",
  EXPENSE_APPROVAL_LIST: BASE_URI + "/api/expense/expense-status-detail",
  EXPENSE_FINANCE_APPROVAL_LIST:
    BASE_URI + "/api/expense/expense-status-financedetail",
  EXPENSE_APPROVAL_SALES: BASE_URI + "/api/expense/heads/approval",
  EXPENSE_COMMENT_CREATE: BASE_URI + "/api/expense/communication/create/",
  EXPENSE_COMMENT_GET: BASE_URI + "/api/expense/communication/get/",

  //Activity
  CALENDER_VIEW: BASE_URI + "/api/opportunity/calender/",
  EXPENSE_APPROVAL: BASE_URI + "/api/expense/approve/",
  EXPENSE_APPROVE_REJECT: BASE_URI + "/api/expense/approve-reject",
  EXPENSE_STATUS: BASE_URI + "/api/expense/status",
  Expense_csv_upload: BASE_URI + "/api/expense/filter-csv-export",
  // currency
  GET_ALL_CURRENCY: BASE_URI + "api/core/currency",

  //configratution
  ALL_TEAM: BASE_URI + "/api/user/all/team",
  EXPORT_PIPELINE_DASHBOARD: BASE_URI + "/api/opportunity/ppt/finance/",
  GET_USER_TARGET: BASE_URI + "/api/user/target/",
  SET_USER_TARGET: BASE_URI + "/api/user/target/set/",
  ADD_USER_TARGET: BASE_URI + "/api/user/target/set",
  COMPANY_INFO: BASE_URI + "/api/company/info",
  // Report
  GET_WON_OPPORTUNITY: BASE_URI + "/api/opportunity/won-opportunity",
  GET_LOST_OPPORTUNITY: BASE_URI + "/api/opportunity/lost-opportunity",
  EXPORT_EXPENSE_DASHBOARD: BASE_URI + "/api/expense/report/Expense/",
  GET_MOST_ACTIVITY: BASE_URI + "/api/opportunity/pipeline/activity/",
  GET_EXPORT_DETAILS: BASE_URI + "/UserProfile/export",
  GET_LOGIN_USER: BASE_URI + "/UserProfile/info",
  // GET_LOST_OPPORTUNITY: BASE_URI + '/UserProfile/info',
  //Report export
  NO_ACTIVITY_EXPORT: BASE_URI + "/api/opportunity/ppt/finance/",
  //ExpenseType
  EXPENSE_TYPE_GET: BASE_URI + "/api/expense/types",
  EXPENSE_TYPE_CREATE: BASE_URI + "/api/expense/types",
  EXPENSE_TYPE_UPDATE: BASE_URI + "/api/expense/types",
  EXPENSE_TYPE_LOCK: BASE_URI + "/api/expense/types/lock",
  EXPENSE_TYPE_ACTIVE: BASE_URI + "/api/expense/types/lock",
};

export default endpoints;
