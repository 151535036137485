import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Popper, Paper, Box } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PeopleOutline from "@material-ui/icons/PeopleOutline";
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import LocationOnOutlined from "@material-ui/icons/LocationOnOutlined";
import AccountBalanceWalletOutlinedIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import Popover from "@material-ui/core/Popover";

import Icons from "../../components/icons";

const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 10,
    right: 10,
  },
  chip: {
    float: "right",
    position: "relative",
    marginBottom: 10,
    fontSize: 16,
    borderRadius: 15,
    padding: "10px 10px 10px 50px",
    borderRadius: "30px",
    background: "#fff",
    width: "fit-content",
    transition: "width 2s",
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
      letterSpacing:".8px !important"
    },
  },
});

// const actions = [
//     // { icon: <FileCopyIcon />, name: "Add Opportunity" },
//     { name: "Add Activity" },
//     { name: "Add Notes" },
//     { name: "Add File" },
// ];

class FloatingButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
      anchorEl: "",
      open: false,
      close: true,
      placement: "top-end",
      actions: props.actionMenu,
    };
  }

  // componentWillReceiveProps(nextProps) {
  //     if (this.props.opportunity_id !== nextProps.opportunity_id) {
  //         this.setState({ opportunity_id: nextProps.opportunity_id });
  //     }
  // }
  handleClose = (e) => {
    this.setState({
      anchorEl: "",
      open: false,
    });
  };

  handleChip = (name, e) => {
    let activityDialog = false;
    let notesDialog = false;
    let filesDialog = false;
    this.setState({ open: false, flag: false });
    switch (name) {
      case "Add Expense":
        return this.props.history.push({
          pathname: "/expense/create/head",
        });
      case "Add Contact":
        return this.props.history.push({
          pathname: "/contact/o/create",
        });

      case "Add Lead":
        return this.props.history.push({
          pathname: "/lead/create",
        });

      case "Add Pipeline":
        return this.props.openCreatePipeline();

      case "Add Opportunity":
        return this.props.history.push({
          pathname: "/opportunity/create",
        });

      case "Add Activity":
        activityDialog = true;
        notesDialog = false;
        return this.props.onItemClick(activityDialog, notesDialog);

      case "Add Notes":
        activityDialog = false;
        notesDialog = true;
        return this.props.onItemClick(activityDialog, notesDialog);

      case "Add Files":
        filesDialog = true;
        return this.props.ItemFileClick(filesDialog);
        // this.props.history.push({
        //     pathname: "/opportunity/create"
        // })

      case "Add Organization":
        return this.props.history.push({
          pathname: "/organization/create",
        });

      case "Add User":
        return this.props.userBoxOpen();

      case "Add Stage":
        return this.props.history.push({
          pathname: "/stage/create",
        });

      case "Add Product":
        return this.props.history.push({
          pathname: "/product/create",
        });

      case "Add Job Title":
        return this.props.history.push({
          pathname: "/job-title/create",
        });

      case "Add Department":
        return this.props.history.push({
          pathname: "/department/create",
        });

      case "Assign Pipeline":
        return this.props.openAssignPipeline();

      default:
        return this.props.history.push({
          pathname: "/",
        });
    }
  };

  render() {
    const { classes } = this.props;
    const actions = this.state.actions;
    return (
      <div className={classes.root}>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {actions &&
            actions.map((item, i) => (
              <Box
                key={i}
                className="flotingbtn_list"
                display="flex"
                justifyContent="flex-end"
              >
                <Paper
                  className={classes.chip}
                  onClick={this.handleChip.bind(this, item.name)}
                >
                  {item.name + " "}
                  <span>
                    {item.name.toLowerCase() == "add lead" ? (
                      <PeopleOutline />
                    ) : item.name.toLowerCase() == "add organization" ? (
                      <AccountCircleOutlined />
                    ) : item.name.toLowerCase() == "add contact" ? (
                      <AccountCircleOutlined />
                    ) : item.name.toLowerCase() == "add opportunity" ? (
                      <Icons name={"opportunity"} htmlColor="#000000" />
                    ) : item.name.toLowerCase() == "add expense" ? (
                      <AccountBalanceWalletOutlinedIcon />
                    ) : item.name.toLowerCase() == "add user" ? (
                      <AccountCircleOutlined />
                    ) : item.name.toLowerCase() == "add opportunity" ? (
                      <Icons name={"opportunity"} htmlColor="#000000" />
                    ) : undefined}
                  </span>
                </Paper>
              </Box>
            ))}
        </Popover>
        <Fab
          color="default"
          aria-label="add"
          onClick={(event) => {
            this.setState({
              flag: !this.state.flag,
              anchorEl: event.currentTarget,
              open: !this.state.open,
            });
          }}
        >
          {this.state.flag ? <CloseIcon/> : <AddIcon/>}
        </Fab>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(FloatingButton));
