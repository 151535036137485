import {
	getOpportunityV1AllApi, getLeadsV1Api, getExpenseV1Api, getPipelineDashboardSalesTargetApi, getPipelineDashboardSalesLostApi,
	getSalesTargetApi, getSalesLostApi, getSalesStageListApi, getSalesDashboardPipelineStagesItemListApi,
	getdashboardActivitiesDataApi, getdashboardFinanceReportApi, getExpenseDashboardApi, getdashboardActivitiesDataBYFilterApi, getNotificationsApi
} from '../api/homeApi';

export const setAtomBallSpinnerShowSalesDashboard = () => {
	return {
		type: "SET_ATOM_BALL_SPINNER_SHOW_SALES_DASHBOARD",
	}
}

export const setAtomBallSpinnerHideSalesDashboard = () => {
	return {
		type: "SET_ATOM_BALL_SPINNER_HIDE_SALES_DASHBOARD",
	}
}

export const setOpportunityV1AllRes = (response) => {
	return {
		type: "SET_DASHBOARD_OPPORTUNITY_V1_ALL_RESPONSE",
		response
	}
}

//setLeadsV1Res
export const setLeadsV1Res = (response) => {
	return {
		type: "SET_LEADS_RESPONSE",
		response
	}
}

export const setExpenseV1Res = (response) => {
	return {
		type: "SET_EXPENSE_RESPONSE",
		response
	}
}

//setExpenseDashbordRes
export const setExpenseDashbordRes = (response) => {
	return {
		type: "SET_EXPENSE_DASHBOARD_RESPONSE",
		response
	}
}

export const setPipelineDashboardSalesTargetResponse = (response) => {
	return {
		type: "SET_PIPELINE_DASHBOARD_SALES_TARGET_RESPONSE",
		response
	}
}

export const setPipelineDashboardSalesLostResponse = (response) => {
	return {
		type: "SET_PIPELINE_DASHBOARD_SALES_LOST_RESPONSE",
		response
	}
}

export const setSalesTargetResponse = (response) => {
	return {
		type: "SET_SALES_TARGET_RESPONSE",
		response
	}
}

export const setSalesLostResponse = (response) => {
	return {
		type: "SET_SALES_LOST_RESPONSE",
		response
	}
}

export const setSalesStageList = (response) => {
	return {
		type: "SET_SALES_STAGE_LIST_RESPONSE",
		response
	}
}

export const setSalesDashboardPipelineStagesItemList = (response) => {
	return {
		type: "SET_SALES_STAGE_ITEMS_LIST_RESPONSE",
		response
	}
}

export const setdashboardActivitiesData = (response) => {
	return {
		type: "SET_DASHBOARD_ACTIVITIES_RESPONSE",
		response
	}
}

export const setdashboardActivitiesDataEmpty = () => {
	return {
		type: "SET_DASHBOARD_ACTIVITIES_RESPONSE_EMPTY",
	}
}

export const setdashboardFinanceReportData = (response) => {
	return {
		type: "SET_DASHBOARD_FINANCE_REPORT_RESPONSE",
		response
	}
}

export const emptySalesResponse = (response) => {
	return {
		type: "SET_SALES_EMPTY_RESPONSE",
		response
	}
}

export const emptyDashboardPipelineRes = (response) => {
	return {
	type: "SET_DASHBOARD_PIPELINE_EMPTY_RESPONSE",
	response
	}
}

export const emptyDashboardResponse = (response) => {
	return {
		type: "SET_DASHBOARD_EMPTY_RESPONSE",
		response
	}
}

export const getOpportunityV1All = ( data) => {
	return dispatch => {     
		dispatch(setAtomBallSpinnerShowSalesDashboard());
        getOpportunityV1AllApi( data ).then((result) => {
			if(result.status === 200){
				return result.json().then(result => {
					dispatch(setOpportunityV1AllRes(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}
		});
	}
}

export const getLeadsV1 = ( data) => {
	return dispatch => {   
		dispatch(setAtomBallSpinnerShowSalesDashboard());   
        getLeadsV1Api( data).then((result) => {
			if(result.status === 200){
				return result.json().then(result => {
					dispatch(setLeadsV1Res(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);

				});
			} else {
				//error handler
			}
		});
	}
}

//getExpenseV1
export const getExpenseV1 = ( data) => {
	return dispatch => {     
		dispatch(setAtomBallSpinnerShowSalesDashboard()); 
        getExpenseV1Api( data).then((result) => {
			if(result.status === 200){
				return result.json().then(result => {
					dispatch(setExpenseV1Res(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);

				});
			} else {
				//error handler
			}
		});
	}
}

export const getExpenseDashboard = ( data) => {
	return dispatch => {     
		dispatch(setAtomBallSpinnerShowSalesDashboard()); 
        getExpenseDashboardApi( data).then((result) => {
			if(result.status === 200){
				return result.json().then(result => {
					dispatch(setExpenseDashbordRes(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);

				});
			} else {
				//error handler
			}
		});
	}
}

export const getPipelineDashboardSalesTarget = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getPipelineDashboardSalesTargetApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setPipelineDashboardSalesTargetResponse(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}

		});
	}
}

export const getPipelineDashboardSalesLost = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getPipelineDashboardSalesLostApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setPipelineDashboardSalesLostResponse(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}

		});
	}
}

export const getSalesTarget = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getSalesTargetApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setSalesTargetResponse(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}

		});
	}
}

//getSalesLost
export const getSalesLost = (data) => {
	return dispatch => {
		getSalesLostApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setSalesLostResponse(result));
				});
			} else {
				//error handler
			}

		});
	}
}

export const getSalesStageList = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard);
		getSalesStageListApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setSalesStageList(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}

		});
	}
}

export const getSalesDashboardPipelineStagesItemList = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getSalesDashboardPipelineStagesItemListApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setSalesDashboardPipelineStagesItemList(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 2000);
				});
			} else {
				//error handler
			}

		});
	}
}

export const getdashboardActivitiesData = () => {
	return dispatch => {
		dispatch(setdashboardActivitiesDataEmpty())
		getdashboardActivitiesDataApi().then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setdashboardActivitiesData(result));
				});
			} else {
				//error handler
			}

		});
	}
}


export const getdashboardActivitiesFilterData = (params) => {
	return dispatch => {
		dispatch(setdashboardActivitiesDataEmpty())
		getdashboardActivitiesDataBYFilterApi(params).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
				
					dispatch(setdashboardActivitiesData(result));
				});
			} else {
				//error handler
			}

		});
	}
}

export const getdashboardFinanceReport = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getdashboardFinanceReportApi(data).then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setdashboardFinanceReportData(result));
					setTimeout(() => {
						dispatch(setAtomBallSpinnerHideSalesDashboard());
					}, 1000);
				});
			} else {
				//error handler
			}

		});
	}
}

export const setNotificationsData = (response) => {
	return {
		type: "SET_NOTIFICATIONS_RESPONSE",
		response
	}
}

export const getNotifications = () => {
	return dispatch => {
		// dispatch(setAtomBallSpinnerShowSalesDashboard());
		getNotificationsApi().then((result) => {
			if (result.status === 200) {
				return result.json().then(result => {
					dispatch(setNotificationsData(result));
				});
			} else {
				//error handler
			}

		});
	}
}
