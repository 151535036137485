// import appConfig from './apiConfig';
import apiToken from '../api/token';
import convertToQueryString from './covertToQueryString';
import endpoints from './endpoints';

export const getOpportunityV1AllApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.BASE_URL + '/api/opportunity/v1/all/' + data.pipeline + '?user=' + data.user + '&fromdate=' + data.from_date + '&todate=' + data.to_date, {
        method: "GET",
        headers: {
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

//getLeadsV1Api

export const getLeadsV1Api = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.BASE_URL + '/api/lead/v1/' + data.pipeline + '?user=' + data.user + '&fromdate=' + data.from_date + '&todate=' + data.to_date, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

//getExpenseV1Api
export const getExpenseV1Api = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.BASE_URL + '/api/expense/v1/' + data.pipeline + '?user=' + data.user + '&fromdate=' + data.from_date + '&todate=' + data.to_date, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

//getExpenseDashboard
export const getExpenseDashboardApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.BASE_URL + '/api/expense/v1/0' + '?user=' + data.user + '&fromdate=' + data.from_date + '&todate=' + data.to_date, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getPipelineDashboardSalesTargetApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    var url = '/api/opportunity/target/v1/' + data.pipeline;
    var query_string = convertToQueryString(data);
    if (query_string !== '') {
        url = url + '?' + query_string;
    }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getPipelineDashboardSalesLostApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    var url = '/api/opportunity/lostbusiness/pipeline/' + data.pipeline;
    var query_string = convertToQueryString(data);
    if (query_string !== '') {
        url = url + '?' + query_string;
    }
    // let end_url = "";
    // if (data.by_user) {
    //     end_url = data.pipeline_id + '?user=' + data.user + '&fromdate=' + data.from_date + '&todate=' + data.to_date;
    // } else {
    //     end_url = data.pipeline_id
    // }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getSalesTargetApi = (parmsData) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let url = '/api/opportunity/target/v1/' + parmsData.pipeline_id + '?user=' + (parmsData.user ? parmsData.user : 0) + '&from_date=' + (parmsData.fromdate ? parmsData.fromdate : '') + '&to_date=' + (parmsData.todate ? parmsData.todate : '');

    // var query_string = convertToQueryString(parmsData);
    // if (query_string) {
    //     url = url + '?' + query_string;
    // }
    // if (parmsData.by_date) {
    //     url = '/api/opportunity/target/v1/' + parmsData.pipeline_id + '?fromdate=' + parmsData.fromdate + '&todate=' + parmsData.todate;
    // } else {
    //     url = '/api/opportunity/target/v1/' + parmsData.pipeline_id;
    // }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getSalesLostApi = (parmsData) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });

    let url = '/api/opportunity/lostbusiness/pipeline/' + parmsData.pipeline_id + '?user=' + (parmsData.user ? parmsData.user : 0) + '&from_date=' + (parmsData.fromdate ? parmsData.fromdate : '') + '&to_date=' + (parmsData.todate ? parmsData.todate : '');
    // var query_string = convertToQueryString(parmsData);
    // if (query_string) {
    //     url = url + '?' + query_string;
    // }

    // if (parmsData.by_date) {
    //     url = '/api/opportunity/lostbusiness/pipeline/' + parmsData.pipeline_id + '?fromdate=' + parmsData.fromdate + '&todate=' + parmsData.todate;
    // } else {
    //     url = '/api/opportunity/lostbusiness/pipeline/' + parmsData.pipeline_id;
    // }

    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

// getSalesStageListApi

export const getSalesStageListApi = (parmsData) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let url = '/api/pipeline/stage/' + parmsData.pipeline_id;

    var query_string = convertToQueryString(parmsData);
    if (query_string) {
        url = url + '?' + query_string;
    }
    // if (parmsData.by_date) {
    //     url = '/api/pipeline/stage/' + parmsData.pipeline_id + '?fromdate=' + parmsData.fromdate + '&todate=' + parmsData.todate;
    // } else {
    //     url = '/api/pipeline/stage/' + parmsData.pipeline_id;
    // }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getSalesDashboardPipelineStagesItemListApi = (parmsData) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let url = '/api/opportunity/dashboard/pipeline/' + parmsData.pipeline_id;
    var query_string = convertToQueryString(parmsData);
    if (query_string) {
        url = url + '?' + query_string;
    }
    // if (parmsData.fromdate) {
    //     url = '/api/opportunity/dashboard/pipeline/' + parmsData.pipeline_id + '?fromdate=' + parmsData.fromdate + '&todate=' + parmsData.todate;
    // }
    // if (parmsData.user) {
    //     url = '/api/opportunity/dashboard/pipeline/' + parmsData.pipeline_id + '?fromdate=' + parmsData.fromdate + '&todate=' + parmsData.todate;
    // } else {
    //     url = '/api/opportunity/dashboard/pipeline/' + parmsData.pipeline_id;
    // }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getdashboardActivitiesDataApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_ACTIVITY_REPORT, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}


export const getdashboardActivitiesDataBYFilterApi = (params) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    let url = '';
    if (params.from !== "" && params.to !== "") {
        url = endpoints.GET_ACTIVITY_REPORT + "?pipeline=" + params.pipeline + "&fromdate=" + params.from + "&todate=" + params.to + "&user=" + params.user;
    } else {
        url = endpoints.GET_ACTIVITY_REPORT + "?pipeline=" + params.pipeline + "&user=" + params.user;
    }
    const request = new Request(url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}

export const getdashboardFinanceReportApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    
    let date = new Date()
    var year = date.getFullYear();

    

    if (data.from_date !== '' && data.from_date !== undefined) {
        let date = new Date(data.from_date)
        var year = date.getFullYear();
    } else if (data.to_date !== '' && data.to_date !== undefined) {
        let date = new Date(data.to_date)
        var year = date.getFullYear();
    }
    
    var url = '/api/opportunity/report/finance/v1/' + year + '/' + data.pipeline;
    var query_string = convertToQueryString(data);
    if (query_string !== '') {
        url = url + '?' + query_string;
    }
    const request = new Request(endpoints.BASE_URL + url, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response
    }).catch(error => {
        return error;
    });
}


export const getNotificationsApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    // const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_NOTIFICATION,
        {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            }
        }
    );

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}