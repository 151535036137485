import React, { Component } from 'react';
import { Typography, Button, ListItemText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PageHeader from '../../components/page-header/PageHeader';
import Filter from '../../components/popups/filter-popups';
import { Link } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import FloatingButton from '../../components/bottomedit/FloatingButton';
import SpinnerAtomBall from '../../components/atom-ball-spinner';
import { setSingleOpportunityEmpty, setPipelineStageListEmpty } from '../../actions/opportunityAction';
import currencyFormate from '../../api/currencyFormate';
import ActionsPopup from '../../components/popups/actionsPopup';
import Snackbar from '../../components/snackbar/success-snackbar';
import OkIcon from "../../assets/images/checkok.png";
import CancelIcon from "../../assets/images/cancelicon.png";
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import axios from 'axios';
import apiToken from '../../api/token';
import endpoints from '../../api/endpoints';
import ReactDOMServer from 'react-dom/server';
import '../../styles/css/jquery.dataTables.min.css';
import '../../styles/css/datatable.css';
import '../../styles/css/material.min.css';
import '../../styles/css/dataTables.material.min.css';
const $ = require('jquery');

$.DataTable = require('datatables.net');

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
    },
    popover: {
        width: '500px',
    },
    field: {
        width: "100%"
    },
    button: {
        margin: theme.spacing(0),
        backgroundColor: "#282828",
        width: "100%",
        color: "white",
        '&:hover': {
            backgroundColor: '#282828',
        }
    },
    'top:before': {
        content: '',
        borderBottom: '20px solid #CC0000',
        borderLeft: '20px solid transparent',
        borderRight: '20px solid transparent'
    },
    tbl: {
        boxShadow: " 0 0 10px 0 rgba(0, 0, 0, 0.08)",
        paddingTop: "3px",
      }
  }
);

class ExpenseTypeList extends Component {
  

    constructor(props) {
      super(props);
      this.state = {
        loading:false,
        listItem: ['Edit opportunity'],
        actionMenu: [{ name: "Add Opportunity" }],
        country_list: [],
        pipeline_list: [],
        list_all_owner_users: [],
        pipeline_stage_list: [],
        viewModel: false,
        selectedDateFrom: '',
        rowData: '',
        actionsPopup: false,
        snackbarOpen: false,
        snackbarMessage: '',
        leadRemoveDialog: false,
        selected_lead_title: '',
        openOpportunityDialog: false,
        from_date: "",
        to_date: "",    
        filter: {
            user: 0,
            from: '',
            to: '',
            pipeline: 0,
            status: 'Active',
            stage: ''
        },
        exportModel: false,
        selected_pipeline_id: "",
        pipeline_name: "ALL"
    }
    }

    componentDidMount = () => {
        this.loadData();
    }

  
    handleActive=(_e, _rowdata)=>{
     
        let api_token = apiToken();
       const self=this;
          this.setState({ loading: true });
          const token = api_token.accessToken;
          let url = endpoints.EXPENSE_TYPE_ACTIVE;
          const data = { type_id: _rowdata.type_id, is_active:!_rowdata.is_active };
          axios.put(
            url,
            data,
            {
              crossDomain: true,
              headers: {
                Authorization: token
              }
            },
          ).then(response => {
            
             this.setState({ loading: false });
             window.location.reload();
          }).catch(error => {
            this.setState({ loading: false });
          });
        
    }

    handleLocked=(_e, _rowdata)=>{
          let api_token = apiToken();
          const self=this;
          this.setState({ loading: true });
          const token = api_token.accessToken;
          let url = endpoints.EXPENSE_TYPE_ACTIVE;
          const data = { type_id: _rowdata.type_id, is_locked:true };
          axios.post(
            url,
            data,
            {
              crossDomain: true,
              headers: {
                Authorization: token
              }
            },
          ).then(response => {
            
             this.setState({ loading: false });
             window.location.reload();
          }).catch(error => {
            this.setState({ loading: false });
          });
    }

    loadData = () => {
        let api_token = apiToken();
        const token = api_token.accessToken;
        const progress_string = ReactDOMServer.renderToString(<SpinnerAtomBall show={true} />);
        const self = this;
        this.$el = $(this.el);
        var url = endpoints.EXPENSE_TYPE_GET+"?type=all";
        this.opportunity_datatable = this.$el.DataTable({
            ajax: {
                url: url,
                type: 'GET',
                crossDomain: true,
                headers: {
                    Authorization: token,
                },
            },
            searching: true,
            serverSide: true,
            processing: true,
            retrieve: true,
            language: {
                processing: progress_string
            },
            responsive: true,
            columns: [
                { title: '#ID', data: 'type_id', visible: false },
                { title: 'Expense Type', data: 'type_value', visible: true },
                //, row.type_id
                {
                    title: 'Is Active',
                    data: 'is_active',
                    render(_data, _type, row, _meta) {
                        return ReactDOMServer.renderToStaticMarkup(
                            <button style={{ height: 15, fontSize:12 }} className="is_active" data-json={JSON.stringify(row)}>
                            <img alt='isactive' src={row.is_active?OkIcon:CancelIcon} height={20}  />
                            </button>
                            
                            
                        );
                    }
                },
                {
                    title: 'Is Locked',
                    data: 'is_locked',
                    render(_data, _type, row, _meta) {
                        return ReactDOMServer.renderToStaticMarkup(
                            <button style={{ height: 15, fontSize:12 }} className="is_locked" data-json={JSON.stringify(row)}>
                          <img alt='islock' style={{textAlign:'center'}} src={row.is_locked?OkIcon:CancelIcon} height={20}  />
                            
                          </button>
                            
                        );
                    }
                },
                {
                    title: 'Actions', data: '',
                    render(_data, _type, row, _meta) {
                        if(!row.is_locked)
                        {
                      return ReactDOMServer.renderToStaticMarkup(
                        <div>
                          <button style={{ height: 15, fontSize:12 }} className="actions" data-json={JSON.stringify(row)}>
                          {'Edit'}
                          </button>
                        </div>
                      );
                        }
                            return '';
                        
                    }
                  }
            ],
            order: [[0, 'desc']],

        });

        $('table').on('click', '.actions', function (event) {
            var rowData = JSON.parse(this.dataset.json);
            self.handleActions(event, rowData);
          });

          $('table').on('click', '.is_active', function (event) {
            var rowData = JSON.parse(this.dataset.json);
            self.handleActive(event, rowData);
          });

          $('table').on('click', '.is_locked', function (event) {
            var rowData = JSON.parse(this.dataset.json);
            self.handleLocked(event, rowData);
          });
  }

  handleActions = (_e, rowData) => {
    this.props.history.push({
      pathname: "/expensetype/update",
      state: { row: rowData },
    });
  };
  onCreateClick = () => {
    this.props.history.push({
      pathname: "/expensetype/create",
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={2}>
          <PageHeader headerText={"Expense Type"} is_filter={false} />
          <div
            style={{
              marginLeft: "0.8rem",
              width: "100%",
              textAlign: "end",
              marginTop: "-10px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={this.onCreateClick}
            >
              Add New Expense Type
            </Button>
          </div>
        </Grid>

        <div className="userlistTable">
          <Grid item xs={12}>
            <table
              id="order_tbl"
              className={classes.tbl + " mdl-data-table"}
              ref={(el) => (this.el = el)}
            />
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pipeline_list: "",
  };
};

export default withRouter(
  withStyles(styles)(connect(mapStateToProps)(ExpenseTypeList))
);
