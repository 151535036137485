import {
	getAllOwnerUsersApi,
	getPipelineStageListApi,
	createOpportunityApi,
	getSingleOpportunityApi,
	updateOpportunityApi,
} from '../api/opportunityApi';
import { setAtomBallSpinnerShowSalesDashboard, setAtomBallSpinnerHideSalesDashboard } from './reportAction';


export const setAllOwnerUsersList = (response) => {
	return {
		type: "SET_OWNER_USER_LIST_RESPONSE",
		response
	}
}

export const setPipelineStageList = (response) => {
	return {
		type: "SET_PIPELINE_STAGE_LIST_RESPONSE",
		response
	}
}

export const setPipelineStageListEmpty = () => {
	return {
		type: "SET_PIPELINE_STAGE_LIST_EMPTY",
	}
}

export const setcreateOpportunitySuccessRes = (response) => {
	return {
		type: 'SET_CREATE_OPPORTUNITY_SUCCESS_RES',
		response
	}
}

export const setCreateOpportunityFailRes = (response) => {
	return {
		type: 'SET_CREATE_OPPORTUNITY_FAIL_RES',
		response
	}
}

export const setEmptyCreateOpportunityResponse = () => {
	return {
		type: 'SET_CREATE_OPPORTUNITY_EMPTY_RES'
	}
}

export const setSingleOpportunitySuccessRes = (response) => {
	return {
		type: 'SET_SINGLE_OPPORTUNITY_SUCCESS_RES',
		response
	}
}

export const setSingleOpportunityFailRes = () => {
	return {
		type: 'SET_SINGLE_OPPORTUNITY_FAIL_RES',
	}
}

export const setSingleOpportunityEmpty = () => {
	return {
		type: 'SET_SINGLE_OPPORTUNITY_EMPTY_RES'
	}
}

export const setUpdateOpportunitySuccessRes = (response) => {
	return {
		type: 'SET_UPDATE_OPPORTUNITY_SUCCESS_RES',
		response
	}
}

export const setUpdateOpportunityFailRes = (response) => {
	return {
		type: 'SET_UPDATE_OPPORTUNITY_FAIL_RES',
		response
	}
}

export const setEmptyUpdateOpportunityRes = () => {
	return {
		type: 'SET_UPDATE_OPPORTUNITY_EMPTY_RES',
	}
}

export const getAllOwnerUsers = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getAllOwnerUsersApi(data).then((result) => {
			dispatch(setAllOwnerUsersList(result));
			setTimeout(() => {
				dispatch(setAtomBallSpinnerHideSalesDashboard());
			}, 1000);
		});
	}
}

export const getPipelineStageList = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		dispatch(setPipelineStageListEmpty());
		getPipelineStageListApi(data).then((result) => {
			dispatch(setPipelineStageList(result));
			setTimeout(() => {
				dispatch(setAtomBallSpinnerHideSalesDashboard());
			}, 1000);
		});
	}
}

export const createOpportunity = (data) => {

	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		createOpportunityApi(data).then((res) => {
			if (res.status == 201 || res.status == 200) {
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000);
				return res.json().then(jsonRes => {
					dispatch(setcreateOpportunitySuccessRes(jsonRes));
				})
			} else {
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000);
				return res.json().then(jsonRes => {
					dispatch(setCreateOpportunityFailRes(jsonRes));
				})
			}
		});
	}
}

export const getSingleOpportunity = (id) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		getSingleOpportunityApi(id).then((result) => {
			if (result) {
				dispatch(setSingleOpportunitySuccessRes(result));
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000);
			} else {
				dispatch(setSingleOpportunityFailRes(result));
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000);
			}
		});
	}
}

export const updateOpportunity = (data) => {
	return dispatch => {
		dispatch(setAtomBallSpinnerShowSalesDashboard());
		dispatch(setEmptyUpdateOpportunityRes());
		updateOpportunityApi(data).then((res) => {
			if (res.status == 200 || res.status == 201) {
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000)
				return res.json().then(jsonRes => {
					dispatch(setUpdateOpportunitySuccessRes(jsonRes));
				})
			} else {
				setTimeout(() => {
					dispatch(setAtomBallSpinnerHideSalesDashboard());
				}, 1000);
				return res.json().then(jsonRes => {
					dispatch(setUpdateOpportunityFailRes(jsonRes));
				})
			}
		});
	}
}