import React from "react";
import { Switch, Route, NavLink, Redirect } from "react-router-dom";
import {
  CreateExpense,
  CreateExpenseForm,
  PipelineDashboard,
  CreateContact,
  CreateLeads,
  CreateOpportunity,
  AllLeads,
  SalesDashboard,
  AllContact,
  ListOpportunity,
  // CreateExpenseHead,
  ExpenseList,
  ExpenseApproval,
  FinanceApproval,
  OnboardProfile,
  OnboardDesignationMaster,
  OnboardProduct,
  AddProduct,
  AddExpense,
  ExpenseType,
  Login,
  ForgetPassword,
  SignUp,
  AddDesignatiion,
  OpportunityDetails,
  DepartmentMaster,
  AddDepartment,
  OrganizationAll,
  OrganizationEditContainer,
  ActiviesDashboard,
  ProfileContainer,
  OrganizationDetails,
  FinanceDashboard,
  ActivityOverview,
  UserAll,
  StageAll,
  StageCreate,
  PipelineTarget,
  ProductAll,
  ProductCreate,
  DepartmentCreate,
  DepartmentAll,
  DesignationAll,
  DesignationCreate,
  PipelineManagementReports,
  MyActiviesDashboard,
  ExpenseDashboard,
  AllTeam,
  ViewPipeline,
  CurrencyAll,
  WonOpportunityList,
  LostOpportunityList,
  NoActivityReportPage,
  LessActivity,
  MostActivity,
  PipelineVsCompany,
  OpportunityExpenses,
  ProjectedPayments,
  AwaitingPayments,
  BeforeMeeting,
  AllocatedPipelines,
  LeastPerformerPage,
  TopPerformerPage,
  ExportedData,
  LoginUserContainer,
  SetNewPassword,
  ViewContact,
  ViewOrganizations,
  OpportunityExpenseList,
  ExpenseView,
  LeadsView,
  ExpenseSalesApprovalView,
  ActivityList,
  ReportViewAll,
  ClosingOpportunities,
  ExpenseBreakdownByTypePage,
  SalesLeadManagementReports,
  SalesManagementReports,
  TeamPerformanceReports,
  FinanceStaticReports,
  ExpenseBreakdownByTypeUserPage,
  HighestSpenderByPipelinePage,
  HighestSpenderByUserPage,
  LostOpportunitiesVsExpensePage,
  LostOpportunitiesWithReasonWhyPage,
  TotalLeadsPage,
  LeadsByUserPage,
  WonOpportunitiesTimeframePage,
  LostOpportunitiesTimeframePage,
  LeadsByDatePage,
  OpportunitiesByStagePage,
  LeadsWithoutActivities,
  TopPerformerByVolumPage,
  GmailAuth,
} from "../pages/pageListAsync";
import Export from "../pages/Dashboard/export";
import AppDragDropDemo from "../components/dashboard-widgets/checkDragDrop/AppDragDropDemo";
import SideNav from "../containers/nav";
import ResetPassword from "../components/dialogbox/ResetPassword";
import tokenVerify from "../api/auth_service";
import expenseTypeList from "../pages/ExpenseType/expenseTypeList";
import CreateExpenseType from "../pages/ExpenseType/createExpenseType";
import updateExpenseType from "../pages/ExpenseType/updateExpenseType";

// class Application extends React.Component {
export default function Application() {
  // render() {
  window.addEventListener("beforeunload", (ev) => {
    ev.preventDefault();
    var data = localStorage.getItem("elivs_access");
    if (data !== null) {
      var keep_me_signed_in = JSON.parse(data).keep_me_signed_in;
      if (!keep_me_signed_in) {
        localStorage.clear();
        sessionStorage.clear();
      }
    }
  });

  var token = localStorage.getItem("access");
  var session_token = sessionStorage.getItem("access");
  if (token || session_token) {
    tokenVerify(token !== null ? token : session_token);
    return (
      // <Router>
      <Switch>
        <Route exact path="/gmail-auth" component={GmailAuth} />
        <SideNav>
          {/* Dashboard */}
          {/* <Route render={() => <Redirect to={{pathname:"/home"}} />} /> */}
          <Route exact path="/" component={PipelineDashboard} />
          <Route exact path="/home" component={PipelineDashboard} />
          <Route exact path="/user/profile" component={ProfileContainer} />
          <Route exact path="/sales/dashboard" component={SalesDashboard} />
          <Route
            exact
            path="/report/dashboard/activitydashboard"
            component={ActiviesDashboard}
          />
          <Route
            exact
            path="/report/finance/view/dashboard/report"
            component={FinanceDashboard}
          />
          <Route
            exact
            path="/report/expense/dashboard"
            component={ExpenseDashboard}
          />
          <Route
            exact
            path="/report/mycalendar"
            component={MyActiviesDashboard}
          />
          {/* Dashboard End */}
          <Route exact path="/lead/create" component={CreateLeads} />
          <Route exact path="/lead/edit" component={CreateLeads} />
          <Route exact path="/lead/all" component={AllLeads} />
          <Route exact path="/lead/:id/details" component={LeadsView} />
          {/* OPPORTUNITY */}
          <Route
            exact
            path="/opportunity/create"
            component={CreateOpportunity}
          />
          <Route
            exact
            path="/opportunity/:id/details"
            component={CreateOpportunity}
          />
          <Route
            exact
            path="/opportunity/:id/activities"
            component={OpportunityDetails}
          />
          {/* OPPORTUNITY */}
          <Route exact path="/contact/o/create" component={CreateContact} />
          <Route exact path="/contact/edit/:id" component={CreateContact} />
          <Route excate path="/contact/all" component={AllContact} />
          <Route
            excate
            path="/contact/:id/overview"
            component={ActivityOverview}
          />
          {/* Default */}
          {/* <Route component={NotFound} /> */}
          <Route exact path="/opportunity/list" component={ListOpportunity} />
          {/* Expense */}
          {/* <Route exact path='/expense/create/head' component={CreateExpenseHead} /> */}
          <Route exact path="/expense/all" component={ExpenseList} />
          <Route exact path="/expense/approval" component={ExpenseApproval} />
          <Route exact path="/expense/finance" component={FinanceApproval} />
          <Route exact path="/expense/create" component={CreateExpense} />
          <Route
            exact
            path="/expense/update/:id"
            component={CreateExpenseForm}
          />
          <Route
            exact
            path="/expense/opportunity-expenses/:id"
            component={OpportunityExpenseList}
          />

          {/* Organization */}
          <Route exact path="/organization/all" component={OrganizationAll} />
          <Route
            exact
            path="/organization/create"
            component={OrganizationEditContainer}
          />
          <Route
            exact
            path="/organization/edit/:id"
            component={OrganizationEditContainer}
          />
          <Route
            exact
            path="/organization/detail/:id"
            component={OrganizationDetails}
          />
          <Route exact path="/appDragDropDemo" component={AppDragDropDemo} />
          {/* Users */}
          <Route exact path="/user/all" component={UserAll} />
          {/* Stage */}
          <Route exact path="/stage/create" component={StageCreate} />
          <Route exact path="/stage/:id/edit" component={StageCreate} />
          <Route exact path="/stage/all" component={StageAll} />
          {/*  */}
          {/* Product */}
          <Route exact path="/product/create" component={ProductCreate} />
          <Route exact path="/product/:id/edit" component={ProductCreate} />
          <Route exact path="/product/all" component={ProductAll} />
          {/*  */}

          {/* Department */}
          <Route exact path="/department/create" component={DepartmentCreate} />
          <Route
            exact
            path="/department/:id/edit"
            component={DepartmentCreate}
          />
          <Route exact path="/department/all" component={DepartmentAll} />
          {/*  */}
          {/* Department */}
          <Route exact path="/job-title/create" component={DesignationCreate} />
          <Route
            exact
            path="/job-title/:id/edit"
            component={DesignationCreate}
          />
          <Route exact path="/job-title/all" component={DesignationAll} />
          {/*  */}
          {/* Configuration */}
          <Route exact path="/currency/all" component={CurrencyAll} />
          {/*  */}
          <Route exact path="/pipeline" component={PipelineTarget} />
          <Route exact path="/team/team" component={AllTeam} />
          <Route exact path="/team/view/pipeline" component={ViewPipeline} />
          {/* Reports */}
          <Route exact path="/reports" component={ReportViewAll} />
          <Route
            exact
            path="/reports/wonopportunities"
            component={WonOpportunityList}
          />
          <Route
            exact
            path="/reports/lostopportunities"
            component={LostOpportunityList}
          />
          <Route exact path="/exports" component={Export} />
          <Route
            exact
            path="/reports/noactivity"
            component={NoActivityReportPage}
          />
          <Route exact path="/reports/lessactivity" component={LessActivity} />
          <Route exact path="/reports/comparison" component={MostActivity} />
          <Route
            exact
            path="/reports/pipelinevscompany"
            component={PipelineVsCompany}
          />
          <Route
            exact
            path="/reports/opportunityexpenses"
            component={OpportunityExpenses}
          />
          <Route
            exact
            path="/reports/projected"
            component={ProjectedPayments}
          />
          <Route exact path="/reports/awaiting" component={AwaitingPayments} />
          <Route
            exact
            path="/reports/beforemeeting"
            component={BeforeMeeting}
          />
          <Route
            exact
            path="/reports/allocatedpipelines"
            component={AllocatedPipelines}
          />
          <Route
            exact
            path="/reports/leastperformer"
            component={LeastPerformerPage}
          />
          <Route
            exact
            path="/reports/topperformer"
            component={TopPerformerPage}
          />
          <Route exact path="/reports/export" component={ExportedData} />
          <Route exact path="/reports/info" component={LoginUserContainer} />
          <Route
            exact
            path="/reports/opportunities-coming-to-close"
            component={ClosingOpportunities}
          />
          <Route
            exact
            path="/reports/expenses-approved"
            component={ExpenseView}
          />
          <Route
            exact
            path="/reports/expense-break-down-by-type"
            component={ExpenseBreakdownByTypePage}
          />
          <Route
            exact
            path="/reports/highest-spender-by-user"
            component={HighestSpenderByUserPage}
          />
          <Route
            exact
            path="/reports/highest-spender-by-pipeline"
            component={HighestSpenderByPipelinePage}
          />
          <Route
            exact
            path="/reports/lost-opportunity-vs-expense"
            component={LostOpportunitiesVsExpensePage}
          />
          <Route
            exact
            path="/reports/lost-opportunity-with-reason"
            component={LostOpportunitiesWithReasonWhyPage}
          />
          <Route exact path="/reports/total-leads" component={TotalLeadsPage} />
          <Route
            exact
            path="/reports/leads-by-user"
            component={LeadsByUserPage}
          />
          <Route
            exact
            path="/reports/won-opportunities-timeframe"
            component={WonOpportunitiesTimeframePage}
          />
          <Route
            exact
            path="/reports/lost-opportunities-timeframe"
            component={LostOpportunitiesTimeframePage}
          />
          <Route
            exact
            path="/reports/leads-by-date"
            component={LeadsByDatePage}
          />
          <Route
            exact
            path="/reports/opportunities-by-stage"
            component={OpportunitiesByStagePage}
          />
          <Route
            exact
            path="/reports/leads-with-noactivities"
            component={LeadsWithoutActivities}
          />
          <Route
            exact
            path="/reports/top-performer-volume"
            component={TopPerformerByVolumPage}
          />
          {/* New page  */}
          <Route exact path="/contact/:id/details" component={ViewContact} />
          <Route
            exact
            path="/organisation/:id/details"
            component={ViewOrganizations}
          />
          <Route exact path="/expense-filter-view" component={ExpenseView} />
          <Route
            exact
            path="/expense-sale-approval-view"
            component={ExpenseSalesApprovalView}
          />
          <Route exact path="/activity-list" component={ActivityList} />
          <Route
            exact
            path="/sales-management/report-list"
            component={SalesManagementReports}
          />
          <Route
            exact
            path="/pipeline-management/report-list"
            component={PipelineManagementReports}
          />
          <Route
            exact
            path="/team-performance/report-list"
            component={TeamPerformanceReports}
          />
          <Route
            exact
            path="/sales-lead-management/report-list"
            component={SalesLeadManagementReports}
          />
          <Route
            exact
            path="/financial-statistics/report-list"
            component={FinanceStaticReports}
          />
          <Route
            exact
            path="/reports/expense-break-down-by-type-user"
            component={ExpenseBreakdownByTypeUserPage}
          />
          <Route exact path="/expensetype/list" component={expenseTypeList} />
          <Route
            exact
            path="/expensetype/create"
            component={CreateExpenseType}
          />
          <Route
            exact
            path="/expensetype/update"
            component={updateExpenseType}
          />
        </SideNav>
      </Switch>
      // {/* </Router> */}
    );
  } else {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/account/signup/" component={SignUp} />
        <Route
          exact
          path="/account/password/reset/"
          component={ForgetPassword}
        />
        <Route
          exact
          path="/account/password/reset/d-set-password"
          component={SetNewPassword}
        />
        {/* OnBoarding */}
        {/* <Route exact path="/profile" component={OnboardProfile} /> */}
        <Route
          exact
          path="/designation"
          component={OnboardDesignationMaster}
        />{" "}
        //
        <Route exact path="/department" component={DepartmentMaster} />
        <Route exact path="/add-designation" component={AddDesignatiion} />
        <Route exact path="/add-department" component={AddDepartment} />
        <Route exact path="/product" component={OnboardProduct} />
        <Route exact path="/add-product" component={AddProduct} />
        <Route exact path="/add-expense-type" component={AddExpense} />
        <Route exact path="/expense-type" component={ExpenseType} />
        <Route
          exact
          path="/create-expense-type"
          component={CreateExpenseType}
        />
        {/* <Route exact path="/user-profile" component={UserProfile} /> */}
        <Route render={() => <Redirect to={{ pathname: "/" }} />} />
      </Switch>
      // {/* </Router> */}
    );
  }
}
// }

// export default Application;
