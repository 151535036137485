import {
  getCountryListApi,
  getCompanySearchApi,
  getPipeLineListApi,
  getCourrencyListApi,
  getCompanyProductsApi,
  getContactSearchApi,
  getCurrentUserApi,
  getAllOwnerUsersApi,
  getAllContactOrganizationApi,
  getUserProfileApi,
} from "../api/commonApi";
import { UpdateProfilePicture } from "../api/userApi";
import {
  setAtomBallSpinnerShowSalesDashboard,
  setAtomBallSpinnerHideSalesDashboard,
} from "./reportAction";

export const setAllUsersList = (response) => {
  return {
    type: "SET_OWNER_USER_LIST_RESPONSE",
    response,
  };
};

export const setAllUsersEmptyList = () => {
  return {
    type: "SET_OWNER_USER_LIST_EMPTY",
  };
};

export const setAllOrganizationsRes = (response) => {
  return {
    type: "SET_All_ORGANIZATIONS",
    response,
  };
};

export const setAllOrganizationEmpty = () => {
  return {
    type: "SET_EMPTY_ORGANIZATIONS",
  };
};

export const setCurrentUserSuccessRes = (response) => {
  return {
    type: "SET_CURRENT_USER_SUCCESS_RES",
    response,
  };
};

export const setCurrentUserfailRes = () => {
  return {
    type: "SET_CURRENT_USER_FAILS_RES",
  };
};

export const setCurrentUserEmptyRes = () => {
  return {
    type: "SET_CURRENT_USER_EMPTY_RES",
  };
};

export const setLoaderShow = () => {
  return {
    type: "SET_LOADER_SHOW",
  };
};

export const setLoaderHide = () => {
  return {
    type: "SET_LOADER_HIDE",
  };
};

export const setCountryListRes = (response) => {
  return {
    type: "SET_COUNTRIES_LIST_RESPONSE",
    response,
  };
};

export const setCompanyListEmpty = () => {
  return {
    type: "SET_COMPANY_SEARCH_LIST_EMPTY",
  };
};

export const setContactListEmpty = () => {
  return {
    type: "SET_CONTACT_SEARCH_LIST_EMPTY",
  };
};

export const setCompanySearchList = (response) => {
  return {
    type: "SET_COMPANY_SEARCH_LIST_RES",
    response,
  };
};

export const setContactSearchList = (response) => {
  return {
    type: "SET_CONTACT_SEARCH_LIST_RES",
    response,
  };
};

export const setCourrencyList = (response) => {
  return {
    type: "SET_CURRENCY_LIST_RES",
    response,
  };
};

export const setPipeLineList = (response) => {
  return {
    type: "SET_PIPELINE_LIST_RES",
    response,
  };
};

export const setPipeLineListEmpty = () => {
  return {
    type: "SET_PIPELINE_LIST_EMPTY_RES",
  };
};

export const setPipeLineListFails = (response) => {
  return {
    type: "SET_PIPELINE_LIST_FAILS_RES",
    response,
  };
};

export const setCompanyProducts = (response) => {
  return {
    type: "SET_COMPANY_PRODUCTS_RES",
    response,
  };
};

export const setCompanyProductsEmpty = () => {
  return {
    type: "SET_COMPANY_PRODUCTS_EMPTY_RES",
  };
};

export const setUserProfile = (response) => {
  return {
    type: "SET_USER_PROFILE_RES",
    response,
  };
};

export const setUserProfileFails = (response) => {
  return {
    type: "SET_USER_PROFILE_FAILS_RES",
    response,
  };
};

export const setUserProfileEmpty = () => {
  return {
    type: "SET_USER_PROFILE_EMPTY",
  };
};

export const setProfilePicEmpty = () => {
  return {
    type: "SET_PROFILE_PIC_EMPTY",
  };
};

export const setProfilePicFails = (response) => {
  return {
    type: "SET_PROFILE_PIC_FAILS_RES",
    response,
  };
};

export const setProfilePic = (response) => {
  return {
    type: "SET_PROFILE_PIC_RES",
    response,
  };
};

export const getAllOwnerUserList = (data) => {
  return (dispatch) => {
    // dispatch(setAllOwnerUsersEmptyList());
    dispatch(setAtomBallSpinnerShowSalesDashboard());

    getAllOwnerUsersApi(data).then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setAllUsersList(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
        //error handler
      }
    });
  };
};

export const getCountryList = () => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getCountryListApi().then((result) => {
      dispatch(setCountryListRes(result));
      setTimeout(() => {
        dispatch(setAtomBallSpinnerHideSalesDashboard());
      }, 1000);
    });
  };
};

export const getAllOrganization = () => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    dispatch(setAllOrganizationEmpty());
    getAllContactOrganizationApi().then((result) => {
      dispatch(setAllOrganizationsRes(result));
      setTimeout(() => {
        dispatch(setAtomBallSpinnerHideSalesDashboard());
      }, 1000);
    });
  };
};

export const getCompanySearch = (data) => {
  return (dispatch) => {
    dispatch(setLoaderShow());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getCompanySearchApi(data).then((result) => {
      dispatch(setLoaderHide());
      dispatch(setCompanySearchList(result));
      setTimeout(() => {
        dispatch(setAtomBallSpinnerHideSalesDashboard());
      }, 1000);
    });
  };
};

export const getContactSearch = (data) => {
  return (dispatch) => {
    dispatch(setLoaderShow());
    // dispatch(setAtomBallSpinnerShowSalesDashboard());
    getContactSearchApi(data).then((result) => {
      dispatch(setContactSearchList(result));
      dispatch(setLoaderHide());
      // setTimeout(() => {
      //     dispatch(setAtomBallSpinnerHideSalesDashboard());
      // }, 1000);
    });
  };
};

export const getPipeLineList = () => {
  return (dispatch) => {
    dispatch(setPipeLineListEmpty());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getPipeLineListApi().then((result) => {
      if (result.status === 200) {
        return result.json().then((result) => {
          dispatch(setPipeLineList(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        //error handler
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
      }
    });
  };
};

export const getCourrencyList = () => {
  return (dispatch) => {
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getCourrencyListApi().then((result) => {
      dispatch(setCourrencyList(result));
      setTimeout(() => {
        dispatch(setAtomBallSpinnerHideSalesDashboard());
      }, 1000);
    });
  };
};

export const getCompanyProducts = () => {
  return (dispatch) => {
    //   dispatch(setCompanyProductsEmpty());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getCompanyProductsApi().then((result) => {
      dispatch(setCompanyProducts(result));
      setTimeout(() => {
        dispatch(setAtomBallSpinnerHideSalesDashboard());
      }, 1000);
    });
  };
};

export const getCurrentUser = () => {
  return (dispatch) => {
    dispatch(setCurrentUserEmptyRes());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getCurrentUserApi().then((response) => {
      if (response.status == 200) {
        return response.json().then((result) => {
          dispatch(setCurrentUserSuccessRes(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        dispatch(setCurrentUserfailRes());
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
      }
    });
  };
};

export const getUserProfile = () => {
  return (dispatch) => {
    dispatch(setUserProfileEmpty());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    getUserProfileApi().then((response) => {
      if (response.status == 200) {
        return response.json().then((result) => {
          dispatch(setUserProfile(result));
          setTimeout(() => {
            dispatch(setAtomBallSpinnerHideSalesDashboard());
          }, 1000);
        });
      } else {
        dispatch(setUserProfileFails());
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
      }
    });
  };
};

export const updateProfilePic = (file) => {
  return (dispatch) => {
    dispatch(setProfilePicEmpty());
    dispatch(setAtomBallSpinnerShowSalesDashboard());
    UpdateProfilePicture(file).then((response) => {
      if (response.status == 200) {
        dispatch(setProfilePic(response.data));
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
        // return response.then(result => {

        // })
      } else {
        dispatch(setProfilePicFails());
        setTimeout(() => {
          dispatch(setAtomBallSpinnerHideSalesDashboard());
        }, 1000);
      }
    });
  };
};
