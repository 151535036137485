import { createcontactApi, getContactApi, updateContactApi, deleteContactApi } from '../api/contactApi';
import { setLoaderShow, setLoaderHide } from './commonAction'
import { setAtomBallSpinnerShowSalesDashboard, setAtomBallSpinnerHideSalesDashboard } from './homeActions';

export const setCreateContactRes = (response) => {
    return {
        type: "SET_CREATE_CONTACT_RESPONSE",
        response
    }
}

export const setCreateContactFailRes = (response) => {
    return {
        type: 'SET_CREATE_CONTACT_FAIL_RES',
        response
    }
}

export const setEmptyCreateContactResponse = () => {
    return {
        type: 'SET_CREATE_CONTACT_EMPTY_RES'
    }
}

export const setgetContactSuccessRes = (response) => {
    return {
        type: 'SET_GET_CONTACT_SUCCESS_RES',
        response
    }
}

export const setgetContactFailRes = (response) => {
    return {
        type: 'SET_GET_CONTACT_FAIL_RES',
        response
    }
}

export const setEmptyGetContactResponse = () => {
    return {
        type: 'SET_GET_CONTACT_EMPTY_RES'
    }
}

export const setUpdateContactSuccessRes = (response) => {
    return {
        type: 'SET_UPDATE_CONTACT_SUCCESS_RES',
        response
    }
}

export const setUpateContactFailRes = (response) => {
    return {
        type: 'SET_UPDATE_CONTACT_FAIL_RES',
        response
    }
}

export const setEmptyUpateContact = () => {
    return {
        type: 'SET_UPDATE_CONTACT_EMPTY_RES'
    }
}

export const setdeleteContactSuccessRes = (response) => {
    return {
        type: 'SET_DELETE_CONTACT_SUCCESS_RESPONSE',
        response
    }
}

export const setdeleteContactFailRes = () => {
    return { type: 'SET_DELETE_CONTACT_ERROR_RESPONSE' }
}

export const setdeleteContactEmptyRes = () => {
    return { type: 'SET_DELETE_CONTACT_EMPTY_RESPONSE' }
}

export const createcontact = (data) => {
    return dispatch => {
        dispatch(setdeleteContactEmptyRes())
        createcontactApi(data).then((result) => {
            if (result.success == "true") {
                dispatch(setCreateContactRes(result));
            } else {
                dispatch(setCreateContactFailRes(result));
            }

        });
    }
}


//updateContact
export const updateContact = (data) => {
    return dispatch => {
        dispatch(setLoaderShow());
        updateContactApi(data).then((result) => {
            dispatch(setLoaderShow());
            dispatch(setEmptyGetContactResponse())
            if (result.status == "True") {
                dispatch(setLoaderHide())
                dispatch(setUpdateContactSuccessRes(result));
            } else {
                dispatch(setUpateContactFailRes(result));
            }
        });
    }
}

export const getContact = (id) => {
    return dispatch => {
        getContactApi(id).then((result) => {
            if (result) {
                dispatch(setgetContactSuccessRes(result));
            } else {
                dispatch(setgetContactFailRes(result));
            }
        });
    }
}

export const deleteContact = (id) => {
    return dispatch => {
        dispatch(setAtomBallSpinnerShowSalesDashboard());
        deleteContactApi(id).then((result) => {
            if (result.status === 201 || result.status === 200) {
                setTimeout(() => {
                    dispatch(setAtomBallSpinnerHideSalesDashboard());
                }, 1000);
                return result.json().then(res_data => {
                    dispatch(setdeleteContactSuccessRes(res_data));
                })

            } else {
                setTimeout(() => {
                    dispatch(setAtomBallSpinnerHideSalesDashboard());
                }, 1000);
                return result.json().then(res_data => {
                    dispatch(setdeleteContactFailRes(res_data));
                })
            }
        });
    }
}