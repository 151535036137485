import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function AddExpenseIcon(props) {
    return (
        <SvgIcon  {...props} viewBox="0 0 100 100">
          <path d="M75.5,7H24.8C22.3,7,20,9.2,20,11.7v78.2c0,2.2,1.4,3.1,2.7,3.1c0.7,0,1.6-0.3,2.3-0.9l3.1-2.6c0.7-0.6,1.9-0.5,2.5,0.1  l2.6,2.4c0.9,0.8,2,1.2,3.2,1.2c1.2,0,2.4-0.4,3.2-1.2l2.6-2.4c0.6-0.5,1.6-0.5,2.1,0.1l1.8,2c0.8,0.9,2,1.5,3.3,1.5  c1.1,0,2.2-0.4,3.1-1.2l2.5-2.3c0.6-0.6,1.7-0.6,2.3,0l2.4,2.3c0.9,0.9,2.1,1.3,3.4,1.3c1.1,0,2.2-0.4,3.1-1.1l3.2-2.7  c0.7-0.6,2-0.6,2.7,0l3.2,2.6c0.7,0.6,1.5,0.9,2.2,0.9c0.8,0,1.6-0.4,2-1.1c0.4-0.5,0.6-1.2,0.6-2.1V11.7C80,9.2,78,7,75.5,7z   M77,89.7l-3-2.5c-0.9-0.7-2.1-1.1-3.3-1.1c-1.2,0-2.4,0.4-3.3,1.1l-3.3,2.7c-0.7,0.5-1.9,0.5-2.5-0.1l-2.4-2.3  c-0.9-0.8-2-1.3-3.2-1.3c-1.2,0-2.3,0.4-3.2,1.3l-2.5,2.3c-0.6,0.5-1.5,0.5-2-0.1l-1.8-2c-0.8-0.9-2.1-1.5-3.3-1.5  c-1.1,0-2.2,0.4-3,1.2l-2.6,2.4c-0.6,0.6-1.7,0.6-2.4,0l-2.6-2.4c-0.9-0.8-2.1-1.3-3.3-1.3c-1.2,0-2.4,0.4-3.3,1.1L23,89.7v-78  c0-0.8,1-1.7,1.8-1.7h50.7c0.8,0,1.5,0.9,1.5,1.7V89.7z"/>
          <path d="M67.4,30H32.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h34.5c0.8,0,1.5-0.7,1.5-1.5S68.2,30,67.4,30z"/>
          <path d="M67.4,46H32.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h34.5c0.8,0,1.5-0.7,1.5-1.5S68.2,46,67.4,46z"/>
          <path d="M67.4,61H32.9c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h34.5c0.8,0,1.5-0.7,1.5-1.5S68.2,61,67.4,61z"/>
          <text x="0" y="115" fill="#000000" fontSize="5px" fontWeight="bold" fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text>
          <text x="0" y="120" fill="#000000" fontSize="5px" fontWeight="bold" fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text>
      </SvgIcon>
    );
}

export default AddExpenseIcon;
