import apiToken from '../api/token';
import endpoints from './endpoints';
import { id } from 'date-fns/locale';
import Axios from "axios";
import convertToQueryString from './covertToQueryString';

// let api_token = apiToken();
// const token = api_token.accessToken;

export const getUserDesignationsApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.GET_USER_DESIGNATIONS, {
        method: "GET",
        crossDomain: true,
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getUserAllApi = async (params) => {
    var url = endpoints.GET_ALL_USER;
    if (params) {
        var queryString = convertToQueryString(params)
        url = url + '?' + queryString;
    }
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(url, {
        method: "GET",
        crossDomain: true,
        headers: headers
    });

    return await fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getDepartmentApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.GET_DEPARTMENT, {
        method: "GET",
        crossDomain: true,
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getUserRoleApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.GET_USER_ROLE, {
        method: "GET",
        crossDomain: true,
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}


export const postUserApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.account_signup, {
        method: "POST",
        crossDomain: true,
        body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const updateUserApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.UPDATE_USER + data.user, {
        method: "PUT",
        crossDomain: true,
        body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const deleteUserApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.UPDATE_USER + id, {
        method: "DELETE",
        crossDomain: true,
        // body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getUserApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.UPDATE_USER + id, {
        method: "GET",
        crossDomain: true,
        // body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const ActivateDeactivateUserApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.USER_ACTIVATE, {
        method: "PUT",
        crossDomain: true,
        body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getUserResetPasswordApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.FORGET_PASSWORD + id, {
        method: "GET",
        crossDomain: true,
        // body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getUserProfileApi = (id) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    const request = new Request(endpoints.GET_USER_PROFILE, {
        method: "GET",
        crossDomain: true,
        // body: JSON.stringify(data),
        headers: headers
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const UpdateProfilePicture = (file) => {
    let formData = new FormData();
    formData.append('avatar_full', file)
    let api_token = apiToken();
    const token = api_token.accessToken;
    // const headers = Object.assign({ 'Content-Type': 'application/json', Authorization: token, });
    // const request = new Request(endpoints.USER_PROIFILE_PICTURE, {
    //     method: "PUT",
    //     crossDomain: true,
    //     body: JSON.stringify(formData),
    //     headers: headers
    // });
    var url = endpoints.USER_PROIFILE_PICTURE
    return Axios.put(url, file,
        {
            headers: {
                Authorization: token,
            }
        }).then(res => {
            return res;

        }).catch(error => {
            return error;
        });

    // return fetch(request).then(response => {
    //     return response;
    // }).catch(error => {
    //     return error;
    // });
}
// fileSelectedHandler = (formData) => {
//     let formData = new FormData();
//     let api_token = apiToken();
//     const token = api_token.accessToken;

//     // var url = 'https://elvis2-backend.do.viewyoursite.net/api/user/profile-picture'
//     var url = endpoints.USER_PROIFILE_PICTURE;
//     formData.append('avatar_full', event.target.files[0])
//     Axios.put(url, formData,
//         {
//             headers: {
//                 Authorization: token,
//             }
//         }).then(res => {
//             const final_url = base_uri.BASE_URL + res.data.avatar_full;
//             this.setState({ image: final_url })

//         })

// }