import endpoints from "./endpoints";
import Axios from "axios";
import { Redirect } from "react-router-dom";
import React, { Component } from 'react'


export default function tokenVerify(token) {
    const URL = endpoints.AUTH_TOKEN_VERIFY;
    var data = {
        "token": JSON.parse(token),
    }
    Axios.post(
        URL,
        data,
    ).then(res => {
        
    }).catch(error => {
        if (error?.response?.status === 401 || error?.response?.status === 400) {
            localStorage.removeItem('access');
            sessionStorage.removeItem('access');
            return (<Redirect to="/" />)
        } else {
            alert(error.message)
        }
    })
}