import { Collapse, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Link, withRouter } from "react-router-dom";
import React, { Component } from 'react';

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Icons from "../../components/icons";
import { withStyles } from "@material-ui/core/styles";

const drawerWidth = 233;
const TITLE = "Save on CRM : ";

const styles = (theme) => ({
    root: {
        display: "flex",
        fontFamily: "sans-serif",
    },
    appBar: {
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        borderRight: "0px",
    },
    drawerHeader: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
        justifyContent: "flex-end",
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    nav: {
        backgroundColor: "#1a1a1a",
        cursur: "pointer",
    },

    toolBarTop: {
        position: "fixed",
        zIndex: "700",
        boxShadow:
            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
        backgroundColor: "#202020",
        width: drawerWidth,
    },
    expandIcon: {
        color: "white",
    },
    listText: {
        color: "#797979 ",
        padding: "0 16px",
        fontWeight: "400",
        fontSize: "16px",
    },
    card: {
        backgroundColor: "primary",
    },
    subMenu: {
        backgroundColor: "#323232",
    },

    menuListItem: {
        marginTop: "8px !importatnt",
        "&:hover": {
            backgroundColor: "#313131",
        },
    },
    subMenuListItem: {
        "&:hover": {
            backgroundColor: "#3A3A3A",
        },
    },
    link: {
        underline: "none",
    },
    grow: {
        flexGrow: 1,
    },
    notic_container: {
        minWidth: 350,
        paddingLeft: "5%",
        backgroundColor: "#EEEEEE",
    },
    help_container: {
        minWidth: 350,
        padding: "2% 2% 2% 2%",
    },
    fab: {
        position: "fixed",
        right: 10,
        bottom: 10,
        transition: "tarnsform 2s",
    }
});


var opened_menu = "";

function handleMenuClick(menu) {
    opened_menu = menu;
}

// function CustomListItem(menu_item, styleIcon, pindex, classes) {
//     if (!menu_item.hasOwnProperty("sub")) {
//         const LIST_ITEM = (
//             <ListItem key={pindex} className={classes.menuListItem}
//                 {...{
//                     to: menu_item?.link?.startsWith("/")
//                         ? menu_item.link
//                         : "/" + menu_item.link,
//                 }} component={Link} button={true}
//             >
//                 <Icons name={menu_item.icon} htmlColor="#fff" style={styleIcon} />
//                 <ListItemText primary={<Typography className={classes.listText}> {menu_item.name} </Typography>}
//                     className="menufont"
//                 />
//             </ListItem>
//         )
//         return LIST_ITEM;
//     } else {
//         const LIST_ITEM = (
//             <ListItem
//                 className={classes.menuListItem}
//                 button
//                 onClick={handleMenuClick(menu_item.name)}
//                 style={{ paddingTop: "10px", paddingBottom: "10px" }}
//             >
//                 <Icons name={menu_item.icon} htmlColor="#fff" style={styleIcon} />
//                 <ListItemText primary={<Typography className={classes.listText}> {menu_item.name} </Typography>} />
//                 {menu_item.open ? (
//                     <ExpandLess className={classes.expandIcon} />
//                 ) : (
//                     <ExpandMore
//                         className={classes.expandIcon}
//                         style={{ width: "26px", height: "26px", paddingLeft: "1px", marginRight: "-1px", marginTop: "2px", color: "#fff" }}
//                     />
//                 )}
//             </ListItem>
//         )
//         return LIST_ITEM;
//     }

// }

class NavList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuList: props.menuList,
            menuButtons: false,
        };
    }

    handleMenuClick = (menu, is_submenu) => {
        this.props.handleMenuClick(menu, is_submenu);
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <List className={classes.nav} component="nav" style={{ width: "233px" }} >
                    {
                        this.props?.menuList && this.props?.menuList.map((item, pindex) => {
                            let styleIcon = {};
                            if (item.icon === "dashboard") {
                                styleIcon = { width: "24.89px", height: "35px", marginTop: "2px" };
                            } else if (item.icon === "leads") {
                                styleIcon = { width: "24px", height: "36px", marginRight: "5px" };
                            } else if (item.icon === "accounts") {
                                styleIcon = { width: "33px", height: "35px", marginRight: "-8px", marginTop: "6px" };
                            } else if (item.icon === "opportunity") {
                                styleIcon = { width: "28px", height: "35px", marginRight: "1px", marginTop: "3px", };
                            } else if (item.icon === "expenses") {
                                styleIcon = { width: "25px", height: "35px" };
                            } else if (item.icon === "configuration") {
                                styleIcon = { width: "25px", height: "35px" };
                            } else if (item.icon === "reports") {
                                styleIcon = { width: "17.5px", height: "35px", marginRight: "11px", marginTop: "-1px", };
                            }
                            return item.sub && item.sub.length > 0 ? (
                                <div className="menuli" key={pindex}>
                                    <ListItem
                                        className={classes.menuListItem}
                                        button
                                        onClick={this.handleMenuClick.bind(this, item, false)}
                                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                                    >
                                        <Icons
                                            name={item.icon}
                                            htmlColor="#fff"
                                            style={styleIcon}
                                        />
                                        <ListItemText
                                            primary={
                                                <Typography className={classes.listText}>
                                                    {item.name}
                                                </Typography>
                                            }
                                        />
                                        {item.open ? (
                                            <ExpandLess className={classes.expandIcon} />
                                        ) : (
                                            <ExpandMore
                                                className={classes.expandIcon}
                                                style={{
                                                    width: "26px",
                                                    height: "26px",
                                                    paddingLeft: "1px",
                                                    marginRight: "-1px",
                                                    marginTop: "2px",
                                                    color: "#fff",
                                                }}
                                            />
                                        )}
                                    </ListItem>
                                    <Collapse in={item.open} timeout="auto" unmountOnExit>
                                        <List
                                            className={classes.subMenu}
                                            component="div"
                                            disablePadding
                                        >
                                            {
                                                item.sub.map((subMenu, index) => (
                                                    <ListItem
                                                        key={index}
                                                        className={classes.menuListItem}
                                                        {...{
                                                            to: subMenu.link,
                                                        }}
                                                        component={Link}
                                                        button={true}
                                                    >
                                                        <ListItemText
                                                            primary={
                                                                <Typography className={classes.listText}>
                                                                    {" "}
                                                                    <span
                                                                        style={{ marginRight: "25px" }}
                                                                    ></span>
                                                                    {subMenu.name}{" "}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                </div>
                            ) : (
                                <ListItem
                                    key={pindex}
                                    className={classes.menuListItem}
                                    {...{
                                        to: item?.link
                                    }}
                                    component={Link}
                                    button={true}
                                >
                                    <Icons
                                        name={item.icon}
                                        htmlColor="#fff"
                                        style={styleIcon}
                                    />
                                    <ListItemText
                                        primary={
                                            <Typography className={classes.listText}>
                                                {item.name}
                                            </Typography>
                                        }
                                        className="menufont"
                                    />
                                </ListItem>
                            );
                        })}
                </List>
            </div>
        );
    }
}

export default withStyles(styles)(NavList);