import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Divider } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import equal from 'fast-deep-equal';
import Box from '@material-ui/core/Box';
import currencyFormate from '../../../api/currencyFormate';
const styles = theme => ({
    paper: {
        // padding: theme.spacing(2),
        color: theme.palette.text.primary,
        dispay: 'flex',
        minWidth: '300px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
        margin: '0 5px'
    },
    toolbar: {
        minHeight: '64px',
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        width: '100%',
        background: 'white',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    title: {
        font: '300 20px/32px Poppins, sans-serif',
        margin: '0'
    },
    box: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: "space-between",
        borderRadius: '4px',
        padding: '0px !important',
        width: '100%',
        textAlign: 'center',
        background: 'rgb(216, 216, 216)',
    },
    boxContainer: {
        textAlign: 'center',
        padding: '2px',
        // flex: '1 1 100%',
        boxSizing: 'border-box',
        // maxWidth: '33%'
    },
    dropZoneContainer: {
        height: '100%',
        position: 'relative',
    },
    dropZoneContainerUl: {
        paddingLeft: '0px',
        minHeight: '200px',
        height: '100%',
        display: 'block!important',
        listStyle: 'none',
        justifyContent: 'space-around',
        float: "left"
    },
    paper2: {
        display: 'block',
        padding: '8px',
        minHeight: '30px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
        margin: '12px'
    }
});
class SalesBoardListItem extends Component {
    state = {

    }


    itemClicked = (id) => {
        this.props.goToOpportunityByID(id);
    }

    render() {
        const { classes, value, label, color, data } = this.props;
        let showSalesItemList = "";
        if (this.props.data.length > 0) {
            showSalesItemList = this.props.data.filter((opportunity) => (opportunity.pipeline === this.props.pipeline)).map(item => (

                <Paper className={classes.paper2}
                    onDragStart={(e) => this.props.onDragStart(e, item, this.props.stage_name)}
                    draggable onClick={() => this.itemClicked(item.opportunity_id)}  >

                    <Grid container direction="row" >
                        <Grid item md={8}>
                            <span style={{ width: 'calc(100% - 80px)', display: 'inline-block', fontSize: '14px' }}>{item.opportunity_name}</span>
                        </Grid>
                        <Grid item md={4}>
                            <span style={{ float: 'right' }}>{item.opportunity_currency.currency_symbol} {item.opportunity_value ? currencyFormate(item.opportunity_value) : currencyFormate(0)}</span>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" >
                        <Grid item md={9}>
                            <span style={{ fontFamily: 'Poppins,sans-serif', fontSize: '10px', textAlign: 'left', marginTop: '0px', color: '#282828', fontWeight: '700' }}>{item.opportunity_currency.currency_symbol} {currencyFormate(item.expenses)} Expenses</span>
                        </Grid>
                        <Grid item md={9}>
                            <span style={{ fontFamily: 'Poppins,sans-serif', fontSize: '10px', textAlign: 'left', marginTop: '5px', color: '#9e9a9a', textTransform: 'uppercase' }} >{item?.owner?.first_name + ' ' + item?.owner?.last_name}</span>
                        </Grid>
                    </Grid>
                </Paper>
            ))
        }



        return (
            <div className={classes.dropZoneContainer}>
                <ul className={classes.dropZoneContainerUl}>
                    {showSalesItemList}
                </ul>
            </div>);
    }
}
SalesBoardListItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default withStyles(styles)(SalesBoardListItem);

