import React from "react";
import Popover from "@material-ui/core/Popover";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {
  withStyles,
  MenuItem,
  Button,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import convertToQueryString from "../../api/covertToQueryString";
import endpoints from "../../api/endpoints";
import Axios from "axios";
import ApiToken from "../../api/token";

const style = (theme) => ({
  field: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(0),
    backgroundColor: "#282828",
    width: "100%",
    color: "white",
    "&:hover": {
      backgroundColor: "#282828",
    },
  },
  top: {
    maxWidth: 450,
    padding: "20px 38px 20px 38px",
  },
});

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status_list: ["lead", "converted to contact", "converted to opportunity"],
      anchorEl: "",
      viewModel: false,
      pipeline_list: [],
      list_all_owner_users: [],
      status: this.props.expenseStatus,
      is_pipeline_filter: true,
      is_user_filter: true,
      is_expenseStatus_filter: false,
      is_expenseType_filter: false,
      filter: {
        user: 0,
        status: "",
        from: "",
        to: "",
        pipeline: 0,
        opportunity: 0,
        expense_type: "",
      },
    };
  }

  componentDidMount() {
    if (
      this.props.current_user !== null &&
      this.props.current_user !== undefined
    ) {
      let user = this.props.current_user.profile.user;
      let filter = this.state.filter;
      filter.user = user;

      this.setState({ filter });
    }

    var filter = this.props.filterdata;
    // filter = 
    this.setState({
      filter,
    });
    // }

    if (this.props.is_expenseStatus_filter !== undefined) {
      if (this.props.is_expenseStatus_filter) {
        this.setState({
          is_expenseStatus_filter: true,
        });
      }
    }

    if (this.props.is_user_filter !== undefined) {
      if (this.props.is_user_filter) {
        this.setState({
          is_user_filter: true,
        });
      } else {
        this.setState({
          is_user_filter: false,
        });
      }
    }

    if (this.props.is_pipeline_filter !== undefined) {
      if (this.props.is_pipeline_filter) {
        this.setState({
          is_pipeline_filter: true,
        });
      } else {
        this.setState({
          is_pipeline_filter: false,
        });
      }
    }
    var user = this.props.list_all_owner_users;
    this.setState({
      pipeline_list: this.props.pipeline_list,
      list_all_owner_users: user,
      anchorEl: this.props.anchorEl,
      viewModel: this.props.viewModel,
    });
  }

  closeFilter = (e) => {
    this.setState({ viewModel: false, anchorEl: "" });

    if (this.props.handleFilterClose) {
      this.props.handleFilterClose();
    }
  };

  onChange = (event) => {
    let filter = this.state.filter;
    filter[event.target.name] = event.target.value;
    // if (event.target.name === 'pipeline') {
    //     let data = { pipeline: event.target.value };
    //     this.getUserList(data);
    // }

    this.setState({ filter });
  };

  filter = () => {
    let searchData = this.state.filter;
    this.props.filter(searchData);
    this.props.handleFilterClose();
  };

  handleFilterClear = () => {
    let filter = this.state.filter;
    filter.user = 0;
    filter.pipeline = 0;
    filter.from = "";
    filter.to = "";
    filter.status = "";
    filter.expense_type = "";
    filter.expense_head_status = "";
    filter.opportunity = 0;
    this.setState({ filter });
    this.props.filter(filter);
    // this.props.handleFilterClose();
  };

  getUserList = (params) => {
    var query_params = convertToQueryString(params);
    let url = endpoints.GET_ALL_USER;
    if (query_params) {
      url = url + "?" + query_params;
    }
    let api_token = ApiToken();
    Axios.get(url, {
      headers: {
        Authorization: api_token.accessToken,
      },
    }).then((res) => {
      if (res.status == 200) {
        this.setState({
          list_all_owner_users: res.data,
        });
        // this.setData(res.data);
      }
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Popover
          open={this.state.viewModel}
          onClose={this.closeFilter}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.top}>
            <Grid container spacing={2}>
              {this.props.is_pipeline_filter &&
              this.props.is_pipeline_filter ? (
                <Grid item md={6} lg={6}>
                  <TextField
                    name="pipeline"
                    className={classes.field}
                    label="PIPELINE"
                    required={true}
                    select
                    value={this.state.filter.pipeline}
                    onChange={this.onChange}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {this.state.pipeline_list &&
                      this.state.pipeline_list.length > 0 &&
                      this.state.pipeline_list.map((option) => (
                        <MenuItem
                          key={option.pipeline_id}
                          value={option.pipeline_id}
                        >
                          {option.pipeline_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}

              {
                <Grid item md={6} lg={6}>
                  <TextField
                    name="user"
                    className={classes.field}
                    label="BY"
                    select
                    onChange={this.onChange}
                    value={this.state.filter?.user}
                  >
                    <MenuItem value={0}>All</MenuItem>
                    {this.state.list_all_owner_users &&
                      this.state.list_all_owner_users
                        .filter((user) => {
                          if (
                            user.first_name !== "" &&
                            user.first_name !== undefined
                          ) {
                            return user;
                          }
                        })
                        .map((option) => (
                          <MenuItem key={option.user} value={option.user}>
                            {option.first_name + " " + option.last_name}
                          </MenuItem>
                        ))}
                  </TextField>
                </Grid>
              }
              {this.props.is_expense_opportunity_filter ? (
                <Grid item sm={6} md={6} lg={6}>
                  <TextField
                    name="opportunity"
                    className={classes.field}
                    label="Opportunity"
                    select
                    onChange={this.onChange}
                    value={this.state.filter.opportunity}
                  >
                    <MenuItem value={0}>{"All"}</MenuItem>
                    {/* {this.state.expenseStatus && this.state.expenseStatus.map((option, i) => ( */}
                    {this.props.Opportunities_list &&
                      this.props.Opportunities_list.filter((opportunity) => {
                        if (this.state.filter.pipeline === 0) {
                          return opportunity;
                        } else if (
                          opportunity.pipeline === this.state.filter.pipeline
                        ) {
                          return opportunity;
                        }
                      }).map((item, i) => (
                        <MenuItem key={i} value={item.opportunity_id}>
                          {item.opportunity_name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}
              {this.props.is_expenseStatus_filter &&
              this.props.is_expenseStatus_filter ? (
                <Grid item sm={6} md={6} lg={6}>
                  <TextField
                    name="status"
                    className={classes.field}
                    label="Expense Status"
                    select
                    onChange={this.onChange}
                    value={this.state.filter.status}
                  >
                    {/* {this.state.expenseStatus && this.state.expenseStatus.map((option, i) => ( */}
                    <MenuItem value={"total expenses"}>
                      {"Total Expenses"}
                    </MenuItem>
                    <MenuItem value={"awaiting approval"}>
                      {"Awaiting approval"}
                    </MenuItem>
                    <MenuItem value={"awaiting payment"}>
                      {"Awaiting payment"}
                    </MenuItem>
                    <MenuItem value={"paid"}>{"Paid or Approved"}</MenuItem>
                    <MenuItem value={"rejected"}>{"Rejected"}</MenuItem>
                    {/* ))} */}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}

              {this.props.is_expenseType_filter &&
              this.props.is_expenseType_filter ? (
                <Grid item sm={6} md={6} lg={6}>
                  <TextField
                    name="expense_type"
                    className={classes.field}
                    label="Expense Type"
                    select
                    onChange={this.onChange}
                    value={this.state.filter.expense_type}
                  >
                    <MenuItem value={"all"}>{"All"}</MenuItem>
                    {this.props.expenseTypeList &&
                      this.props.expenseTypeList.map((type, i) => {
                        return (
                          <MenuItem key={i} value={type.type_id}>
                            {type.type_value}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                </Grid>
              ) : (
                ""
              )}

              <Grid item md={6} lg={6}>
                <TextField
                  name="from"
                  id="date"
                  label="From"
                  type="date"
                  onChange={this.onChange}
                  value={this.state.filter.from}
                  className={classes.field}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item md={6} lg={6}>
                <TextField
                  name="to"
                  id="date"
                  label="To"
                  type="date"
                  onChange={this.onChange}
                  className={classes.field}
                  value={this.state.filter.to}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid item md={12} lg={12}>
                <button
                  onClick={this.handleFilterClear}
                  style={{
                    width: "98%",
                    textDecoration: "underline",
                    border: "none",
                    background: "none",
                  }}
                >
                  {" "}
                  Clear filters
                </button>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Button className={classes.button} onClick={this.filter}>
                  Apply filters
                </Button>
              </Grid>
            </Grid>
          </div>
        </Popover>
      </div>
    );
  }
}

export default withStyles(style)(Filters);
