import React, { Component } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField, Button, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import equal from "fast-deep-equal";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ContactAutoComplete from "../../components/auto-complete/contact";
import OrganizationAutoComplete from "../../components/auto-complete/organization";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withRouter } from "react-router-dom";
import SpinnerAtomBall from "../../components/atom-ball-spinner";
const styles = (theme) => ({
  container: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0),
      width: "100%",
    },
    padding: 30,
    margin: "20px",
  },
  button: {
    margin: theme.spacing(0),
    width: "100%",
  },
});

class OpportunityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currency_list: [],
      pipeline_list: [],
      company_products_list: [],
      list_all_owner_users: [],
      pipeline_stage_list: [],
      company_search_array: [],
      contact_search_array: [],
      organization_name: "",
      organization: {},
      opportunity_name: "",
      description: "",
      expected_closed_date: "",
      opportunity_currency: "",
      opportunity_value: "",
      owner: "",
      pipeline: "",
      stage: "",
      product: "",
      singleOpportunity: {},
      contact_name: "",
      value: "",
      leads: "",
      opportunity: {
        opportunity_name: "",
        description: "",
        expected_closed_date: new Date(),
        opportunity_currency: "",
        opportunity_value: "",
        owner: "",
        pipeline: "",
        stage: "",
        product: "",
      },
      org: "",
      contact: {},
      organization: {},
      ContactautoCompleteOpen: false,
      CompanyautoCompleteOpen: false,
      company_autocomplete: false,
      current_user: "",
      error: {
        name: "",
        description: "",
        contact: "",
        org: "",
        organization_name: "",
        expected_closing_date: "",
        currency: "",
        value: "",
        owner: "",
        pipeline: "",
        stage: "",
        product_service: "",
      },
      disabled: false,
      loading: false,
      test: "",
    };
  }

  componentWillMount() {
    if (this.props.currency_list && this.props.currency_list.length > 0) {
      this.setState({ currency_list: this.props.currency_list });
    }
    if (this.props.pipeline_list && this.props.pipeline_list.length > 0) {
      this.setState({ pipeline_list: this.props.pipeline_list });
    }
    if (
      this.props.list_all_owner_users &&
      this.props.list_all_owner_users.length > 0
    ) {
      this.setState({ list_all_owner_users: this.props.list_all_owner_users });
    }
    if (
      this.props.company_products_list &&
      this.props.company_products_list.length > 0
    ) {
      this.setState({
        company_products_list: this.props.company_products_list,
      });
    }
    if (
      this.props.pipeline_stage_list &&
      this.props.pipeline_stage_list.length > 0
    ) {
      this.setState({ pipeline_stage_list: this.props.pipeline_stage_list });
    }
  }

  componentDidUpdate(prevProps) {
    if (!equal(this.props.companySearchList, prevProps.companySearchList)) {
      let organization_name = this.state.organization_name;
      this.props.companySearchList &&
        this.props.companySearchList.filter((comp) => {
          if (organization_name == comp.organization_name) {
            this.setState({ organization: comp }, () => {
              console.log("comp : ", comp);
            });
          }
        });
      this.setState({ company_search_array: this.props.companySearchList });
    }

    if (!equal(this.props.contactSearchList, prevProps.contactSearchList)) {
      this.setState({ contact_search_array: this.props.contactSearchList });
    }

    if (
      !equal(this.props.singleOpportunity, prevProps.singleOpportunity) ||
      this.props.singleOpportunity != null
    ) {
      if (Object.keys(this.props.singleOpportunity).length > 0) {
        let singleOpportunity = this.props.singleOpportunity;
        this.setOpportunitydetails(singleOpportunity);
        let name = "";

        if (singleOpportunity.opportunity.opportunity_contacts.length > 0) {
          name =
            singleOpportunity.opportunity.opportunity_contacts[0]
              .contact_details !== null
              ? singleOpportunity.opportunity.opportunity_contacts[0]
                  .contact_details.first_name +
                " " +
                singleOpportunity.opportunity.opportunity_contacts[0]
                  .contact_details.last_name
              : "";
        }

        let stage = "",
          isstage = false;
        stage = singleOpportunity.opportunity.stage;
        var number = parseInt(
          singleOpportunity.opportunity.opportunity_value
            ? singleOpportunity.opportunity.opportunity_value
            : 0
        );
        var opp_value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        this.setState({
          singleOpportunity: this.props.singleOpportunity,
          opportunity_name: singleOpportunity.opportunity.opportunity_name,
          description: singleOpportunity.opportunity.description,
          expected_closed_date:
            singleOpportunity.opportunity.expected_closed_date,
          opportunity_currency:
            singleOpportunity.opportunity.opportunity_currency.currency_id,
          opportunity_value: opp_value,
          owner:
            singleOpportunity.opportunity.owner != null
              ? singleOpportunity.opportunity.owner.user
              : "",
          pipeline: singleOpportunity.opportunity.pipeline,
          stage: stage,
          product: singleOpportunity.opportunity.product
            ? singleOpportunity.opportunity.product.product_id
            : null,
          contact_name: name,
        });
        this.props.setSingleOpportunityEmpty();
      } else {
        if (
          this.props.current_user.company !== undefined &&
          this.props.current_user.company !== null &&
          (this.props.singleOpportunity === "" ||
            this.props.singleOpportunity === null) &&
          this.state.opportunity_currency === ""
        ) {
          this.setState({
            opportunity_currency: this.props.current_user.company.currency
              .currency_id,
          });
        }
      }
    } else {
      if (
        this.props.current_user.company !== undefined &&
        this.props.current_user.company !== null &&
        (this.props.singleOpportunity === "" ||
          this.props.singleOpportunity === null) &&
        this.state.opportunity_currency === ""
      ) {
        this.setState({
          opportunity_currency: this.props.current_user.company.currency
            .currency_id,
        });
      }
    }

    if (!equal(this.props.current_user, prevProps.current_user)) {
      if (
        this.props.current_user.profile !== undefined &&
        this.props.current_user.profile !== null
      ) {
        this.setState({ current_user: this.props.current_user.profile.user });
      }
    }

    if (!equal(this.props.contactSearchList, prevProps.contactSearchList)) {
      this.setState({ contact_search_array: this.props.contactSearchList });
    }

    if (!equal(this.props.contactSearchList, prevProps.contactSearchList)) {
      this.setState({ contact_search_array: this.props.contactSearchList });
    }
  }

  componentWillReceiveProps(props) {
    // if (props.pipeline_stage_list && props.pipeline_stage_list.length > 0) {
    this.setState({
      pipeline_stage_list: props.pipeline_stage_list,
      company_products_list: props.company_products_list,
    });
    // }
  }

  _getSelectedCompanyDetails = (event, value) => {
    if (value !== null) {
      let contact = this.state.contact;
      if (contact.contact_id == "") {
        contact.official_email = value.official_email;
        contact.website = value.website;
        contact.official_phone = value.official_phone;
        contact.mobile_no = value.mobile_no;
        contact.address_line1 = value.address_line1;
        contact.address_line2 = value.address_line2;
        contact.address_city = value.address_city;
        contact.address_state = value.address_state;
        contact.address_country = value.address_country;
        contact.address_zip = value.address_zip;
        contact.linkedin_url = value.linkedin_url;
        contact.facebook_url = value.facebook_url;
        contact.twitter_handle = value.twitter_handle;
      }
      this.setState({
        contact,
        organization: value,
        organization_name: value.organization_name,
        leads: value.organization_name,
      });
    } else {
      this.setState({
        org: "",
        organization_name: "",
        company_autocomplete: true,
        organization: {},
        company_search_array: [],
      });
    }
  };

  _handleCompanyChange = (e) => {
    const val = e.target.value;
    this.setState({ organization_name: val });
    let organization = {};
    organization.organization_name = val;
    this.setState({ organization });
    this.props.getCompanyList(val);
    setTimeout(() => {
      this.filterCompany(val);
    }, 500);
    // clearTimeout(this.typingTimer);
    // this.typingTimer = setTimeout(() => {
    //     if (val) {
    //         this.props.getCompanyList(val);
    //         if (val.length > 3 && this.state.company_search_array.length == 0) {
    //             this.setState({
    //                 company_autocomplete: false,
    //                 organization_name: val
    //             })
    //         } else {
    //             this.setState({
    //                 company_autocomplete: true,
    //                 // organization_name: ""
    //             })
    //         }
    //     } else {
    //         this.setState({ company_search_array: [] });
    //         this.props.setCompanyListEmpty();
    //     }
    // }, 500);
  };

  filterCompany = (value) => {
    // let companylist = this.state.company_search_array;
    // let organization = companylist.filter(comp => value == comp.organization_name);
    // this.setState({organization});
  };

  onChangedate = (event) => {
    let error = this.state.error;
    let currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (event != null) {
      let date = new Date(
        event.getFullYear(),
        event.getMonth(),
        event.getDate()
      );
      if (date >= currentDate) {
        let state = this.state;
        error.expected_closing_date = "";
        state["expected_closed_date"] = event;

        this.setState({ state, error });
      } else {
        error.expected_closing_date =
          "Expacted closing date cannot before current date";
        this.setState({ error, expected_closed_date: event });
      }
    } else {
      error.expected_closing_date = "";
      this.setState({ error, expected_closed_date: event });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      // stage: event.target.value,
      // opportunity
    });
    if (event.target.name == "pipeline") {
      //this.setState({stage:''});
      this.props.getPipelineStage(event.target.value);
    }
    if (event.target.name == "opportunity_value") {
      var value = event.target.value;
      value = value.replace(/\D/g, "");
      var number = value;
      value = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.setState({
        opportunity_value: value,
      });
    }
  };

  onChangeCurrency = (event) => {
    const opportunity = this.state.opportunity;
    opportunity["currency"] = event.target.value;
    this.setState({ opportunity: opportunity });
  };

  onChangeContact = (event) => {
    const opportunity = this.state.opportunity;
    const onlyNums = event.target.value;
    if (onlyNums.length < 14) {
      opportunity[event.target.name] = event.target.value;
      this.setState({ opportunity: opportunity });
    }
  };

  validate = () => {
    let flag = false;
    let error = this.state.error;
    if (this.state.opportunity_value == "") {
      error.value = "This field is required";
      flag = true;
    } else {
      error.value = "";
    }

    if (this.state.opportunity_name == "") {
      error.name = "This field is required";
      flag = true;
    } else {
      error.name = "";
    }

    if (error.expected_closing_date) {
      flag = true;
    }

    if (
      this.state.expected_closed_date == "" ||
      this.state.expected_closed_date == null
    ) {
      error.expected_closing_date = "This field is requered";
      flag = true;
    }

    if (this.state.description == "") {
      error.description = "This field is required";
      flag = true;
    } else {
      error.description = "";
    }

    if (this.state.opportunity_currency == "") {
      error.currency = "This field is required";
      flag = true;
    } else {
      error.currency = "";
    }

    if (this.state.owner == "") {
      error.owner = "This field is required";
      flag = true;
    } else {
      error.owner = "";
    }

    if (this.state.pipeline == "") {
      error.pipeline = "This field is required";
      flag = true;
    } else {
      let ispipeline = true;
      this.state.pipeline_list.map((item) => {
        if (parseInt(item.pipeline_id) === parseInt(this.state.pipeline)) {
          ispipeline = false;
        }
      });
      if (ispipeline) {
        error.pipeline = "This field is required";
        flag = true;
      } else {
        error.pipeline = "";
      }
      //error.pipeline = ""
    }

    if (this.state.stage == "") {
      error.stage = "This field is required";
      flag = true;
    } else {
      let isStage = true;
      this.state.pipeline_stage_list.map((item) => {
        if (parseInt(item.stage_id) === parseInt(this.state.stage)) {
          isStage = false;
        }
      });
      if (isStage) {
        error.stage = "This field is required";
        flag = true;
      } else {
        error.stage = "";
      }
    }

    if (this.state.product == "") {
      error.product_service = "This field is required";
      flag = true;
    } else {
      let isproduct_service = true;
      this.state.company_products_list.map((item) => {
        if (parseInt(item.product_id) === parseInt(this.state.product)) {
          isproduct_service = false;
        }
      });
      if (isproduct_service) {
        error.product_service = "This field is required";
        flag = true;
      } else {
        error.product_service = "";
      }
      //error.product_service = ""
    }

    if (Object.keys(this.state.contact).length == 0) {
      error.contact = "This field is required";
      flag = true;
    } else {
      error.contact = "";
    }
    this.setState({ error });
    return flag;
  };

  postOpportunityPayload = () => {
    let opportunity = this.state.opportunity;
    if (!this.validate()) {
      this.setState({ disabled: true, loading: true });
      opportunity.opportunity_name = this.state.opportunity_name;
      opportunity.opportunity_value = this.state.opportunity_value.replace(
        /,/g,
        ""
      );
      opportunity.opportunity_currency = this.state.opportunity_currency;
      opportunity.description = this.state.description;
      opportunity.stage = this.state.stage;
      opportunity.product = this.state.product;
      opportunity.owner = this.state.owner;
      opportunity.pipeline = this.state.pipeline;
      opportunity.expected_closed_date = this.state.expected_closed_date;
      let contact = this.state.contact;
      let organization = this.state.organization;
      let payload = {
        opportunity,
        // org: this.state.org,
        contact,
        organization,
      };
      this.props.createOpportunity(payload);
      this.setState({ loading: false });
      // this.props.history.push('/opportunity/list');
    }
  };

  setOpportunitydetails = (singleOpportunity) => {
    let opportunity = this.state.opportunity;
    let opportunity_details = singleOpportunity;
    if (Object.keys(opportunity_details).length > 0) {
      opportunity.opportunity_id =
        opportunity_details.opportunity.opportunity_id;
      opportunity.opportunity_name =
        opportunity_details.opportunity.opportunity_name;
      opportunity.opportunity_value =
        opportunity_details.opportunity.opportunity_value;
      opportunity.opportunity_currency =
        opportunity_details.opportunity.opportunity_currency.currency_id;
      opportunity.description = opportunity_details.opportunity.description;
      opportunity.stage = opportunity_details.opportunity.stage;
      opportunity.product =
        opportunity_details.opportunity?.product?.product_id;
      opportunity.owner =
        opportunity_details.opportunity.owner != null
          ? opportunity_details.opportunity.owner.user
          : "";
      opportunity.pipeline = opportunity_details.opportunity.pipeline;
      opportunity.expected_closed_date =
        opportunity_details.opportunity.expected_closed_date;

      let contact = null;
      let org = null;
      if (opportunity_details.opportunity.opportunity_contacts.length > 0) {
        contact =
          opportunity_details.opportunity.opportunity_contacts["0"]
            .contact_details !== null
            ? opportunity_details.opportunity.opportunity_contacts["0"]
                .contact_details
            : null;

        org =
          opportunity_details.opportunity.opportunity_contacts["0"]
            .contact_details !== null
            ? opportunity_details.opportunity.opportunity_contacts["0"]
                .contact_details.first_name
            : "";
      }
      let organization = this.state.organization;
      let organization_name = "";
      if (opportunity_details.opportunity.organization_details !== null) {
        organization.organization_id =
          opportunity_details.opportunity.organization_details.organization_id;
        organization.organization_name =
          opportunity_details.opportunity.organization_details.organization_name;
        organization_name =
          opportunity_details.opportunity.organization_details
            .organization_name;
      }

      if (contact !== null) {
        delete contact["organization_details"];
        delete contact["created_on"];
        delete contact["created_by"];
        delete contact["organization"];
      }

      this.setState({
        opportunity,
        org: org == null ? this.state.org : org,
        contact: contact == null ? this.state.contact : contact,
        organization,
        singleOpportunity: singleOpportunity,
        organization_name,
      });
    }
  };

  getContactList = (inputValue) => {
    this.props.getContactList(inputValue);
  };

  setContactListEmpty = () => {
    this.props.setContactListEmpty();
  };

  handleAutoCompleteReset = (type) => {
    this.setState({ contact: {}, contact_name: "" });
  };

  handleAutoCompletCompanyeReset = (type) => {
    this.setState({
      org: "",
      organization_name: "",
      company_autocomplete: true,
      organization: {},
    });
  };

  handleCompanyFocus = (e) => {
    this.setState({
      organization_name: e.target.value,
    });
  };

  setContact = (org, contact, organization) => {
    this.setState({
      contact,
      org: org,
      contact_name: org,
      ContactautoCompleteOpen: false,
      organization,
      organization_name: organization.organization_name,
    });
  };

  // handleContactFocus = (e) => {
  //     this.setState({ ContactautoCompleteOpen: true, contact_name: e.target.value });
  // }

  updateOpportunityPayload = () => {
    if (!this.validate()) {
      this.setState({ disabled: true, loading: true });
      let opportunity = this.state.opportunity;
      opportunity.opportunity_name = this.state.opportunity_name;
      // var number = parseInt(singleOpportunity.opportunity.opportunity_value ? singleOpportunity.opportunity.opportunity_value : 0);
      // var opp_value = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      opportunity.opportunity_value = this.state.opportunity_value.replace(
        /,/g,
        ""
      );
      opportunity.opportunity_currency = this.state.opportunity_currency;
      opportunity.description = this.state.description;
      opportunity.stage = this.state.stage;
      opportunity.product = this.state.product;
      opportunity.owner = this.state.owner;
      opportunity.pipeline = this.state.pipeline;
      opportunity.expected_closed_date = this.state.expected_closed_date;
      let org = this.state.org;
      let contact = this.state.contact;
      let organization = this.state.organization;

      let payload = {
        opportunity,
        org: org,
        contact,
        organization,
      };
      this.props.setSingleOpportunityEmpty();
      this.props.updateOpportunity(payload);
      // this.props.history.push('/opportunity/list');
      this.setState({ loading: false });
    }
  };

  onCloseIcon = () => {
    let organization = {};
    this.setState({ organization, organization_name: "" });
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.container}>
        <form autoComplete="on">
          <Grid container justify="flex-start" spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="opportunity_name"
                label="NAME"
                placeholder="Enter name"
                className={classes.textField}
                onChange={this.handleChange}
                value={this.state.opportunity_name}
                error={this.state.error.name !== ""}
                helperText={this.state.error.name}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="DESCRIPTION"
                placeholder="description"
                value={this.state.description}
                onChange={this.handleChange}
                required
                error={this.state.error.description.length > 0}
                helperText={this.state.error.description}
              />
            </Grid>
            <Grid item xs={6}>
              {
                // !this.state.ContactautoCompleteOpen ?
                //     <TextField
                //         label="Contact"
                //         placeholder="Contact"
                //         value={this.state.contact_name}
                //         onFocus={this.handleContactFocus}
                //         InputProps={{
                //             inputComponent: Input,
                //             endAdornment: this.state.contact_name ?
                //                 <IconButton size='small'>
                //                     <CloseIcon onClick={this.handleAutoCompleteReset.bind(this, "contact")} />
                //                 </IconButton> :
                //                 undefined
                //         }}
                //     /> :
                <ContactAutoComplete
                  label="Search Contact"
                  placeholder="Search Contact"
                  name="contact"
                  getContactList={this.getContactList}
                  setContactListEmpty={this.setContactListEmpty}
                  contactSearchList={this.props.contactSearchList}
                  loading={this.props.show_Loader}
                  reset={this.handleAutoCompleteReset}
                  setContact={this.setContact}
                  value={this.state.contact_name}
                  onCloseIcon={this.onCloseIcon}
                  helperText={this.state.error.contact}
                  error={this.state.error.contact.length > 0}
                />
              }
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                inputValue={this.state.organization_name}
                className={classes.autoTextField}
                options={this.state.company_search_array}
                getOptionLabel={(option) => option.organization_name}
                onChange={this._getSelectedCompanyDetails}
                openText={"open"}
                value={this.state.leads}
                filterSelectedOptions={true}
                autoSelect
                renderInput={(params) => (
                  // <div style={{ width: "100%" }}>
                  <TextField
                    margin="dense"
                    style={{ width: "100%" }}
                    {...params}
                    variant="standard"
                    label="COMPANY"
                    placeholder="Select Company"
                    // margin="normal"
                    fullWidth
                    aria-readonly
                    name="organization_name"
                    //error={errors.organization_name.length > 0}
                    value={this.state.organization_name}
                    // helperText={errors.organization_name}

                    onChange={this._handleCompanyChange}
                  />
                )}
              />
              {/* : */}
              {/* <TextField
                                label="COMPANY"
                                placeholder="Company"
                                value={this.state.organization_name}
                                onChange={this.handleCompanyFocus}
                                InputProps={{
                                    inputComponent: Input,
                                    readOnly: true,
                                    // endAdornment: this.state.organization_name?.length > 2 ?
                                    //     <IconButton size='small'>
                                    //         <CloseIcon onClick={this.handleAutoCompletCompanyeReset.bind(this, "company")} />
                                    //     </IconButton> :
                                    //     undefined
                                }}
                            /> */}
              {/* } */}
            </Grid>
            <Grid item xs={12} lg={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  name="expected_closed_date"
                  id="expected-closed-date"
                  label="Expected Closed Date"
                  onChange={this.onChangedate}
                  format="dd/MM/yyyy"
                  required
                  error={this.state.error.expected_closing_date.length > 0}
                  helperText={this.state.error.expected_closing_date}
                  value={
                    this.state.expected_closed_date
                      ? this.state.expected_closed_date
                      : null
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                name="opportunity_value"
                label="VALUE"
                type="text"
                inputProps={{ min: "0" }}
                onChange={this.handleChange}
                placeholder="Value"
                error={this.state.error.value.length > 0}
                helperText={this.state.error.value}
                required
                value={this.state.opportunity_value}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                name="owner"
                label="OWNER"
                onChange={this.handleChange}
                select
                value={this.state.owner}
                required
                error={this.state.error.owner.length > 0}
                helperText={this.state.error.owner}
              >
                {this.props?.list_all_owner_users.map((option) =>
                  option.first_name ? (
                    <MenuItem key={option.user} value={option.user}>
                      {option.first_name +
                        " " +
                        option.last_name +
                        "(" +
                        option.designation.designation_name +
                        ")"}
                    </MenuItem>
                  ) : undefined
                )}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                name="pipeline"
                label="PIPELINE"
                required
                select
                onChange={this.handleChange}
                value={this.state.pipeline}
                error={this.state.error.pipeline.length > 0}
                helperText={this.state.error.pipeline}
              >
                {this.state.pipeline_list.map((option) => (
                  <MenuItem key={option.pipeline_id} value={option.pipeline_id}>
                    {option.pipeline_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                name="stage"
                label="STAGE"
                onChange={this.handleChange}
                required
                select
                value={this.state.stage}
                error={this.state.error.stage.length > 0}
                helperText={this.state.error.stage}
              >
                {this.state.pipeline_stage_list.map((option) => (
                  <MenuItem key={option.stage_id} value={option.stage_id}>
                    {option.stage_name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              <TextField
                name="product"
                label="PRODUCT / SURVICE"
                onChange={this.handleChange}
                required
                select
                value={this.state.product}
                error={this.state.error.product_service.length > 0}
                helperText={this.state.error.product_service}
              >
                {this.state.company_products_list &&
                  this.state.company_products_list.map((option) => (
                    <MenuItem key={option.product_id} value={option.product_id}>
                      {option.product_name}
                    </MenuItem>
                  ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={4}>
              {this.state.singleOpportunity.opportunity &&
              this.state.singleOpportunity.opportunity.opportunity_id ? (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.updateOpportunityPayload}
                >
                  UPDATE
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={this.postOpportunityPayload}
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
        <SpinnerAtomBall show={this.state.loading} />
      </Paper>
    );
  }
}
export default withRouter(withStyles(styles)(OpportunityForm));
