import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';


function CreateExpenseIcon(props) {
    return (
                    <SvgIcon {...props} viewBox='0 0 20 10'>
            <path d="M82.5,14.7H19.5c-6.5,0-11.7,5.3-11.7,11.7v45.2c0,6.5,5.3,11.7,11.7,11.7h63.1c6.5,0,11.7-5.3,11.7-11.7V26.4  C94.2,19.9,89,14.7,82.5,14.7z M63.3,52.6v-7.1c0-4.1,3.2-7.3,7.3-7.3h19.2v21.8H70.6C66.5,59.9,63.3,56.7,63.3,52.6z M58.9,45.4  v7.1c0,6.5,5.3,11.7,11.7,11.7h19.2v7.3c0,4.1-3.2,7.3-7.3,7.3H19.5c-4.1,0-7.3-3.2-7.3-7.3V26.4c0-4.1,3.2-7.3,7.3-7.3h63.1  c4.1,0,7.3,3.2,7.3,7.3v7.3H70.6C64.2,33.7,58.9,39,58.9,45.4z" />
        </SvgIcon>
    );
}

export default CreateExpenseIcon;