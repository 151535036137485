import { act } from "react-dom/test-utils";

const initialState = {
  dashboard_opportinuity_all_Data: null,
  dashboard_leads_data: null,
  dashboard_expense_data: null,
  dashboard_sales_target_won_data: null,
  dashboard_sales_lost_data: null,
  //sales dashboard states....
  sales_dashboard_target_won_data: null,
  dashboard_activites_data: null,
  notifications: [],
}
// dashboard store reducer
const dashboardStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_DASHBOARD_OPPORTUNITY_V1_ALL_RESPONSE':
      return {
        ...state,
        dashboard_opportinuity_all_Data: action.response,
      }

    case 'SET_LEADS_RESPONSE':
      return {
        ...state,
        dashboard_leads_data: action.response,
      }

    case 'SET_EXPENSE_RESPONSE':
      return {
        ...state,
        dashboard_expense_data: action.response,
      }

    case 'SET_PIPELINE_DASHBOARD_SALES_TARGET_RESPONSE':
      return {
        ...state,
        dashboard_sales_target_won_data: action.response,
      }

    case 'SET_PIPELINE_DASHBOARD_SALES_LOST_RESPONSE':
      return {
        ...state,
        dashboard_sales_lost_data: action.response,
      }

    case 'SET_DASHBOARD_PIPELINE_EMPTY_RESPONSE':
      return {
        dashboard_opportinuity_all_Data: [],
        dashboard_leads_data: [],
        dashboard_expense_data: [],
        dashboard_sales_target_won_data: [],
        dashboard_sales_lost_data: [],
      }

    case 'SET_DASHBOARD_EMPTY_RESPONSE':
      return {
        ...state,
        dashboard_finance_report_data: [],
        dashboard_sales_target_won_data: [],
        dashboard_sales_lost_data: [],
      }

    //..... leads dashboard ....//
    case 'SET_SALES_TARGET_RESPONSE':
      return {
        ...state,
        sales_dashboard_target_won_data: action.response
      }

    case 'SET_SALES_LOST_RESPONSE':
      return {
        ...state,
        sales_dashboard_lost_data: action.response
      }

    case 'SET_SALES_STAGE_LIST_RESPONSE':
      return {
        ...state,
        sales_dashboard_stages_list_data: action.response
      }

    case 'SET_SALES_STAGE_ITEMS_LIST_RESPONSE':
      return {
        ...state,
        sales_dashboard_stages_item_list_data: action.response
      }
    case 'SET_SALES_EMPTY_RESPONSE':
      return {
        ...state,
        sales_dashboard_target_won_data: [],
        sales_dashboard_lost_data: [],
        sales_dashboard_stages_list_data: [],
        sales_dashboard_stages_item_list_data: [],
      }


    case 'SET_DASHBOARD_ACTIVITIES_RESPONSE':

      return {
        ...state,
        dashboard_activites_data: action.response
      }

    case 'SET_DASHBOARD_ACTIVITIES_RESPONSE_EMPTY':
      return {
        ...state,
        dashboard_activites_data: null
      }

    //finance dashboard
    case 'SET_DASHBOARD_FINANCE_REPORT_RESPONSE':
      return {
        ...state,
        dashboard_finance_report_data: action.response
      }

    case 'SET_ATOM_BALL_SPINNER_SHOW_SALES_DASHBOARD':
      return {
        ...state,
        atom_ball_spinner_sales: true
      }

    case 'SET_ATOM_BALL_SPINNER_HIDE_SALES_DASHBOARD':
      return {
        ...state,
        atom_ball_spinner_sales: false
      }

    case 'SET_EXPENSE_DASHBOARD_RESPONSE':
      return {
        ...state,
        expenseDashboardData: action.response
      }

    case 'SET_NOTIFICATIONS_RESPONSE':
      return {
        ...state,
        notifications: action.response
      }

    default:
      return state;
  }
};

export default dashboardStore;
