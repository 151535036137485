import React from "react";
import { withStyles } from "@material-ui/core/styles";
import ExpensesIcon from "../../components/icons/expenses";
import ContactIcon from "../../components/icons/contact";
import LeadsIcon from "../../components/icons/leads";
import OpportunityIcon from "../../components/icons/opportunity";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { Grid, Popper, Paper, IconButton } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Popover from "@material-ui/core/Popover";

const styles = (theme) => ({
  root: {
    position: "fixed",
    bottom: 10,
    right: 10,
  },
  chip: {
    marginBottom: 10,
    fontSize: 16,
    borderRadius: 15,
    padding: 8,
    width: "fit-content",
    transition: "width 2s",
    "&:hover": {
      background: "#efefef",
      // width:250
    },
  },
});

const actions = [
  // { icon: <FileCopyIcon />, name: "Add Opportunity" },
  { icon: <ExpensesIcon />, name: "Add Expense" },
  { icon: <ContactIcon />, name: "Add Contact" },
  { icon: <LeadsIcon />, name: "Add Lead" },
  { icon: <OpportunityIcon />, name: "Add Opportunity" },
];

class BottomEditIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flag: false,
      anchorEl: "",
      open: false,
      handelOpen:false
    };
  }

  handleClose = (e) => {
    this.setState({
      anchorEl: "",
      open: false,
    });
  };

  handleChip = (name, e) => {
    if (name == "Add Expense") {
      this.props.history.push({
        pathname: "/expense/create",
      });
    } else if (name == "Add Contact") {
      this.props.history.push({
        pathname: "/contact/o/create",
      });
    } else if (name == "Add Lead") {
      this.props.history.push({
        pathname: "/lead/create",
      });
    } else if (name == "Add Opportunity") {
      this.props.history.push({
        pathname: "/opportunity/create",
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Popover
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {actions.map((item, i) => (
            <Paper
              className={classes.chip}
              onClick={this.handleChip.bind(this, item.name)}
            >
              {item.name}
              <IconButton size="small">{item.icon}</IconButton>
            </Paper>
          ))}
        </Popover>
        <Fab
          color="primary"
          aria-label="add"
          onClick={(event) => {
            this.setState({
              flag: !this.state.flag,
              anchorEl: event.currentTarget,
              open: !this.state.handelOpen,
            });
          }}
        >
          {this.state.flag ? <CloseIcon /> : <AddIcon />}
        </Fab>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(BottomEditIcon));
