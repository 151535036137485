import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function ExportPdf(props) {
    return (
        <SvgIcon {...props} style={{width:"18", height:"22", marginRight:'10px'}} viewBox='0 0 18 22'>
             <g fill="none" fill-rule="evenodd">
                <path fill="#000" fill-rule="nonzero" d="M15.374 0H2.626C1.268 0 .166 1.131.166 2.527v16.86c0 1.395 1.102 2.526 2.46 2.526h12.747c1.359 0 2.46-1.131 2.46-2.526V2.527C17.833 1.13 16.732 0 15.373 0zM16.5 19.387c0 .637-.505 1.156-1.126 1.156H2.626c-.62 0-1.126-.519-1.126-1.156V2.527c0-.638.505-1.157 1.126-1.157h12.747c.622 0 1.127.519 1.127 1.157v16.86z"/>
                <path fill="#000" fill-rule="nonzero" d="M3.333 13.25h11.333v1.37H3.333zM3.333 15.271h11.333v1.37H3.333zM3.333 17.292h11.333v1.37H3.333z"/>
                <text fill="#282828" font-family="Poppins" font-size="6" font-weight="bold">
                    <tspan x="3.144" y="9">PDF</tspan>
                </text>
            </g>
        </SvgIcon>
    );
}

export default ExportPdf;