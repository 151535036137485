import appConfig from './apiConfig';
import apiToken from '../api/token';
import endpoints from './endpoints';

// let api_token = apiToken();
// const token = api_token.accessToken;

export const getAllOwnerUsersApi = () => {
    let api_token = apiToken(); 
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_ALL_USER, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });
    
    return  fetch(request).then(response => {       
        return  response;
        }).catch(error => {
        return error;});
}

export const getAllContactOrganizationApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.ORGANIZATION_ALL, {
        method: "GET",
        crossDomain: true,
        headers: {
            // 'Content-Type': 'application/json',
            Authorization: token
        }
    });

    return fetch(request).then(response => {
        return response.json().then(organizations => {
            return organizations;
        });
    }).catch(error => {
        return error;
    });
}

export const getCurrentUserApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_PROFILE, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
            // Authorization: appConfig.getToken(),
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getCountryListApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_COUNTRY_LIST, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token
            // Authorization: appConfig.getToken(),
        }
    });

    return fetch(request).then(response => {
        return response.json().then(countryListResponse => {
            return countryListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getCompanySearchApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.ORGANIZATION_SEARCH_API + "?search_query=" + data, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(currencyListResponse => {
            return currencyListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getContactSearchApi = (data) => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.contact_filter + data, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(contactListResponse => {
            return contactListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getPipeLineListApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_PIPELINE, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

export const getCourrencyListApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.CURRENCY_GET, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(currencyListResponse => {
            return currencyListResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getCompanyProductsApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_ALL_PRODUCT, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response.json().then(companyProductsResponse => {
            return companyProductsResponse;
        });
    }).catch(error => {
        return error;
    });
}

export const getUserProfileApi = () => {
    let api_token = apiToken();
    const token = api_token.accessToken;
    const headers = Object.assign({ 'Content-Type': 'application/json' });
    const request = new Request(endpoints.GET_USER_PROFILE, {
        method: "GET",
        crossDomain: true,
        headers: {
            'Content-Type': 'application/json',
            Authorization: token,
        }
    });

    return fetch(request).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}