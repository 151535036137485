import React, { PureComponent } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import equal from 'fast-deep-equal';

const styles = theme => ({

});

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            open: false,
            value: '',
            contact_search_array: this.props.contactSearchList === null || Object.keys(this.props.contactSearchList).length == 0 ?
                [] : this.props.contactSearchList,
            contact_name: props.value,
        }
    }


    componentDidUpdate(prevProps) {
        if (!equal(this.props.contactSearchList, prevProps.contactSearchList)) {
            this.setState({ contact_search_array: this.props.contactSearchList });
        }
        if (!equal(this.props.loading, prevProps.loading)) {
            this.setState({ loading: this.props.loading });
        }
        if (!equal(this.props.value, prevProps.value) && this.props.value) {
            let first_name = this.props.value.split(" ")[0];
            this.props.getContactList(first_name);
            this.setState({ contact_name: this.props.value });
        }
    }

    componentWillMount() {
        if (this.props.value) {
            let first_name = this.props.value.split(" ")[0];
            this.props.getContactList(first_name);
            this.setState({ contact_name: this.props.value });
        }
    }

    _handleContactChange = (e) => {
        const val = e.target.value;
        this.setState({ contact_name: val });

        if (val) {
            // this.setState({ contact_name: val });
            // this.props.getContactList(val);
        }
        // clearTimeout(this.typingTimer);
        // this.typingTimer = setTimeout(() => {

        // }, 500);

        
        let contact = {};
        let organization = {};
        let org = val;
        if(val.split(' ').length>1)
        {
            contact.first_name = val.split(' ')[0];
            let i= val.indexOf(' ');
            contact.last_name = val.substring(i, val.length);
        }else{
            contact.first_name = val;
            contact.last_name = "";
        }
        this.setState({ org: org, contact, contact_name: val, open: false });
        this.props.setContact(org, contact, organization);    

    }

    _getSelectedContactDetails = (event, value) => {
        if (value !== null) {
            let contact = {};
            let organization = {};
            let org = ''
            org = value.first_name + ' ' + value.last_name;
            contact=value;
            // contact.contact_id = value.contact_id ? value.contact_id : null;
            // contact.first_name = value.first_name ? value.first_name : null;
            // contact.last_name = value.last_name ? value.last_name : null;
            // contact.value = value.value ? value.value : null;
            // contact.product = value.product ? value.product : null;
            // contact.lead_id = value.lead_id ? value.lead_id : null;
            // contact.official_email = value.official_email ? value.official_email : null;
            // contact.website = value.website ? value.website : null;
            // contact.official_phone = value.official_phone ? value.official_phone : null;
            // contact.mobile_no = value.mobile_no ? value.mobile_no : null;
            // contact.address_line1 = value.address_line1 ? value.address_line1 : null;
            // contact.address_line2 = value.address_line2 ? value.address_line2 : null;
            // contact.address_city = value.address_city ? value.address_city : null;
            // contact.address_state = value.address_state ? value.address_state : null;
            // contact.address_country = value.address_country ? value.address_country : null;
            // contact.address_zip = value.address_zip ? value.address_zip : null;
            // contact.linkedin_url = value.linkedin_url ? value.linkedin_url : null;
            // contact.facebook_url = value.facebook_url ? value.facebook_url : null;
            // contact.twitter_handle = value.twitter_handle ? value.twitter_handle : null;
            if (value.organization_details != null && value.organization_details != undefined) {
                organization.organization_id = value.organization_details.organization_id;
                organization.organization_name = value.organization_details.organization_name;
            }
            this.setState({ org: org, contact, contact_name: value.first_name + " " + value.last_name, open: false });
            this.props.setContact(org, contact, organization);
        }
    }

    handleCloseIcon = () => {
        this.props.onCloseIcon();
        this.setState({ contact_search_array: [], open: false, org: '', contact_name: '' });
        this.props.reset('contact');
    }

    render() {
        const { classes } = this.props;
        console.log(this.state.contact_name);
        return (
            <div className={classes.Paper}>
                    <Autocomplete
                        inputValue={this.state.contact_name}
                        loading={this.state.loading}
                        options={this.state.contact_search_array}
                        getOptionLabel={option => option.first_name + ' ' + option.last_name}
                        onChange={this._getSelectedContactDetails}
                        closeIcon={
                            <CloseIcon onClick={this.handleCloseIcon} />
                        }
                        renderInput={params => (
                            <TextField
                                margin="dense"
                                style={{ width: "100%" }}
                                {...params}
                                label={this.props.label}
                                placeholder={this.props.label}
                                margin="normal"
                                fullWidth
                                required
                                value={this.state.contact_name}
                                name={this.props.label}
                                // onBlur={this._handleContactChange}
                                onChange={this._handleContactChange}
                                error={this.props.error}
                                helperText={this.props.helperText}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: "off",
                                }}
                            />
                        )}
                    />
                    {
                        this.state.loading && this.state.value ?
                            <LinearProgress /> :
                            undefined
                    }
            </div>
        )
    }
}

export default withStyles(styles)(Contact);