import { act } from "react-dom/test-utils";

const initialState = {
    atom_ball_spinner: false
}

const loaderStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_OWNER_USER_LIST_RESPONSE':
      return {
        ...state,
        atom_ball_spinner: action.response,
      }

    default:
      return state;
  }
};

export default loaderStore;
