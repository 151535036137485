import React, { PureComponent } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import equal from 'fast-deep-equal';

const styles = theme => ({

});

class Organization extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: '',
            loading: false,
            open: false,
            company_search_array: [],
            select_company_name: '',
            organization: {
                organization_id: '',
                organization_name: '',
            },
        }
    }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.companySearchList, prevProps.companySearchList)) {
            this.setState({ company_search_array: this.props.companySearchList });
        }
        if (!equal(this.props.loading, prevProps.loading)) {
            this.setState({ loading: this.props.loading });
        }
    }

    _handleCompanyChange = (e) => {
        const val = e.target.value;
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
            if (val) {
                this.setState({ value: val });
                this.props.getCompanyList(val);
            } else {
                this.setState({ company_search_array: [] });
                this.props.setCompanyListEmpty()
            }
        }, 500);
    }

    _getSelectedCompanyDetails = (event, value) => {
        if (value !== null) {
            const organization = this.state.organization;
            organization.organization_id = value.organization_id;
            organization.organization_name = value.organization_name;
            let select_company_name = value.organization_name;
            this.setState({ organization, select_company_name: select_company_name });
            this.props.setCompany(organization, select_company_name)
        }
    }


    handleCloseIcon = () => {
        this.setState({ company_search_array: [] });
        this.props.reset('organization');
    }

    render() {

        const { classes } = this.props;
        return (
            <Paper elevation={3} className={classes.Paper}>
                <Autocomplete
                    className={classes.autoTextField}
                    options={this.state.company_search_array}
                    getOptionLabel={option => option.organization_name}
                    onChange={this._getSelectedCompanyDetails}
                    closeIcon={
                        <CloseIcon onClick={this.handleCloseIcon} />
                    }
                    open={this.state.value}
                    renderInput={params => (
                        <TextField
                            margin="dense"
                            style={{ width: "100%" }}
                            {...params}
                            variant="standard"
                            label={this.props.label}
                            placeholder={this.props.placeholder}
                            margin="normal"
                            fullWidth
                            name={this.props.name}
                            onChange={this._handleCompanyChange}
                        />
                    )}
                />
                {
                    this.state.loading && this.state.value ?
                        <LinearProgress /> :
                        undefined
                }

            </Paper>
        )
    }
}

export default withStyles(styles)(Organization);