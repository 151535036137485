import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function AccountsIcon(props) {
    return (
    <SvgIcon  {...props} version="1.1" x="-500px" y="0px" viewBox="0 0 100 93.75" paddingleft='-200px' >
      <g><g><path d="M54.5,61.4814453h-34v-48h34V61.4814453z M22.5,59.4814453h30v-44h-30V59.4814453z"/></g>
      <g><g><path d="M33.3500977,29.394043h-4c-0.5522461,0-1-0.4477539-1-1v-8c0-0.5522461,0.4477539-1,1-1h4c0.5522461,0,1,0.4477539,1,1v8     C34.3500977,28.9462891,33.9023438,29.394043,33.3500977,29.394043z M30.3500977,27.394043h2v-6h-2V27.394043z"/></g>
      <g><path d="M45.6499023,29.394043h-4c-0.5522461,0-1-0.4477539-1-1v-8c0-0.5522461,0.4477539-1,1-1h4c0.5522461,0,1,0.4477539,1,1v8     C46.6499023,28.9462891,46.2021484,29.394043,45.6499023,29.394043z M42.6499023,27.394043h2v-6h-2V27.394043z"/></g>
      <g><path d="M33.3500977,45.1108398h-4c-0.5522461,0-1-0.4477539-1-1v-8c0-0.5522461,0.4477539-1,1-1h4c0.5522461,0,1,0.4477539,1,1     v8C34.3500977,44.6630859,33.9023438,45.1108398,33.3500977,45.1108398z M30.3500977,43.1108398h2v-6h-2V43.1108398z"/></g>
      <g><path d="M45.6499023,45.1108398h-4c-0.5522461,0-1-0.4477539-1-1v-8c0-0.5522461,0.4477539-1,1-1h4c0.5522461,0,1,0.4477539,1,1     v8C46.6499023,44.6630859,46.2021484,45.1108398,45.6499023,45.1108398z M42.6499023,43.1108398h2v-6h-2V43.1108398z"/></g></g>
      <g><path d="M42.1147461,61.5185547h-9.2294922v-6.7763672c0-1.9326172,1.5722656-3.5048828,3.5043945-3.5048828h2.2207031    c1.9321289,0,3.5043945,1.5722656,3.5043945,3.5048828V61.5185547z M34.8852539,59.5185547h5.2294922v-4.7763672    c0-0.8295898-0.6748047-1.5048828-1.5043945-1.5048828h-2.2207031c-0.8295898,0-1.5043945,0.675293-1.5043945,1.5048828    V59.5185547z"/></g></g>
      <text x="0" y="90" fill="#000000" fontSize="5px" fontWeight="bold" fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text><text x="0" y="95" fill="#000000" fontSize="5px" fontWeight="bold" fontFamily="'Helvetica Neue', Helvetica, Arial-Unicode, Arial, Sans-serif"></text>
      </SvgIcon>
    );
}

export default AccountsIcon;