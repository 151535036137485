import React, { Component } from 'react';
import { Container, IconButton, Button, Typography, Box } from '@material-ui/core';
import Icons from '../icons';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '../icons/arrowBack';
import withStyles from '@material-ui/core/styles/withStyles';
import equal from 'fast-deep-equal';
import FilterIcon from "../../assets/images/filter.svg";

const style = theme => ({
    root: {
        flexGrow:1,
        width: '100%',
        
    },
    text: {
        letterSpacing: '0.05em',
        fontSize: '14px',
        color:'color: #202020;',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontFamily: 'Poppins, sans-serif'
    },
    filter: {
        flexDirection: "row-reverse"
    },
});

class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                user: 0,
                status: 'lead',
                from: '',
                to: '',
                pipeline: 0
            },
            pipeline_name: '',
            status: "",
            user_name: "",
            from_to_date: "",
            pipeline_list: "",
            all_user: "",
            create_expense: this.props.create_expense
        };
    }

    componentWillMount() {
        if (this.props.filter_data != {} && this.props.filter_data !== null) {
            this.setState({ filter: this.props.filter_data });
        }

        if (this.props.all_user && this.props.all_user !== null) {
            this.setState({ all_user: this.props.all_user });
        }

        if (this.props.pipeline_list != {} && this.props.pipeline_list !== null) {
            this.setState({ pipeline_list: this.props.pipeline_list });
        }

        if (this.props.filterChip) {
        }
    }

    // componentDidMount() {
    //     this.getFilterFormData();
    // }

    componentDidUpdate(prevProps) {
        if (!equal(this.props.filter_data, prevProps.filter_data) && this.props.filter_data != {} && this.props.filter_data !== null) {
            this.setState({ filter: this.props.filter_data });
            this.getFilterFormData();
        }

        if (!equal(this.props.all_user, prevProps.all_user) && this.props.all_user && this.props.all_user !== null) {
            this.setState({ all_user: this.props.all_user });
        }

        if (!equal(this.props.pipeline_list, prevProps.pipeline_list) && this.props.pipeline_list != {} && this.props.pipeline_list !== null) {
            this.setState({ pipeline_list: this.props.pipeline_list });
        }

        if (!equal(this.props.filterChip, prevProps.filterChip) && this.props.filterChip) {

        }
    }

    openFilter = (e) => {
        this.props.handleFilterPopup(true, e.currentTarget);
    }

    getFilterFormData = (data) => {
        if (data.user && data.user !== null && data.user !== undefined) {
            this.props.list_all_owner_users.filter(users => {
                if (users.user == data.user) {
                    this.setState({
                        user_name: users.first_name + " " + users.last_name,
                    });
                }
            })
        }
        if (data.pipeline && data.pipeline !== null && data.pipeline !== undefined) {
            this.props.pipeline_list.filter(pipelines => {
                if (pipelines.pipeline_id == data.pipeline) {
                    this.setState({
                        pipeline_name: pipelines.pipeline_name,
                    });
                }
            })
        }
        if ((data.from && data.from !== null && data.from !== undefined) && (data.to && data.to !== null && data.to !== undefined)) {
            this.setState({
                from_to_date: data.from + '-' + data.to,
            });
        }
        else if (data.from && data.from !== null && data.from !== undefined) {
            this.setState({
                from_to_date: data.from,
            });
        }

        else if (data.to && data.to !== null && data.to !== undefined) {
            this.setState({
                from_to_date: data.to,
            });
        }

        this.setState({ filter: data })
        this.props.filter(data);
    }



    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Box display="flex" pt={1}>
                    <Box flexGrow={1}>
                        <IconButton color='primary' disableFocusRipple={true} size='small' onClick={this.props.goBack} className="leadarrow">
                            <ArrowBackIcon />
                        </IconButton>
                        <span className={classes.text}>
                            {this.props.headerText}
                        </span>
                    </Box>
                    {
                        this.props.is_filter ?
                            <Box flexGrow={0}>
                                <Button style={{ padding: '0px 0px 0px 15px' }} size="small" color='primary' onClick={this.props.handleFilterOpen}>
                                <img src={FilterIcon} style={{ width: "23px" }} />
                                <Typography variant='subtitle2' style={{ fontSize: '12px', marginLeft: "5px",color:'#202020',
                                fontStyle: 'normal',
                                fontStretch: 'normal',
                                lineHeight: 'normal',
                                fontWeight:'700',
                                letterSpacing: '1px',
                                textTransform: 'uppercase', }}>
                                Filter</Typography>
                                </Button>
                            </Box> : ''
                    }
                    {
                        this.props.create_expense ?
                            <Box flexGrow={0} className="expenseBtn">
                                <Button variant="contained" color="primary" size="small" onClick={this.props.handleExpenseHeadOpen}>Create Expense</Button>
                            </Box> : ''
                    }
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(PageHeader);