import { act } from "react-dom/test-utils";

const initialState = {
  create_opportunity_response: null,
  create_opportunity_success: false,
  create_opportunity_error: false,

  update_opportunity_response: null,
  update_opportunity_success: false,
  // update_opportunity_error: false,

  single_opportunity_response: null,
  single_opportunity_success: false,
  single_opportunity_error: false,
  list_all_owner_users: [],
}
// dashboard store reducer
const oppertunityStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_OWNER_USER_LIST_RESPONSE':
      return {
        ...state,
        list_all_owner_users: action.response,
      }

    case 'SET_PIPELINE_STAGE_LIST_RESPONSE':
      return {
        ...state,
        pipeline_stage_list: action.response,
      }

    case 'SET_PIPELINE_STAGE_LIST_EMPTY':
      return {
        ...state,
        pipeline_stage_list: []
      }

    case 'SET_CREATE_OPPORTUNITY_SUCCESS_RES':
      return {
        ...state,
        create_opportunity_response: action.response,
        create_opportunity_success: true,
        create_opportunity_error: false,
      }

    case 'SET_CREATE_OPPORTUNITY_FAIL_RES':
      return {
        ...state,
        create_opportunity_response: action.response,
        create_opportunity_success: false,
        create_opportunity_error: true,
      }

    case 'SET_CREATE_OPPORTUNITY_EMPTY_RES':
      return {
        ...state,
        create_opportunity_response: [],
        create_opportunity_success: false,
        create_opportunity_error: false,
      }

    case 'SET_SINGLE_OPPORTUNITY_SUCCESS_RES':
      return {
        ...state,
        single_opportunity_response: action.response,
        single_opportunity_success: true,
        single_opportunity_error: false,
      }

    case 'SET_SINGLE_OPPORTUNITY_FAIL_RES':
      return {
        ...state,
        single_opportunity_response: action.response,
        single_opportunity_success: false,
        single_opportunity_error: true,
      }

    case 'SET_SINGLE_OPPORTUNITY_EMPTY_RES':
      return {
        ...state,
        single_opportunity_response: "",
        single_opportunity_success: false,
        single_opportunity_error: false,
      }

    case 'SET_UPDATE_OPPORTUNITY_SUCCESS_RES':
      return {
        ...state,
        update_opportunity_response: action.response,
        update_opportunity_success: true,
      }

    case 'SET_UPDATE_OPPORTUNITY_FAIL_RES':
      return {
        ...state,
        update_opportunity_response: action.response,
        update_opportunity_success: false,
      }

    case 'SET_UPDATE_OPPORTUNITY_EMPTY_RES':
      return {
        ...state,
        update_opportunity_response: {},
        update_opportunity_success: false,
      }
    default:
      return state;
  }
};

export default oppertunityStore;
