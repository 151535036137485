import { act } from "react-dom/test-utils";

const initialState = {
  dashboard_opportinuity_all_Data: null,
  dashboard_leads_data: null,
  dashboard_expense_data: null,

  companySearchList: [],
  contactSearchList: [],

  create_leads_response: null,
  create_leads_success: false,
  create_leads_error: false,

  get_lead_response: null,
  get_lead_success: false,
  get_lead_error: false,

  update_lead_response: null,
  update_lead_success: false,
  update_lead_error: false,

  get_lead_activities: [],
  get_lead_activities_success: false,
  get_lead_activities_error: false,
  get_lead: null,

  lead_notes: [],
  lead_files:[],
}
// leads store reducer
const leadsStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_PRODUCT_LIST_RES':
      return {
        ...state,
        product_list: action.response,
      }

    case 'SET_COMPANY_SEARCH_LIST_EMPTY':
      return {
        ...state,
        companySearchList: []
      }

    case 'SET_CONTACT_SEARCH_LIST_EMPTY':
      return {
        ...state,
        contactSearchList: []
      }

    case 'SET_CREATE_LEADS_SUCCESS_RES':
      return {
        ...state,
        create_leads_success: true,
        create_leads_error: false,
        create_leads_response: action.response,
      }

    case 'SET_CREATE_LEADS_FAIL_RES':
      return {
        ...state,
        create_leads_success: false,
        create_leads_error: true,
        create_leads_response: action.response,
      }

    case 'SET_CREATE_LEADS_EMPTY_RES':
      return {
        ...state,
        create_leads_success: false,
        create_leads_error: false,
        create_leads_response: '',
      }

    case 'SET_GET_LEAD_SUCCESS_RES':
      return {
        ...state,
        get_lead_response: action.response,
        get_lead_success: true,
        get_lead_error: false,
      }

    case 'SET_GET_LEAD_FAIL_RES':
      return {
        ...state,
        get_lead_response: action.response,
        get_lead_success: false,
        get_lead_error: true,
      }

    case 'SET_GET_LEAD_EMPTY_RES':
      return {
        ...state,
        get_lead_response: null,
        get_lead_success: false,
        get_lead_error: false,
      }

    case 'SET_UPDATE_LEAD_SUCCESS_RES':
      return {
        ...state,
        update_lead_response: action.response,
        create_leads_response: action.response,
        update_lead_success: true,
        update_lead_error: false,
      }

    case 'SET_UPDATE_LEAD_FAIL_RES':
      return {
        ...state,
        update_lead_response: action.response,
        create_leads_response: action.response,
        update_lead_success: false,
        update_lead_error: true,
      }

    case 'SET_UPDATE_LEAD_EMPTY_RES':
      return {
        ...state,
        update_lead_response: null,
        create_leads_response: null,
        update_lead_success: false,
        update_lead_error: false,
      }

    case 'SET_GET_LEAD_ACTIVITY_SUCCESS_RES':
      return {
        ...state,
        lead_upcomming_activities: action.response.upcomming_activities,
        lead_past_activities: action.response.past_activities,
        lead_next_activity: action.response.next_activity,
        lead_all_activities: action.response.all_activities,
        get_lead: action.response.lead,
        get_lead_success: true,
        get_lead_error: false,
      }

    case 'SET_GET_LEAD_NOTES_SUCCESS_RES':
      return {
        ...state,
        lead_notes: action.response,
        get_lead_notes_success: true,
        get_lead_notes_error: false,
      }

    case 'SET_GET_LEAD_FAIL_RES':
      return {
        ...state,
        lead_upcomming_activities: action.response.upcomming_activities,
        lead_past_activities: action.response.past_activities,
        lead_next_activity: action.response.next_activity,
        get_lead: action.response.lead,
        get_lead_success: false,
        get_lead_error: true,
      }

    case 'SET_GET_LEAD_EMPTY_RES':
      return {
        ...state,
        lead_upcomming_activities: [],
        lead_past_activities: [],
        lead_next_activity: null,
        get_lead: null,
        get_lead_success: false,
        get_lead_error: false,
      }

    case 'SET_GET_LEAD_FILES_SUCCESS_RES':
      return {
        ...state,
        lead_files: action.response,
      }

    default:
      return state;
  }
};

export default leadsStore;
