import React from "react";
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import endpoints from "../../api/endpoints";
import apiToken from "../../api/token";
// import setOpen from '../../components/dialogbox/dialogbox'
import LeadRemoveDialog from '../dialogbox/dialogbox';
import { deleteContact } from '../../actions/contactAction';
import { connect } from 'react-redux';

// let api_token = apiToken();
// const token = api_token.accessToken;

let loading = false;
class ActionsPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            actionPopup: false,
            anchorEl: "",
            rowData: [],
            listItem: [],
            user: props.user,
            pipeline_list: props.pipeline_list,
            loading: "false",
        }
    }

    componentDidMount() {
        let { actionsPopup, anchorEl, rowData, listItem } = this.state;
        if (this.props.open) {
            actionsPopup = this.props.open;
        }
        if (this.props.anchorEl) {
            anchorEl = this.props.anchorEl;
        }
        if (this.props.rowData) {
            rowData = this.props.rowData;
        }
        if (this.props.listItem) {
            if (rowData && rowData.stage) {
                if (rowData.stage.toLowerCase() === 'contact') {
                    listItem = this.props.listItem;
                    // listItem.pop('Convert to Contact')
                    listItem = listItem.filter(item => item !== 'Convert to Contact')
                } else { 
                    listItem = this.props.listItem;
                }
            } else {
                listItem = this.props.listItem;
            }
        }

        this.setState({
            actionPopup: actionsPopup,
            anchorEl: anchorEl,
            rowData: rowData,
            listItem: listItem
        });
    }

    closeFilter = () => {
        this.setState({ anchorEl: "", actionPopup: false });
        this.props.onDelete();

    }

    handleList = (listData) => {

        if (listData.toLowerCase() == "edit lead") {
            this.closeFilter()
            this.props.actionPopup();
            this.props.setEmptyGetLead();
            this.props.history.push({
                pathname: "/lead/edit",
                state: { lead_datials: this.state.rowData }
            });
        }

        if (listData.toLowerCase() == "remove lead") {
            // this.closeFilter()
            var id = this.state.rowData.lead_id;
            this.setState({ open: true });
        }

        if (listData.toLowerCase() == "convert to contact") {
            this.closeFilter()
            this.props.actionPopup();
            this.convertLeadToContact();
        }

        if (listData.toLowerCase() == "convert to opportunity") {
            this.closeFilter()
            this.props.actionPopup();
            this.props.openOpportunityDialog(true);
        }

        if (listData.toLowerCase() == "edit opportunity") {
            // this.closeFilter()
            this.props.history.push({
                pathname: "/opportunity/" + this.state.rowData.opportunity_id + "/details",
                state: {
                    datails: {
                        opportunity_id: this.state.rowData.opportunity_id,
                        headers_title: "Update Opportunity"
                    }
                }
            });
            this.closeFilter()
        }

        if (listData.toLowerCase() == "edit contact") {
            this.closeFilter()
            this.props.actionPopup();
            let id = this.state.rowData.contact_id;
            this.props.history.push({
                pathname: "/contact/edit/" + id,
                state: { contact_datials: this.state.rowData }
            });
        }

        if (listData.toLowerCase() == "remove contact") {
            // this.closeFilter()
            // var id = this.state.rowData.contact_id;
            // this.props.dispatch(deleteContact(id));
            // this.props.onDelete();
            // this.setState({ actionPopup: false })
            this.props.openContactRemoveConfirmation();
        }

        if (listData.toLowerCase() == "edit organization") {
            this.closeFilter()
            this.props.actionPopup();
            this.props.history.push({
                pathname: "/organization/edit/" + this.state.rowData.id,
                state: {
                    id: this.state.rowData.id,
                    header_text: "EDIT ORGANIZATION"
                }
            });
        }

        if (listData.toLowerCase() == "edit user") {
            this.closeFilter()
            this.props.userBox();
        }

        if (listData.toLowerCase() == "activate/deactivate user") {
            this.closeFilter()
            this.props.handleDeactivateUserOpen();
        }

        if (listData.toLowerCase() == "reset password") {
            this.closeFilter()
            this.props.handleResetPasswordOpen();
        }

        if (listData.toLowerCase() == "edit stage") {
            this.closeFilter()
            this.props.history.push({
                pathname: "/stage/" + this.state.rowData + "/edit",
                state: { id: this.state.rowData }
            })
        }



        if (listData.toLowerCase() == "remove stage") {
            this.closeFilter()
            this.props.handleStageRemoveBoxOpen();
        }

        if (listData.toLowerCase() == "edit product") {
            this.closeFilter()
            this.props.history.push({
                pathname: "/product/" + this.state.rowData + "/edit",
                state: { id: this.state.rowData }
            })
        }

        if (listData.toLowerCase() == "remove product") {
            this.closeFilter()
            this.props.handleProductRemoveBoxOpen();
        }

        if (listData.toLowerCase() == "edit pipeline") {
            this.props.editPipelineDialog()
        }

        if (listData.toLowerCase() == "remove pipeline") {
            var id = this.state.rowData.pipeline_id;
            let stages = this.state.rowData.stages;
            this.props.deletePipeline(id, stages);
        }

        if (listData.toLowerCase() == "edit job title") {
            var id = this.state.rowData;
            this.props.history.push({
                pathname: '/job-title/' + id + '/edit',
                state: { id: id }
            })
        }

        if (listData.toLowerCase() == "edit department") {
            var id = this.state.rowData;
            this.props.history.push({
                pathname: '/department/' + id + '/edit',
                state: { id: id }
            })
        }

        if (listData.toLowerCase() == "add opportunity") {
            this.props.openAddOpportunityDialogue();
        }
        if (listData.toLowerCase() == "update stage order") {
            this.props.updateStageOrderPopup();
        }
    }

    removeLead = (id) => {
        let api_token = apiToken();
        const token = api_token.accessToken;
        this.setState({ loading: "true" });
        var url = endpoints.lead_delete + id;
        axios.delete(
            url,
            {
                headers: {
                    Authorization: token,
                }
            }
        ).then(
            response => {
                if (response.status === 200) {
                    loading = false;
                    this.props.actionPopup();
                    this.props.snackbar(true, response.data.message);
                    this.props.onDelete()
                    this.setState({ open: false, actionPopup: false, loading: 'false' })
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    convertLeadToContact = () => {
        this.closeFilter();
        let api_token = apiToken();
        const token = api_token.accessToken;
        var url = endpoints.create_lead;
        var payload = this.state.rowData;
        var headers = {
            Authorization: token,
        }
        axios.post(
            url,
            payload,
            { headers }
        ).then(res => {
            if (res.status == 201) {
                this.props.history.push({
                    pathname: "/contact/all",
                    state: {
                        open: true,
                        message: "lead successfully converted to contact!",
                    }
                });
                this.props.loadData();
                this.props.snackbar(true, res.data.message);
            }
        }).catch(error => {
            console.log(error);
        });
    }

    callRemoveLead = () => {
        var id = this.state.rowData.lead_id;
        this.removeLead(id);
    }

    oncancel = () => {
        this.props.actionPopup();
    }

    render() {
        const { classes } = this.props;
        let listItem = this.state.listItem;
        return (
            <div>
                <Popover
                    open={this.state.actionPopup}
                    onClose={this.closeFilter}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}

                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List className="actionpopup">
                        {
                            listItem && listItem.map((list) =>
                                <ListItem button style={{ paddingBottom: '0% !important' }}>
                                    <ListItemText onClick={this.handleList.bind(this, list)}>
                                        <Typography variant="body2" color="black">
                                            <span style={{ color: "black", fontSize: "14px" }}>
                                                {list}
                                            </span>
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            )
                        }
                    </List>
                </Popover>
                {
                    this.state.open ?
                        <LeadRemoveDialog
                            open={this.state.open}
                            lead={this.state.rowData.lead_title}
                            leadRemove={this.callRemoveLead}
                            oncancel={this.oncancel}
                            loading={this.state.loading}
                        />
                        :
                        ""
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        delete_contact_res: state.contactStore.delete_contact_res,
        delete_contact_error: state.contactStore.delete_contact_error,
        delete_contact_success: state.contactStore.delete_contact_success,
    }
}

export default withRouter(connect(mapStateToProps)(ActionsPopup));
