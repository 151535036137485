import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Divider } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import equal from 'fast-deep-equal';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import SalesBoardListItem from './sales-board-listItem';
import endpoints from '../../../api/endpoints';
import apiToken from '../../../api/token';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';


import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import currencyFormate from '../../../api/currencyFormate';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
const styles = theme => ({
    paper: {
        // padding: theme.spacing(2),
        color: theme.palette.text.primary,
        dispay: 'flex',
        minWidth: '300px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
        margin: '0 5px'
    },
    toolbar: {
        minHeight: '64px',
        display: 'flex',
        boxSizing: 'border-box',
        flexDirection: 'column',
        width: '100%',
        background: 'white',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    title: {
        font: '300 20px/32px Poppins, sans-serif',
        margin: '0'
    },
    box: {
        flexDirection: 'row',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: "space-between",
        borderRadius: '4px',
        padding: '0px !important',
        width: '100%',
        textAlign: 'center',
        background: 'rgb(216, 216, 216)',
    },
    boxContainer: {
        textAlign: 'center',
        padding: '2px',
        // flex: '1 1 100%',
        boxSizing: 'border-box',
        // maxWidth: '33%'
    },
    dropZoneContainer: {
        height: '100%',
        position: 'relative',
    },
    dropZoneContainerUl: {
        paddingLeft: '0px',
        minHeight: '200px',
        height: '100%',
        display: 'block!important',
        listStyle: 'none',
        justifyContent: 'space-around',
        float: "left"
    },
    paper2: {
        display: 'block',
        padding: '8px',
        minHeight: '30px',
        boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
        margin: '12px'
    },
    field: {
        width: "100%",
    },
    loader: {
        position: 'fixed',
        left: '0px',
        top: '0px',
        width: '100%',
        height: '100%',
        zIndex: 9999,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
    }
});
class AppDragDropDemo extends Component {
    state = {
        showSalesItem: false,
        target: 0,
        projected: 0,
        current: 0,
        total_opportunity_value: 0,
        sales_data: [],
        show_lost_popup: true,
        open: false,
        old_stage_name: '',
        data: '',
        newStage: '',
        lost_reason: '',
        loading: false,
        selected_pipeline: 0,
    }


    componentDidMount() {

        var ss = this.props;
        if (this.props.sales_data) {
            this.setState({ sales_data: this.props.sales_data });
        }

        if (this.props.pipeline_list && this.props.pipeline_list.length > 0) {
            this.setState({ selected_pipeline: this.props.pipeline_list[0]?.pipeline_id })
        }
    }

    componentDidUpdate(prevprops) {

        if (!equal(this.props.salesListItemArrayShow, prevprops.salesListItemArrayShow)) {
            this.setState({ showSalesItem: true, sales_data: this.props.sales_data });
        } else if (!equal(this.props.sales_data, prevprops.sales_data)) {
            this.setState({ showSalesItem: true, sales_data: this.props.sales_data });
        }
    }

    getStageName = (stage_id, stage_status) => {
        if (stage_status === "Active") {
            let data = this.state.sales_data.filter(x => x.stage_id === stage_id);
            return data[0].stage_name;
        } else if (stage_status === "Won" || stage_status === "Lost") {
            return stage_status
        }
    }

    addAndRemoveDataIntoStageIteams = (old_stage_name, data, newStage) => {
        this.setState({ loading: true });
        let stageArr = this.state.sales_data;
        // remove item from array
        if (old_stage_name === 'Won' || old_stage_name === "Lost") {
            var index1 = stageArr.findIndex(x => x.stage_status === old_stage_name);
        } else {
            var index1 = stageArr.findIndex(x => x.stage_name === old_stage_name);
        }

        let listItem = stageArr[index1].listItem;
        stageArr[index1].listItem = listItem.filter(item => item.opportunity_id !== data.opportunity_id);
        if (newStage === 'Won' || newStage === "Lost") {
            var index2 = stageArr.findIndex(x => x.stage_status === newStage);
        } else {
            var index2 = stageArr.findIndex(x => x.stage_name === newStage);
        }
        let arrangeData = ""
        if (newStage === "Won" || newStage === "Lost") {
            data.status = newStage;
            data.stage = data.stage;
            arrangeData = data;
            arrangeData.changeStatus = true;

        } else {
            data.status = "Active"
            data.stage = stageArr[index2].stage_id;
            arrangeData = data;
        }
        data.stage = stageArr[index2].stage_id;
        stageArr[index2].listItem.unshift(data);  // add items 
        // update data
        let api_token = apiToken();
        const token = api_token.accessToken;
        axios.put(endpoints.BASE_URL + '/api/opportunity/arrange',
            arrangeData,
            {
                headers: {
                    Authorization: token,
                }
            }
        ).then(res => {
            this.props.UpdatePipelineStagesItemList(arrangeData);
            this.setState({ sales_data: stageArr, loading: false });

        }).catch(res => {
            this.setState({ loading: false });
            alert(" api error");
        }
        );
    }

    onDrop = (ev, newStage) => {
        let data = JSON.parse(ev.dataTransfer.getData("move_data"));
        let old_stage_name = this.getStageName(data.stage, data.status);
        if (newStage === "Lost") {
            this.setState({ open: true, old_stage_name: old_stage_name, data: data, newStage: newStage });
        } else {
            this.addAndRemoveDataIntoStageIteams(old_stage_name, data, newStage);
        }
    }

    onDragStart = (ev, data) => {
        ev.dataTransfer.setData("move_data", JSON.stringify(data));
    }

    onDragOver = (ev) => {
        ev.preventDefault();
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    onChange = (event) => {
        this.setState({ lost_reason: event.target.value });
    }

    submitLostReason = () => {
        let data = this.state.data;
        if (this.state.lost_reason != '') {
            data.lost_reason = this.state.lost_reason;
            data.lost_on_date = new Date();
            data.lost_at_stage = data.stage;
            this.setState({ open: false });
            this.addAndRemoveDataIntoStageIteams(this.state.old_stage_name, data, this.state.newStage);
        }
    }
    goToOpportunityByID = (id) => {
        this.props.goToOpportunityByID(id);
    }

    handleChange = (e, newAlignment) => {
        this.setState({ selected_pipeline: newAlignment })
    }


    render() {
        const { classes, value, label, color, data } = this.props;
        let total_opportunity_value = 0;
        let showSalesList = "";
        if (this.state.sales_data.length > 0) {
            showSalesList = this.state.sales_data.filter((data) => {
                if (data.pipeline.pipeline_id === this.state.selected_pipeline || data.stage_status === 'Won' || data.stage_status === 'Lost') {
                    return data;
                }
            }).map(item => (

                <Paper className={classes.paper} onDragOver={(e) => this.onDragOver(e)}
                    onDrop={(e) => { this.onDrop(e, item.check_status ? item.stage_status : item.stage_name) }}>
                    <Toolbar className={classes.toolbar}>
                        <Toolbar style={{ padding: '0 0px', width: "100%", justifyContent: 'center' }}>
                            <h5 className={classes.title}> {item.check_status ? item.stage_status : item.stage_name} </h5>
                        </Toolbar>
                        <Toolbar style={{ padding: '0 0px', width: "100%", justifyContent: 'center', maxHeight: '30px', minHeight: '30px' }}>
                            <div className={classes.box}>
                                <div className={classes.boxContainer}><span>{item.listItem ? item.listItem.length : 0} {' OPPS.'}</span></div>
                                <div className={classes.boxContainer} style={{ cursor: 'pointer' }}>
                                    <Tooltip title={'Total contract Value : ' + (item.listItem.length > 0 ? item.listItem[0].opportunity_currency.currency_symbol : "") + (item.listItem ? (item.listItem.reduce((prev, next) => prev + next.opportunity_value, 0)) : 0)}>
                                        <span>{item.listItem.length > 0 ? item.listItem[0].opportunity_currency.currency_symbol : ""} {item.listItem ? currencyFormate(item.listItem.reduce((prev, next) => prev + next.opportunity_value, 0)) : currencyFormate(0)} TCV</span></Tooltip></div>
                                <div className={classes.boxContainer}><span>{item.listItem.length > 0 ? item.listItem[0].opportunity_currency.currency_symbol : ""} {item.listItem ? currencyFormate((item.listItem.reduce((prev, next) => prev + next.expenses, 0))) : 0} EXp</span></div>
                            </div>
                        </Toolbar>
                    </Toolbar>
                    {item.listItem ? <SalesBoardListItem goToOpportunityByID={this.goToOpportunityByID} data={item.listItem} stage={item.stage_name} pipeline={this.state.selected_pipeline} onDragStart={this.onDragStart} /> : ''}
                </Paper>

            ))
        }



        return (
            <Grid item xs={12} className="sales" style={{ maxWidth: "96.6%", marginRight: 30 }}>
                <div><ToggleButtonGroup
                    color="primary"
                    value={this.state.selected_pipeline}
                    exclusive
                    onChange={this.handleChange}
                >
                    {
                        this.props.pipeline_list && this.props.pipeline_list.map((pipeline, i) => {
                            return (<ToggleButton key={i} value={pipeline.pipeline_id}>{pipeline.pipeline_name}</ToggleButton>)
                        })
                    }
                </ToggleButtonGroup>
                </div>
                <ul>
                    {showSalesList}
                </ul>
                {this.state.show_lost_popup ?
                    <Dialog open={this.state.open} onClose={this.handleClose} >
                        <div style={{ padding: '24px' }}>
                            <div style={{ font: '300 20px/32px Poppins, sans-serif' }}>
                                <span style={{ float: "left" }}>Lost Reason</span>
                                <span style={{ float: "right" }}><CloseIcon onClick={this.handleClose} /></span>
                            </div>
                            <div style={{ dispay: "flex", width: '300px', }}>

                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Box mt={5}>
                                            <TextField
                                                name="pipeline"
                                                className={classes.field}
                                                label="Lost Reason"
                                                required={true}
                                                name="pipeline"
                                                select
                                                onChange={this.onChange}
                                            >
                                                <MenuItem value='reason-0'>Not Intrested</MenuItem>
                                                <MenuItem value='reason-1'>Completed</MenuItem>
                                            </TextField>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Box mt={7}>
                                            <Button onClick={this.submitLostReason} color="primary" style={{ backgroundColor: "#eee", float: "right" }}>
                                                Submit
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>

                            </div>
                        </div>
                    </Dialog>
                    : ''}
                {
                    this.state.loading ? (
                        <div className={classes.loader}>
                            <div style={{ paddingTop: "30%" }}><center><CircularProgress size={40} /></center></div>
                        </div>
                    ) : undefined
                }
            </Grid>);
    }
}
AppDragDropDemo.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default withStyles(styles)(AppDragDropDemo);

