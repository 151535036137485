import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function DashboardIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M12,4H20V10H12V4M12,21V11H20V21H12M3,21V15H11V21H3M3,14V4H11V14H3M4,5V13H10V5H4M13,5V9H19V5H13M13,12V20H19V12H13M4,16V20H10V16H4Z"/>
      </SvgIcon>
    );
}

export default DashboardIcon;
