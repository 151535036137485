import React from "react";
import SvgIcon from '@material-ui/core/SvgIcon';

function CreatePipelineIcon(props) {
    return (
        <SvgIcon {...props} viewBox='0 0 20 10'>
            <path d="M77.91,77.785H22.238c-0.273-0.036-0.56,0.003-0.828,0.127c-0.72,0.334-1.055,1.172-0.764,1.91l5.197,13.184   c0.226,0.573,0.779,0.95,1.396,0.95h45.688c0.11,0,0.22-0.012,0.327-0.036l0.323-0.072c0.503-0.112,0.913-0.476,1.086-0.961   l4.66-13.099c0.163-0.459,0.094-0.97-0.188-1.368C78.854,78.022,78.397,77.785,77.91,77.785z M72.166,90.956H28.26L24.25,80.785   h51.534L72.166,90.956z" />
            <path d="M85.521,54.395c-0.166-0.059-0.336-0.081-0.503-0.081v-0.005H15.08c-0.204-0.016-0.415,0.007-0.62,0.081   c-0.781,0.276-1.189,1.135-0.912,1.915l4.735,13.333c0.218,0.615,0.796,0.998,1.414,0.998c0.045,0,0.09-0.019,0.135-0.023h60.33   c0.038,0.003,0.076,0.02,0.113,0.02c0.616,0,1.194-0.383,1.413-0.997l4.743-13.323C86.71,55.53,86.302,54.672,85.521,54.395z    M79.224,67.611H20.748l-3.659-10.304h65.804L79.224,67.611z" />
            <path d="M93.346,31.466c-0.281-0.398-0.738-0.635-1.226-0.635H7.881c-0.487,0-0.944,0.237-1.225,0.635   c-0.281,0.398-0.352,0.908-0.188,1.367l4.716,13.289c0.006,0.016,0.015,0.03,0.022,0.046c0.022,0.058,0.05,0.113,0.079,0.167   c0.015,0.028,0.028,0.057,0.045,0.083c0.044,0.071,0.094,0.136,0.149,0.199c0.006,0.007,0.011,0.014,0.017,0.021   c0.274,0.303,0.667,0.497,1.108,0.497h74.778c0.001,0,0.002,0,0.003,0c0.003,0,0.006-0.001,0.009-0.001   c0.137-0.001,0.268-0.025,0.394-0.061c0.03-0.008,0.058-0.017,0.087-0.027c0.128-0.044,0.249-0.101,0.358-0.176   c0.009-0.006,0.016-0.014,0.024-0.02c0.103-0.074,0.194-0.163,0.275-0.261c0.018-0.022,0.037-0.042,0.054-0.065   c0.081-0.11,0.148-0.23,0.197-0.361c0.003-0.009,0.01-0.016,0.014-0.025l4.736-13.303C93.696,32.375,93.627,31.865,93.346,31.466z    M86.326,44.134H13.661l-3.656-10.303h79.989L86.326,44.134z" />
            <path d="M48.108,19.604c0.281,0.281,0.663,0.439,1.061,0.439s0.779-0.158,1.061-0.439l10.999-11c0.586-0.586,0.586-1.536,0-2.121   c-0.586-0.586-1.535-0.586-2.121,0l-9.938,9.939L39.23,6.482c-0.586-0.586-1.535-0.586-2.121,0c-0.586,0.585-0.586,1.535,0,2.121   L48.108,19.604z" />
        </SvgIcon>
    );
}

export default CreatePipelineIcon;