import React from 'react';
import { PropTypes } from 'prop-types';
import './style.css';

function SpinnerAtomBall(props) {
  return (
      <div className="overlay-spinner" style={{ display : props.show ? 'block':'none'}}>
          <div className="spinner">
              <div className="ball ball-1"></div>
              <div className="ball ball-2"></div>
              <div className="ball ball-3"></div>
              <div className="ball ball-4"></div>
          </div>
      </div>
  );
} 


export default SpinnerAtomBall;
