import React from "react";
import Loader from 'react-loader-spinner'
import { render } from "@testing-library/react";

 export default class Loading extends React.Component {
  //other logic
    render() {
     return(
      <Loader
         type="Puff"
         color="#00BFFF"
         height={100}
         width={100}
         timeout={3000} //3 secs
      />
     );
    }
 }

// function Loading(props) {
   // if (props.error) {
   //   return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
   // } else if (props.pastDelay) {
   //   return <div>Loading...</div>;
   // } else {
   //   return null;
      // return
      //    <Loader
      //       type="Puff"
      //       color="#00BFFF"
      //       height={100}
      //       width={100}
      //       timeout={5000} //3 secs
      //    />         
   // }
//  }
//  export default Loading;
