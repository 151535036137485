import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { resetPasswordUser } from "../../actions/userAction"
import { Snackbar } from "@material-ui/core";
import SpinnerAtomBall from "../atom-ball-spinner";


class ResetPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            setOpen: props.open,
            data: props.data,
            snackBar: false,
            disabled: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.resetPassRes != prevProps.resetPassRes && this.props.resetPassResSuccess == true) {
            let message = "Reset password link sent for" +"'"+this.state.data.email+"'";
            this.props.snackBar(message);
            this.handleClose();
        }
    }

    handleClose = (event) => {
        this.setState({ setOpen: false })
        this.props.onCancelResetPassword();
    }

    handleDeactive = () => {
        this.props.dispatch(resetPasswordUser(this.state.data.email));
        this.setState({ disabled: true })
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.setOpen}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <div style={{
                        width: "480px",
                        margin: "30px 0px 0px 30px",
                        fontSize: "15px",
                    }}>
                        {"Reset Password Confirmation"}
                    </div>
                    <div style={{
                        width: "480px",
                        margin: "30px 0px 40px 30px",
                        fontSize: "15px",
                    }}>
                        Are you sure you want to reset password for'<strong>{this.state.data.first_name + " " + (this.state.data.last_name ? this.state.data.last_name : '')}</strong>'?
                    </div>
                    <DialogActions>
                        <div style={{ width: "100%" }}>
                            <Grid container direction="row">
                                <Grid item xs={12} lg={12} md={12}>
                                    <Button fullWidth onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        onClick={this.handleDeactive}
                                        variant="contained"
                                        color="primary"
                                        // style={{ color: "white", backgroundColor: "black" }}
                                        disabled={this.state.disabled}
                                    >
                                        Yes, reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                        <SpinnerAtomBall show={this.props.atom_ball_spinner_sales} />
                    </DialogActions>
                </Dialog>
                {
                    this.state.snackBar ?
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            open={this.state.snackBar}
                            onClose={this.handleSnackBarClose}
                            message="I love snacks"
                        /> : undefined
                }
                
            </div>
        );
    }
}

const mapStatetoprops = (state) => {
    return {
        resetPassRes: state.userStore.resetPassRes,
        resetPassResSuccess: state.userStore.resetPassResSuccess,
        atom_ball_spinner_sales: state.dashboardStore.atom_ball_spinner_sales,
    }
}

export default (connect(mapStatetoprops)(ResetPassword));