import React, { Component } from 'react';
// import Profile from '../../containers/profile/profile';
// import PageHeader from '../../components/page-header/PageHeader';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { getUserProfile, updateProfilePic } from '../../actions/commonAction';
import CardActionArea from '@material-ui/core/CardActionArea';
import { Avatar, Divider, CardHeader, Grid } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
// import ProfilePhoto from "../assets/images/user-icon-jpg-11.jpg";
import ProfilePhoto from "../../assets/images/user-icon-jpg-11.jpg";
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import base_uri from '../../api/baseUrls';
import endpoints from '../../api/endpoints';

const style = theme => ({
    Card: {
        margin: 10
    }
})

class NavProfileContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_profile: '',
            profileName: '',
            profileDesignation: '',
            profileImage: ''
        };
    }

    componentDidMount() {
        this.props.dispatch(getUserProfile());
        if (this.props.user_profile !== null && this.props.user_profile !== undefined) {           
            var fatch_url = '';
            if (this.props.user_profile.avatar_full !== null && this.props.user_profile.avatar_full !== undefined) {
                fatch_url = endpoints.BASE_URL + this.props.user_profile.avatar_full;
            }
            console.log(this.props.user_profile.first_name + ' ' + this.props.user_profile)
            this.setState({
                user_profile: this.props.user_profile,
                profileName: this.props.user_profile.first_name + ' ' + this.props.user_profile.last_name,
                profileDesignation: this.props.user_profile.designation?.designation_name,
                profileImage: fatch_url,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.user_profile !== null && this.props.user_profile !== undefined && Object.keys(this.props.user_profile).length > 0 && this.props.user_profile !== prevProps.user_profile) {
            var fatch_url = '';
            if (this.props.user_profile.avatar_full !== null && this.props.user_profile.avatar_full !== undefined) {
                fatch_url = endpoints.BASE_URL + this.props.user_profile.avatar_full;
            }
            console.log(this.props.user_profile)
            this.setState({
                user_profile: this.props.user_profile,
                profileName: this.props.user_profile.first_name + ' ' + this.props.user_profile.last_name,
                profileDesignation: this.props.user_profile.designation.designation_name,
                profileImage: fatch_url,
            });
        }
    }

    // getUserProfile = () => {
    //     this.props.dispatch(getUserProfile())
    // }

    // updateProfilePic = (file) => {
    //     this.props.dispatch(updateProfilePic(file))
    // }

    handleClick = () => {
        this.props.history.push("/user/profile")
    }

    render() {
        const { classes } = this.props;
        return (

            <div>
                <div style={{ paddingTop: '64px', backgroundColor: '#282828', width: "233px" }}>
                    <Card className={classes.card} style={{ backgroundColor: '#282828', boxShadow: "none" }}>
                        <CardActionArea style={{ backgroundColor: "#282828", display: 'inline-flex', margin: "10px 0px" }} onClick={this.handleClick}>
                            {/* <Avatar style={{ overflow: 'hidden', width: '80px', height: '80px' }} alt="Remy Sharp" src={this.state.profileImage} /> */}
                            <Avatar style={{ overflow: 'hidden', width: '80px', height: '80px' }} alt="Remy Sharp" src={this.state.profileImage ? this.state.profileImage : ProfilePhoto} />
                        </CardActionArea>
                        <CardContent style={{ backgroundColor: "#282828", padding: "0px", marginTop: "2px" }} >
                            <Typography component="h2" style={{ textAlign: 'center', color: '#fff', fontWeight: 'bold', fontSize: '16px' }} >
                                {this.state.profileName}
                            </Typography>
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item xs={6}>
                                    <Divider style={{ borderBottom: '2px solid #979797', width: '25px', height: '3px', display: 'block', margin: '10px auto', marginTop: "13px", marginBottom: "10px" }} />
                                </Grid>
                            </Grid>
                            <Typography component="h2" style={{ textAlign: 'center', color: '#fff', fontWeight: 'bold', fontSize: '12px', letterSpacing: "1px", textTransform: "uppercase" }} >
                                {this.state.profileDesignation}
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user_profile: state.commonStore.userProfile,
    };
}

export default withRouter(withStyles(style)(connect(mapStateToProps)(NavProfileContainer)));