import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ArrowBack from '../../components/icons/arrowBack';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import apiToken from '../../api/token';
import endpoints from '../../api/endpoints';
import { withRouter } from "react-router-dom";
import SpinnerAtomBall from '../../components/atom-ball-spinner';

const styles = theme => ({
    root: {
      padding: "35px",
    },
  })

  
  class CreateExpenseType extends Component {

    constructor(props) {
      super(props)
      this.state = {
        expense_type:'',
        expenseReasonErr:'',
        loading:false
      }
    }


     goBack = () => {
        this.props.history.goBack();
      };
      setExpenseType=(e)=>{
        this.setState({
          expense_type: e.target.value
        });
      }
      validate = () => {
        let flag = false;
        if (this.state.expense_type == null || this.state.expense_type == "") {
          this.setState({
            expenseReasonErr:"This field is required"
          });
          flag = true;
      }
        return flag;
      }

    handleSubmit=()=>{
        let api_token = apiToken();
    if (!this.validate()) {
      this.setState({ loading: true });
      const token = api_token.accessToken;
      let url = endpoints.EXPENSE_TYPE_CREATE;
      const { expense_type } = this.state;
      const data = { type_value: expense_type, is_locked:false };
      axios.post(
        url,
        data,
        {
          crossDomain: true,
          headers: {
            Authorization: token
          }
        },
      ).then(response => {
        
         this.setState({ loading: false });
         this.goBack();
      }).catch(error => {
        this.setState({ loading: false });
        console.log(error.message);
      });
    }
      }
      
    render() {
        const { classes } = this.props;

        return (
          <div className="newexpensess" >
      <div className="newexpensessheading" >
        <Button>
          <ArrowBack onClick={this.goBack} />
        </Button>
        <span>CREATE EXPENSE TYPE</span>
      </div>
      <Container className="newsexpressContainer">
        <Card
         
          style={{
            textAlign:'center',
            borderRadius: "10px",
            boxShadow: "0 0 20px 10px rgba(0, 0, 0, 0.1)",
            padding: "2% 3%",
          }}
        >
          <Grid  spacing={1}>
            <Grid item md={12} className="topLable">
              <TextField
                value={this.state.expense_type}
                label="Expense Type"
                onChange={this.setExpenseType}
                required
                error={this.state.expenseReasonErr.length > 0}
                helperText={this.state.expenseReasonErr}
              />
            </Grid>
            </Grid>
            <Grid container>
            <Grid item md={12} style={{  paddingTop: 13 }}>
              <Button
                className={classes.TextField}
                onClick={this.handleSubmit}
                variant="contained"
                color="primary"
              >
                Add
              </Button>
            </Grid>
            </Grid>
          
        </Card>
      
      </Container>
     
      <SpinnerAtomBall show={this.state.loading} />
    </div>
        )
      }
    }
    
    export default withRouter(withStyles(styles)(CreateExpenseType));