import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";


class LeadRemoveDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            setOpen: props.open,
            lead_title: props.lead,
            loading: false,
        }
    }

    componentDidUpdate(prevprops) {
        if (this.props.loading !== prevprops.loading){
            this.setState({ loading: prevprops.loading })
        }
    }

    handleClose = (event) => {
        this.setState({ setOpen: false })
        this.props.oncancel();
    }

    handleRemove = () => {
        this.props.leadRemove();
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.setOpen}
                    keepMounted
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description">
                    <DialogTitle id="alert-dialog-slide-title">
                        {"Remove Confirmation"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Are you sure you want to remove '{this.state.lead_title}' from leads?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ width: "100%" }}>
                            <Grid container direction="row">
                                <Grid item xs={12} lg={12} md={12}>
                                    <Button fullWidth onClick={this.handleClose}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        fullWidth
                                        onClick={this.handleRemove}
                                        style={{ color: "white", backgroundColor: "black" }}
                                    >
                                        Yes, remove{this.state.loading ? <CircularProgress color="secondary" size={18} /> : undefined}
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default LeadRemoveDialog;