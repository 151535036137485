import { act } from "react-dom/test-utils";

const initialState = {
  opprs_no_activity: null,
  opprs_no_total_activity: null,
  targetOfPipeline: [],
  opportunity_expense: [],
  project_payments: null,
  awaiting_payments: [],
  most_activity: null,
  exported_data: null,
  login_user: null,
  lost_opportunity: null,
  won_opportunity: null,
  expense_breakdown_by_type: [],
  expense_breakdown_by_type_user: [],
  // New Report (19/Nov)
  highest_spender_user: [],
  highest_spender_pipeline: [],
  opportunity_by_stage: [],
  lost_opportunity_vs_less_activities: [],
  opportunities_coming_to_close: [],
  lost_opportunities_reason: [],
  top_permorfer_by_value: [],
  lost_opportunities_volume: [],
  total_leads: [],
  leads_by_user: [],
  leads_by_date: [],
  lost_opportunities_vs_expense: [],
  leads_without_activities: [],
  top_performer_by_volume: [],
}

const reportsStore = (state = initialState, action) => {
  switch (action.type) {

    case 'SET_OPPRS_NO_AVCTIVITY_RES':
      return {
        ...state,
        opprs_no_activity: action.response,
      }

    case 'SET_OPPRS_NO_TOTAL_AVCTIVITY_RES':
      return {
        ...state,
        opprs_no_total_activity: action.response,
      }

    case 'SET_LESS_AVCTIVITY_RES':
      return {
        ...state,
        less_activity: action.response.data,
      }

    case 'SET_TARGET_OF_PIPELINE_RES':
      return {
        ...state,
        targetOfPipeline: action.response.data,
      }

    case 'SET_OPPORTUNITY_EXPENSES_RES':
      return {
        ...state,
        opportunity_expense: action.response.data,
      }

    case 'SET_PROJECTED_PAYMENTS_RES':
      return {
        ...state,
        project_payments: action.response.data,
      }

    case 'SET_AWAITING_PAYMENT_RES':
      return {
        ...state,
        awaiting_payments: action.response.data,
      }

    case 'SET_PERFORMER_LIST_RES':
      return {
        ...state,
        performer_list: action.response.performer,
        performer_pipeline: action.response.pipeline,
      }

    case 'SET_EMPTY_TARGET_OF_PIPELINE_RES':
      return {
        ...state,
        targetOfPipeline: [],
        opportunity_expense: [],
        project_payments: null,
        awaiting_payments: [],
        performer_list: [],
        performer_pipeline: [],
      }
    case 'SET_MOST_AVCTIVITY_RES':
      return {
        ...state,
        most_activity: action.response,
      }

    case 'SET_EXPORTED_DATA_RES':
      return {
        ...state,
        exported_data: action.response,
      }

    case 'SET_LOGIN_USER_RES':
      return {
        ...state,
        login_user: action.response,
      }

    case 'SET_LOST_OPPORTUNITY_RES':
      return {
        ...state,
        lost_opportunity: action.response,
      }

    case 'SET_WON_OPPORTUNITY_RES':

      return {
        ...state,
        won_opportunity: action.response,
      }

    case 'SET_EXPENSE_BREAK_BY_TYPE_RES':

      return {
        ...state,
        expense_breakdown_by_type: action.response,
      }

    case 'SET_EXPENSE_BREAK_BY_TYPE_USER_RES':

      return {
        ...state,
        expense_breakdown_by_type_user: action.response,
      }

    // New Report (19/Nov)

    case 'SET_HIGHEST_SPENDER_USER_RES':

      return {
        ...state,
        highest_spender_user: action.response,
      }

    case 'SET_HIGHEST_SPENDER_PIPELINE_RES':

      return {
        ...state,
        highest_spender_pipeline: action.response,
      }

    case 'SET_OPPORTUNITIES_BY_STAGE':

      return {
        ...state,
        opportunity_by_stage: action.response,
      }

    case 'SET_OPPORTUNITIES_BY_VS_LESS_ACTIVITIES':

      return {
        ...state,
        lost_opportunity_vs_less_activities: action.response,
      }

    case 'SET_OPPORTUNITIES_COMING_TO_CLOSE':

      return {
        ...state,
        opportunities_coming_to_close: action.response,
      }

    case 'SET_OPPORTUNITIES_REASON':

      return {
        ...state,
        lost_opportunities_reason: action.response,
      }


    case 'SET_TOP_PERFORMANCE_BY_VALUE':

      return {
        ...state,
        top_permorfer_by_value: action.response,
      }

    case 'SET_TOTAL_LEADS':

      return {
        ...state,
        total_leads: action.response,
      }

    case 'SET_LEADS_BY_USERS':

      return {
        ...state,
        leads_by_user: action.response,
      }

    case 'SET_LEADS_BY_DATES':

      return {
        ...state,
        leads_by_date: action.response,
      }

    case 'SET_LOST_OPPORTUNITIES_EXPENSE':

      return {
        ...state,
        lost_opportunities_vs_expense: action.response,
      }

    case 'SET_LOST_OPPORTUNITIES_TIMEFRAME':

      return {
        ...state,
        lost_opportunities_timeframe: action.response,
      }

    case 'SET_WON_OPPORTUNITIES_TIMEFRAME':

      return {
        ...state,
        won_opportunities_timeframe: action.response,
      }

    case 'SET_LEADS_WITH_NO_ACTIVITIES':
      return {
        ...state,
        leads_without_activities: action.response,
      }

    case 'SET_TOP_PERFORMANCE_BY_VOLUME':

      return {
        ...state,
        top_performer_by_volume: action.response,
      }

    default:
      return state;
  }
};

export default reportsStore;
